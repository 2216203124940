import React, { Fragment, useEffect, useState } from "react";
import PagesHeading from "../pages-heding/PagesHeading";
import Naac from "../naac-page/Naac";
import { useParams } from "react-router-dom";
import NaacInside from "../naac-page/NaacInside";

const NaccInsidePage = (props) => {
	const [year, setYear] = useState("");
	const [page, setPage] = useState("");

	let loc = useParams();
	// setYear(loc);

	useEffect(() => {
		setPage(loc.page);
		setYear(loc.year);
	}, [loc, page]);

	return (
		<Fragment>
			<PagesHeading
				page_title={page.replaceAll("-", " ").toUpperCase()}
			></PagesHeading>
			<NaacInside yearData={year} pageData={page}></NaacInside>
		</Fragment>
	);
};

export default NaccInsidePage;
