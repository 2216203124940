import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import OrganizationalStructure from '../organizational-structure/OrganizationalStructure'

const OrganizationStructurePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <OrganizationalStructure></OrganizationalStructure>
    </Fragment>
  )
}

export default OrganizationStructurePage