import React, { Fragment, useState } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import "./homeslider.css";
import { useEffect } from "react";
import { api } from "../../helper/api";
const HomeSlider = () => {
	const [homeSlider, setHomeSlider] = useState([]);
	// const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			const response = await api({
				method: "post",
				url: "fetch.php",
				data: { fetch_homeSlider: true },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			if (jsonData.status === 200) {
				setHomeSlider(jsonData.data);
			}
			// setLoading(false);
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			<div
				id="carouselExampleIndicators"
				className="carousel slide"
				data-bs-ride="carousel"
			>
				<div className="carousel-indicators">
					{homeSlider.length > 0 ? (
						homeSlider.map((slider, id) => (
							<button
								key={id}
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to={id}
								className="active"
								aria-current="true"
								aria-label={`Slide ${id + 1}`}
							></button>
						))
					) : (
						<button
							type="button"
							data-bs-target="#carouselExampleIndicators"
							data-bs-slide-to="0"
							className="active"
							aria-current="true"
							aria-label="Slide 1"
						></button>
					)}
				</div>
				<div className="carousel-inner">
					{homeSlider.length > 0 ? (
						homeSlider.map((slider, id) => (
							<div
								className={`carousel-item ${
									id === 0 && "active"
								}`}
								key={id}
							>
								<img
									src={slider?.slider_img}
									className="d-block w-100"
									alt="..."
								/>
							</div>
						))
					) : (
						<div className="carousel-item ">
							<img
								src="home_page_slider/home_page_slider_1.png"
								className="d-block w-100"
								alt="..."
							/>
						</div>
					)}
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="prev"
				>
					<span className="slider-btn left" aria-hidden="true">
						<GrFormPrevious className="slider-left-btn " />
					</span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="next"
				>
					<span className="slider-btn right" aria-hidden="true">
						<GrFormNext className="slider-right-btn" />
					</span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</Fragment>
	);
};

export default HomeSlider;
