import React, { Fragment, useState } from "react";
import "./visitngguestcard.css";
import { Link } from "react-router-dom";
import PopupVisitingGuest from "../popup-visiting-guest/PopupVisitingGuest";
const VistingGuestCard = (props) => {
	const [showPopUp, setShowPopUp] = useState(false);
	const [vistingGuestsData, setVisitingGuestData] = useState({
		faculty_name: "",
		area_special: "area_special",
		cor_experience: "cor_experience",
		teaching_expe: "teaching_expe",
		faculty_qualification: "faculty_qualification",
		company_name: "company_name",
	});

	const getVisingData = (
		faculty_name,
		area_special,
		cor_experience,
		teaching_expe,
		faculty_qualification,
		company_name
	) => {
		let updateDataVisting = {};
		updateDataVisting = {
			faculty_name: faculty_name,
			area_special: area_special,
			cor_experience: cor_experience,
			teaching_expe: teaching_expe,
			faculty_qualification: faculty_qualification,
			company_name: company_name,
		};
		// console.log("updateDataVisting", updateDataVisting)
		setVisitingGuestData(updateDataVisting);
		return setShowPopUp(!false);
	};
	// console.log(vistingGuestsData);
	return (
		<Fragment>
			<div className="core-faculty-card-container">
				<div className="core-faculty-name-role row">
					{props.CommitteMember_details.map((item, index) => {
						return (
							<Fragment>
								<Link to={item.url}>
									<div
										className="core-faculty-name-role-profile column"
										key={index}
										onClick={() =>
											getVisingData(
												item.faculty_name,
												item.area_special,
												item.cor_experience,
												item.teaching_expe,
												item.faculty_qualification,
												item.company_name
											)
										}
									>
										<div className="core-faculty-profile-img">
											<img
												src={item.faculty_profile}
												alt="Image Alt"
											/>
										</div>
										<div className="core-faculty-details">
											<p className="core-faculty-name">
												{item.faculty_name}
											</p>
										</div>
									</div>
								</Link>
							</Fragment>
						);
					})}
				</div>
				{showPopUp === true ? (
					<PopupVisitingGuest
						show={showPopUp}
						faculty_name={vistingGuestsData.faculty_name}
						area_special={vistingGuestsData.area_special}
						cor_experience={vistingGuestsData.cor_experience}
						teaching_expe={vistingGuestsData.teaching_expe}
						qualification={vistingGuestsData.faculty_qualification}
						company_name={vistingGuestsData.company_name}
						hide={() => setShowPopUp(false)}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};

export default VistingGuestCard;
