import React, { Fragment, useState } from "react";
import "./academicyeardetails.css";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../Loading/Loading";
import { api } from "../../helper/api";
const ResearchPaperDetails = () => {
	let loc = useParams();
	const [loading, setLoading] = useState(true);
	const [publicationData, setPublicationData] = useState(null);

	useEffect(() => {
		const loadData = async () => {
			let acYear = loc.year.split("-")[0];
			let level = loc.level != null ? loc.level : null;
			let paper = loc.paper != null ? loc.paper : null;
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						get_research_paper_inside: true,
						year: acYear,
						level: level,
						paper: paper,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;

				if (jsonData.status === 200) {
					setPublicationData(jsonData.data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			} catch (error) {
				// console.log(error);
				setLoading(false);
			}
		};
		loadData();
	}, [loc]);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="academic-research-paper-dynamic-container container">
				<div className="academic-research-paper-dynamic-heading">
					<h2>Academic Year {loc.year}</h2>
				</div>
				<div className="academic-research-paper-dynamic-list-container pt-3">
					<div className="">
						{publicationData && publicationData.length > 0
							? publicationData.map((data, id) => (
									<div
										className="academic-research-paper-dynamic-single-box"
										key="id"
									>
										<div className="academic-research-paper-dynamic-single-box-heading">
											<div className="academic-research-paper-dynamic-single-box-heading-first">
												<p>TITLE :-</p>
												<strong>{data.title}</strong>
											</div>
											<div className="academic-research-paper-dynamic-single-box-heading-first">
												<p>Paper :-</p>
												<strong>{data.paper}</strong>
											</div>
											<div className="academic-research-paper-dynamic-single-box-heading-first">
												<p>LEVEL :-</p>
												<strong> {data.level}</strong>
											</div>
										</div>
										<div className="academic-research-paper-dynamic-single-box-content-container">
											<div className="academic-research-paper-dynamic-single-box-heading-first">
												<p>By :-</p>
												<strong> {data.host_by}</strong>
											</div>
											<div className="academic-research-paper-dynamic-single-box-heading-first">
												<p>Organizer :-</p>
												<strong>
													{data.organizer}
												</strong>
											</div>
											<div className="academic-research-paper-dynamic-single-box-heading-first">
												<p>Venue :-</p>
												<strong>{data.venu}</strong>
												<br />
												<p>Date :</p>
												<strong> {data.date}</strong>
											</div>
										</div>
										<div className="conferenc-name-year pt-3">
											<p>{data.content}</p>
										</div>
									</div>
							  ))
							: "No DATA FOUND"}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ResearchPaperDetails;
