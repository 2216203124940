import React, { Fragment, useEffect, useState } from "react";
import { BsFillBuildingFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { BsBookFill } from "react-icons/bs";
import { FaAward } from "react-icons/fa";
import "./collegeprospectus.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CollegeProspectus = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_homepage_link: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{/* {loading && <Loading />} */}

			<div className="college-prospectus-container">
				<div className="take-div-upp">
					{data && (
						<div className="college-prospectus-row">
							<div className="college-prospectus columns ">
								<BsBookFill className="book-icon college-icons" />
								<div className="college-prospectus-content">
									<h3>PROSPECTUS</h3>
									<a
										href={
											data.College_Prospectus
												? data.College_Prospectus
												: "javascript:void(0)"
										}
										target="_blank"
									>
										Click Here
									</a>
								</div>
							</div>
							<div className="college-campus-life columns">
								<BsFillBuildingFill className="building-icon college-icons" />
								<div className="college-prospectus-content">
									<h3>CAMPUS TOUR</h3>
									<a
										href={
											data.Campus_Life
												? data.Campus_Life
												: "javascript:void(0)"
										}
										target="_blank"
									>
										Click Here
									</a>
								</div>
							</div>
							<div className="college-events columns">
								<FaAward className="award-icon college-icons" />
								<div className="college-prospectus-content">
									<h3>EVENTS</h3>
									<a
										href={
											data.Event
												? data.Event
												: "javascript:void(0)"
										}
										target="_blank"
									>
										Click Here
									</a>
								</div>
							</div>
							<div className="exam-time-table columns">
								<FaCalendarAlt className="calender-icon college-icons" />
								<div className="college-prospectus-content">
									<h3>PLACEMENTS</h3>
									<a
										href={
											data.Accolades
												? data.Accolades
												: "javascript:void(0)"
										}
										target="_blank"
									>
										Click Here
									</a>
								</div>
								</div>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CollegeProspectus;
