import React, { Fragment, useEffect, useState } from "react";

import "./bestpracticedetails.css";
import PagesHeading from "../pages-heding/PagesHeading";
import { Link } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const BestPracticesDetails = () => {
	const [tab, setTab] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_tabs_best_practice: true, sub: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setTab(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<PagesHeading page_title="Show Best Practices 2019-2020"></PagesHeading>
			<div className="best-practice-details-container container">
				<div className="best-practice-details-row">
					{tab.length > 0
						? tab.map((item, index) => {
								return (
									<div
										className="best-practice-details-column"
										key={index}
									>
										<Link to={item.subtab_slug}>
											{item.subtab_name}
										</Link>
									</div>
								);
						  })
						: "No Data Found"}
				</div>
			</div>
		</Fragment>
	);
};
export default BestPracticesDetails;
