import React, { Fragment, useEffect, useState } from "react";
import CollegeDeveleopmentCommittee from "../college-development-committee/CollegeDeveleopmentCommittee";
import PagesHeading from "../pages-heding/PagesHeading";
import CollegeDEvelopmentCommitteeTable from "../college-development-committee/CollegeDEvelopmentCommitteeTable";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CollegeDevelopmentCommitteePage = (props) => {
	const [CommitteMember, setCommitteMember] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_college_dev_committee: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setCommitteMember(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<PagesHeading page_title={props.page_title}></PagesHeading>
			{CommitteMember.length > 0 ? (
				<CollegeDEvelopmentCommitteeTable
					CommitteMember_details={CommitteMember}
				></CollegeDEvelopmentCommitteeTable>
			) : (
				"No Data Added"
			)}
		</Fragment>
	);
};

export default CollegeDevelopmentCommitteePage;
