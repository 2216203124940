import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import ResearchPaper from '../research-paper/ResearchPaper'

const ResearchPaperPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <ResearchPaper></ResearchPaper>
    </Fragment>
  )
}

export default ResearchPaperPage