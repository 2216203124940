import React, { Fragment, useEffect, useState } from "react";
import "./capabilityenhancement.css";
import { Link } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CapabilityEnhancement = () => {
	const DevelopmemntButtons = [
		{
			id: 1,
			button_name: "Bridge Course",
			is_Active: true,
			url: "/bridge-course",
		},
		{
			id: 2,
			button_name: "Career Counselling",
			is_Active: true,
			url: "/career-counselling",
		},
		{
			id: 3,
			button_name: "Soft Skill Development",
			is_Active: true,
			url: "/soft-skill-development",
		},
	];

	const [tab, setTab] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_tabs_capability_enhancement: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setTab(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="development-initiative-container container">
				<div className="development-initiative-buttons">
					{tab.length > 0
						? tab.map((item, index) => {
								return (
									<div
										className="development-button"
										key={index}
									>
										<Link to={item.slug}>
											<button>{item.name}</button>
										</Link>
									</div>
								);
						  })
						: "No Data Found"}
				</div>
			</div>
		</Fragment>
	);
};

export default CapabilityEnhancement;
