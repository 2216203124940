import React, { Fragment } from "react";
import "./popupvisitingguest.css";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
const PopupVisitingGuest = (props) => {
	return (
		<Fragment>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						Visiting & Guest Faculty
					</Modal.Title>
					<GrClose onClick={props.hide} className="close-popup-btn" />
				</Modal.Header>
				<Modal.Body>
					<div className="modal-body row">
						<strong className="col-3">Name of the Faculty</strong>
						<span className="col-1 d-flex justify-content-center">
							:{" "}
						</span>
						<p className="col-8">{props.faculty_name}</p>
						<br />
						<strong className="col-3">
							Area of Specialization{" "}
						</strong>
						<span className="col-1 d-flex justify-content-center">
							:{" "}
						</span>
						<p className="col-8">{props.area_special}</p>
						<strong className="col-3">Corporate Experience </strong>
						<span className="col-1 d-flex justify-content-center">
							:{" "}
						</span>
						<p className="col-8">{props.cor_experience} years</p>
						<strong className="col-3">Teaching Experience </strong>
						<span className="col-1 d-flex justify-content-center">
							:{" "}
						</span>
						<p className="col-8">{props.teaching_expe} years</p>
						<strong className="col-3">Qualification </strong>
						<span className="col-1 d-flex justify-content-center">
							:{" "}
						</span>
						<p className="col-8">{props.qualification}</p>
						<strong className="col-3">Company Name </strong>
						<span className="col-1 d-flex justify-content-center">
							:{" "}
						</span>
						<p className="col-8">{props.company_name}</p>
					</div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default PopupVisitingGuest;
