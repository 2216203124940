import React, { Fragment } from 'react'
import './studentwelfareservices.css'
const StudentWelfareServices = () => {
    return (
        <Fragment>
            <div className='student-welfare-services-container'>
                <div className='student-welfare-services-banner-1'>
                    <img src='images/student_wefare_service_1.png' className='student-welfare-services-image-1' alt="Image Alt" />
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Academic Counseling</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>The Faculty Development Workshop is designed for Directors, Head of the Institutions, Registrars and Senior Faculty members from Management Institutes.</p>
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Cafeteria</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>Light meals and snacks are available in the College cafeteria from 09.00 a.m. to 06.00 p.m. Students are not allowed to take any food or drinks into the classrooms, laboratories or library.</p>
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Cultural and Social Activities</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>Activities include social and environmental symposia, lectures and educational meetings. Orientation meetings and graduation ceremonies are organized annually. Sports day, Cultural Day, Fresher’s Party, Farewell &Alumni Meets are annual events organized by the students reflecting there management skills and persona.</p>
                </div>
                <div className='student-welfare-services-banner-2'>
                    <img src='images/student_wefare_service_2.png' className='student-welfare-services-image-2' alt="Image Alt" />
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Industrial Visits and Outbound Visit</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>Outbound Visit is a part of the Induction Program, where the students are given a feel of management education with management activities outside the institute. Every year Industrial Visits are organized by the students with approval and assistance from the Institute. This not only provides students with insights of the real industry / market functioning but also enhances their organizing and planning capabilities.</p>
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Recreation and Sports Activities</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>The Institute encourages extra-curricular activities outside the class room that enrich cultural, physical and social life of students in the campus. The facilities for outdoor games like cricket, hockey and indoor games like snooker, chess, carom, table tennis, badminton, etc are also provided in the institute in accordance to the timetable scheduled for lectures.</p>
                </div>
                <div className='student-welfare-services-banner-3'>
                    <img src='images/student_wefare_service_3.png' className='student-welfare-services-image-3' alt="Image Alt" />
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Library</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>A library consisting of various reference books, national newspapers in English and Hindi, journals and periodicals, HBR case lets are being subscribed to along with several business and social magazines.</p>
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Personality Development</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>Special programs and lectures are arranged from time to time for the personality development of students. Several competitions like elocution, debate, essay writing, etc. are also organized.</p>
                </div>
                <div className='student-welfare-services-heading'>
                    <h5>Computer Facilities</h5>
                </div>
                <div className='student-welfare-services-content'>
                    <p>The Institute has well-developed computer facilities. Every student has access to computer and internet for academic matters.</p>
                </div>
            </div>
        </Fragment>
    )
}

export default StudentWelfareServices