import React, { Fragment, useState, useEffect } from "react";
import "./studymaterial.css";
import { BsFillBuildingFill } from "react-icons/bs";
import { FaBookOpen } from "react-icons/fa";
import { GrSearch } from "react-icons/gr";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const StudyMaterial = () => {
	const [semdropdowns, setSemDropdowns] = useState([]);
	const [depdropdowns, setDepDropdowns] = useState([]);
	const [StudyMaterial, setStudyMaterial] = useState([]);
	const [filterDate, setFilterDate] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filter, setfilter] = useState({
		department: "BCOM",
		semester: "",
	});

	useEffect(() => {
		const loadSemOption = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						fetch_all_study_material_sem_options: true,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setSemDropdowns(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadSemOption();
		const loadDepOption = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						fetch_all_study_material_dep_options: true,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setDepDropdowns(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadDepOption();
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_study_material: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				let data = jsonData.data.map((item) => trimObjectValues(item));
				setStudyMaterial(data);
				setFilterDate(data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	// Function to trim each value in the object
	function trimObjectValues(obj) {
		const trimmedObj = {};
		for (let prop in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, prop)) {
				trimmedObj[prop] =
					typeof obj[prop] === "string"
						? obj[prop].trim()
						: obj[prop];
			}
		}
		return trimmedObj;
	}

	const handleChange = (e) => {
		setfilter({
			...filter,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const filterMaterials = () => {
		const filtered = StudyMaterial.filter((material) => {
			return (
				(!filter.department ||
					material.study_material_department.trim() ===
						filter.department.trim()) &&
				(!filter.semester ||
					material.study_material_semester.trim() ===
						filter.semester.trim())
			);
		});
		setFilterDate(filtered);
	};

	return (
		<Fragment>
			{loading && <Loading />}
			<div className="extend-the-pagex-heading"></div>
			<div>
				<div className="study-material-container">
					<div className="study-material-search-content-container container">
						<div className="study-material-search-content-row">
							<div className="study-material-search-content-column">
								<div className="study-material-search-building">
									<BsFillBuildingFill className="study-material-search-building-icon" />
								</div>
								<div className="study-material-search-select-department-heading">
									<ul className="study-material-search-select-department-ul">
										<li>
											<h4>Select Department</h4>
										</li>
									</ul>
								</div>
								<div className="study-material-search-select-department-drop-down">
									<select
										className="study-material-search-select-department-dropdown-list"
										name="department"
										onChange={handleChange}
									>
										<option
											value="select-department"
											disabled
											selected
										>
											Select Department
										</option>
										{depdropdowns &&
											depdropdowns.length > 0 &&
											depdropdowns.map((drop, id) => (
												<option value={drop.name}>
													{drop.name}
												</option>
											))}
									</select>
								</div>
							</div>
							<div className="study-material-search-content-column">
								<div className="study-material-search-book">
									<FaBookOpen className="study-material-search-book-icon" />
								</div>
								<div className="study-material-search-select-semester-heading">
									<ul className="study-material-search-select-department-ul">
										<li>
											<h4>Select The Semester</h4>
										</li>
									</ul>
								</div>
								<div className="study-material-search-select-semster-drop-down">
									<select
										className="study-material-search-select-semester-dropdown-list"
										onChange={handleChange}
										name="semester"
									>
										<option
											value="select-department"
											disabled
											selected
										>
											Select semester
										</option>
										{semdropdowns &&
											semdropdowns.length > 0 &&
											semdropdowns.map((drop, id) => (
												<option value={drop.semester}>
													{drop.semester}
												</option>
											))}
									</select>
								</div>
							</div>
							<div className="study-material-search-content-column">
								<div className="study-material-search-search">
									<GrSearch className="study-material-search-search-icon" />
								</div>
								<div className="study-material-search-select-search-heading">
									<ul className="study-material-search-select-department-ul">
										<li>
											<h4>Select Department</h4>
										</li>
									</ul>
								</div>
								<div className="study-material-search-select-search-drop-down">
									<button
										className="study-material-search-select-search-button"
										onClick={filterMaterials}
									>
										Search Now
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="hr-tag-between-search-result">
						<hr></hr>
					</div>
					<div className="result-for-search-files-container">
						<div className="result-for-search-files-row">
							{filterDate.length > 0
								? filterDate.map((material, id) => (
										<DownloadStudyMaterial
											key={id}
											fileName={
												material.study_material_name
											}
											subject={material.subject}
											doc={material.doc_name}
										/>
								  ))
								: "NO RESULT FOUND"}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default StudyMaterial;

export const DownloadStudyMaterial = ({ fileName, subject, doc }) => {
	return (
		<div className="result-for-search-files-column">
			<div className="result-for-search-files-heading-container">
				<strong>FILE NAME :- </strong>
				<p>{fileName}</p>
			</div>
			<div className="result-for-search-files-subject-heading-container">
				<strong>SUBJECT :- </strong>
				<p>{subject}</p>
			</div>
			<div className="result-for-search-files-download">
				<a href={doc} className="result-for-search-files-download-btn">
					Download{" "}
					<FaCloudDownloadAlt className="result-for-search-files-download-btn-icon" />
				</a>
			</div>
		</div>
	);
};
