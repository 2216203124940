import React, { Fragment, useEffect, useState } from "react";
import "./researchcell.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const ResearchCell = () => {
	const [researchCellData, setResearchCellData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { get_research_cell: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setResearchCellData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadData();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="research-cell-container">
				<div className="research-cell-table-container">
					{researchCellData && researchCellData.length > 0 ? (
						<table className="research-cell-table">
							<thead>
								<tr>
									<th>No.</th>
									<th>Year</th>
									<th>University Name</th>
									<th>Registration Number</th>
									<th>Research Guide Name</th>
									<th>No. of Research Student Registered</th>
									<th>
										No. of Research Student Completed Under
										The Guide
									</th>
									<th>File</th>
								</tr>
							</thead>
							<tbody>
								{researchCellData.map((data, id) => (
									<tr key={id}>
										<td>{id + 1}.</td>
										<td>{data.year}</td>
										<td>{data.university_name}</td>
										<td>{data.reg_no}</td>
										<td>
											{data.name}{" "}
											{data.guide_pdf_file && (
												<a
													href={data.guide_pdf_file}
													target="_blank"
													className="linkcta"
												>
													<FaEye className="research-cell-pdf-view" />
												</a>
											)}
										</td>
										<td>
											{data.research_student_registered !=
											""
												? data.research_student_registered
												: 0}
										</td>
										<td>
											{data.research_student_completed !=
											""
												? data.research_student_completed
												: 0}
										</td>
										<td className="research-cell-pdf">
											{data.student_registered_file && (
												<a
													href={
														data.student_registered_file
													}
													target="_blank"
												>
													<FaEye className="research-cell-pdf-view" />
												</a>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						"No Data Found"
					)}
				</div><br></br>
				{/* <div className="pictorial-heading container">
					<h3>Pictorial Dashboard</h3>
				</div> */}
				<div className="pictorial-image-container container">
					<img
						src="https://www.gnvsiom.org/assets/images/pictorial_dashboard.png"
						alt="Pictorial Image"
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default ResearchCell;
