import React, { Fragment } from 'react'
import ChairmanMessage from '../chairman-message/ChairmanMessage'
import PagesHeading from '../pages-heding/PagesHeading'


const ChairManMessagePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <ChairmanMessage></ChairmanMessage>
    </Fragment>
  )
}

export default ChairManMessagePage