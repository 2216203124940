import React, { Fragment } from 'react'
import { GrFormPrevious } from 'react-icons/gr';
const PreviousSlider = (props) => {
    const { className, style, onClick } = props;
    return (
        <Fragment>
            <GrFormPrevious className={ "slider-previous-btn "+ className}
                style={{ }}
                onClick={onClick} />
        </Fragment>
    )
}

export default PreviousSlider