import React, { Fragment } from 'react'
import BestPracticesIGL from '../best-practices-igl/BestPracticesIGL'

const BestPractciesIGLPage = () => {
  return (
    <Fragment>
        <BestPracticesIGL></BestPracticesIGL>
    </Fragment>
  )
}

export default BestPractciesIGLPage