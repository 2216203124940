import React, { Fragment } from "react";
import CoreFaculty from "../core-faculty/CoreFaculty";
import PagesHeading from "../pages-heding/PagesHeading";

const CoreFacultyPages = (props) => {
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<CoreFaculty />
		</Fragment>
	);
};

export default CoreFacultyPages;
