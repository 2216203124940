import React, { Fragment } from 'react'
import DirectorMessage from '../director-message.js/DirectorMessage'
import PagesHeading from '../pages-heding/PagesHeading'

const DirectorMessagePage = (props) => {
  return (
   <Fragment>
    <PagesHeading page_title={props.page_title}></PagesHeading>
    <DirectorMessage></DirectorMessage>
   </Fragment>
  )
}

export default DirectorMessagePage