import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import CertificateCourse from '../certificate-course/CertificateCourse'

const CertificateCoursePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <CertificateCourse></CertificateCourse>
    </Fragment>
  )
}

export default CertificateCoursePage