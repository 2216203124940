import React, { Fragment,useState } from 'react'
import ConferenceReadMoreModal from '../popup-cards/ConferenceReadMoreModal'
import PopupImageSliderLightbox from '../popup-image-slider-lightbox/PopupImageSliderLightbox'
import PagesHeading from '../pages-heding/PagesHeading'
const RuralImmersionProgram = () => {
    const [showModal, setShowModal] = useState(false)
    const [readmoreContent, setReadMoreContent] = useState({ readmore_title: "", readmore_content: "" })
    const [showImageSliderbtn, setShowImageSlider] = useState(false)
    const [photosArray, setPhotosArray] = useState('')
    const getReadMoreContent = (readmore, readmore_title) => {
        setReadMoreContent({
            readmore_content: readmore,
            readmore_title: readmore_title
        })
        return setShowModal(!false)
    }
    const showImageSlider = (image_array) => {
        setPhotosArray(image_array)
        return setShowImageSlider(true)
    }
    const confernce_array = [{
        id: 1,
        title: '1st International Conference - 2016',
        content: '1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was',
        images_array: [
            {
                id: 1,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 2,
                src: 'https://gnvsiom.org/photo_gallery/48cdeef289226ac3e9a3ac1e86ac1922DSC_5020.JPG'
            },
            {
                id: 3,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 4,
                src: 'https://gnvsiom.org/photo_gallery/6070e50319cb9b469cd1546d74661249IMG_8815.JPG'
            },
            {
                id: 5,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 6,
                src: 'https://gnvsiom.org/photo_gallery/48cdeef289226ac3e9a3ac1e86ac1922DSC_5020.JPG'
            },
            {
                id: 7,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 8,
                src: 'https://gnvsiom.org/photo_gallery/6070e50319cb9b469cd1546d74661249IMG_8815.JPG'
            }
        ]
    },
    {
        id: 2,
        title: '2nd International Conference - 2016',
        content: '1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was',
        images_array: [
            {
                id: 1,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 2,
                src: 'https://gnvsiom.org/photo_gallery/48cdeef289226ac3e9a3ac1e86ac1922DSC_5020.JPG'
            },
            {
                id: 3,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 4,
                src: 'https://gnvsiom.org/photo_gallery/6070e50319cb9b469cd1546d74661249IMG_8815.JPG'
            },
            {
                id: 5,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 6,
                src: 'https://gnvsiom.org/photo_gallery/48cdeef289226ac3e9a3ac1e86ac1922DSC_5020.JPG'
            },
            {
                id: 7,
                src: 'https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG'
            },
            {
                id: 8,
                src: 'https://gnvsiom.org/photo_gallery/6070e50319cb9b469cd1546d74661249IMG_8815.JPG'
            }
        ]
    },
    {
        id: 3,
        title: '3rd International Conference - 2016',
        content: '1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was1st International Conference was held on 5th and 6th March 2016. Conference theme was',
        images_array: [
            {
                id: 1,
                src: 'https://gnvsiom.org/photo_gallery/6070e50319cb9b469cd1546d74661249IMG_8815.JPG'
            }
        ]
    }]
    return (
        <Fragment>
            <PagesHeading page_title="Rural Immersion Program"></PagesHeading>
            <div className='conference-container container'>
                {confernce_array.map((item, index) => {
                    const contetn_substring = item.content.substring(0, 230);
                    return (
                        <div className='single-conference-comtainer'>
                            <div className='conference-heading'>
                                <h4>{item.title}</h4>
                            </div>
                            <div className='conference-message'>
                                {item.content.length <= 50 ? <p>{item.content}</p> : <><p className='confrence-more-content-paragraph'>{contetn_substring}</p><button className="confrence-more-content-button" onClick={() => { getReadMoreContent(item.content, item.title) }}>Read more</button></>}
                            </div>
                            <div className='conference-images-container'>
                                {item.images_array.map((images, index) => {
                                    return (
                                        <Fragment>
                                            {index >= 3 ? '' : <img src={images.src} alt="Image Alt" loading='lazy' />}
                                        </Fragment>
                                    )
                                })}
                            </div>
                            {item.images_array.length > 3 ?
                                <div className='conference-more-image'>
                                    <button className='conference-more-image-button' onClick={() => { showImageSlider(item.images_array) }}>View More</button>
                                </div>
                                : ''
                            }
                        </div>
                    )
                })}
                {showModal ? <ConferenceReadMoreModal show={showModal} readmore={readmoreContent} onHide={() => setShowModal(false)} /> : ''}
                {showImageSliderbtn ? <PopupImageSliderLightbox isOpen={showImageSliderbtn} photo_array={photosArray} onHide={() => { setShowImageSlider(false) }} /> : ''}
            </div>
        </Fragment>
    )
}

export default RuralImmersionProgram