import React, { Fragment } from 'react'
import MandatoryDisclosure from '../mandatory-disclosure/MandatoryDisclosure'
import PagesHeading from '../pages-heding/PagesHeading'

const MandatoryDisclosurePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <MandatoryDisclosure></MandatoryDisclosure>
    </Fragment>
  )
}

export default MandatoryDisclosurePage