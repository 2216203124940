import React, { Fragment } from 'react'
import DevelopmentInitiative from '../development-initiative/DevelopmentInitiative'
import PageHeading from '../pages-heding/PagesHeading'
const DevelopmentInitiativePage = (props) => {
  return (
    <Fragment>
        <PageHeading page_title={props.page_title}></PageHeading>
        <DevelopmentInitiative></DevelopmentInitiative>
    </Fragment>
  )
}

export default DevelopmentInitiativePage