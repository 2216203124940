import React, { useState } from "react";
import styled from "styled-components";

const StyledField = styled.div`
	display: flex;
	flex-direction: column;
	label {
		font-size: 18px;
		line-height: 18px;
		font-weight: 500;
		text-align: left;
		color: #225493;
	}
	select,
	textarea,
	input {
		min-height: 55px;
		border: 1px solid #8c8c8c;
		&::placeholder {
			color: #8c8c8c;
			font-size: 15px;
			line-height: 18px;
			font-weight: 500;
		}
		&:focus-within,
		&:focus {
			box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
		}
	}
	.invalid-feedback {
		text-align: left;
	}
`;

export const TextAreaInput = ({
	required,
	className,
	id,
	label,
	placeholder,
	defaultValue,
	multiline,
	loading = false,
	maxlength,
	onChange = () => {},
	onClick = () => {},
	isValidated = false,
	autoCapitalize,
}) => {
	const [isError, setIsError] = useState(false);
	const [value, setValue] = useState(defaultValue ?? "");

	const handleBlur = () => {
		if (required && value === "") {
			setIsError(true);
		} else {
			setIsError(false);
		}
	};

	return (
		<StyledField
			className={`form-group${className ? " " + className : ""}`}
			onClick={onClick}
		>
			{label ? (
				<label htmlFor={id} className="form-label">
					{label}
					{required ? (
						<span className="text-danger ms-1">*</span>
					) : null}
				</label>
			) : null}

			<textarea
				type="text"
				required={required}
				className={`form-control${
					isValidated && isError ? " is-invalid" : ""
				}`}
				name={id}
				id={id}
				rows={multiline}
				maxLength={maxlength ? maxlength : "false"}
				placeholder={placeholder}
				onChange={(e) => {
					onChange(e);
					setValue(e.target.value);
				}}
				onBlur={handleBlur}
				value={value}
				disabled={loading}
				autoCapitalize={autoCapitalize}
			/>

			{required && placeholder && isValidated && isError ? (
				<p className="invalid-feedback">
					Please enter a {label.toLowerCase()}.
				</p>
			) : null}
		</StyledField>
	);
};
export const TextInput = ({
	required,
	className,
	id,
	label,
	placeholder,
	defaultValue,
	loading = false,
	maxlength,
	onChange = () => {},
	onClick = () => {},
	isValidated = false,
	autoCapitalize,
}) => {
	const [isError, setIsError] = useState(false);
	const [value, setValue] = useState(defaultValue ?? "");

	const handleBlur = () => {
		if (required && value === "") {
			setIsError(true);
		} else {
			setIsError(false);
		}
	};

	const handleKeypress = (e) => {
		const keyCode = e.keyCode || e.which; // Check for both keyCode and which for browser compatibility

		// Allow functional keys (like backspace, delete, arrows) and spaces (keyCode 32)
		if (
			keyCode !== 9 &&
			keyCode !== 8 &&
			keyCode !== 32 &&
			(keyCode < 65 || keyCode > 90) &&
			(keyCode < 97 || keyCode > 122)
		) {
			e.preventDefault();
		}
	};
	const handlePaste = (e) => {
		e.preventDefault();
		const pastedText = e.clipboardData.getData("text/plain");
		const regex = /^[a-zA-Z\s]*$/;

		if (!regex.test(pastedText)) {
			// Handle the invalid paste here
		} else {
			document.execCommand(
				"insertText",
				false,
				pastedText.replace(/[^a-zA-Z\s]/g, "")
			);
		}
	};
	const allowText = (e) => {
		const regex = /^[a-zA-Z\s]*$/;
		const inputValue = e.target.value;

		setIsError(inputValue === "");

		if (!regex.test(inputValue)) {
			e.preventDefault();
		}
	};

	return (
		<StyledField
			className={`form-group${className ? " " + className : ""}`}
			onClick={onClick}
		>
			{label ? (
				<label htmlFor={id} className="form-label">
					{label}
					{required ? (
						<span className="text-danger ms-1">*</span>
					) : null}
				</label>
			) : null}

			<input
				type="text"
				required={required}
				className={`form-control${
					(isValidated && !value) || isError ? " is-invalid" : ""
				}`}
				name={id}
				id={id}
				maxLength={maxlength ? maxlength : "false"}
				placeholder={placeholder}
				onChange={(e) => {
					onChange(e);
					setValue(e.target.value);
					allowText(e);
				}}
				onInput={allowText}
				onBlur={handleBlur}
				onKeyDown={handleKeypress}
				onPaste={handlePaste}
				value={value ?? ""}
				disabled={loading}
				autoCapitalize={autoCapitalize}
			/>

			{(required && placeholder) || (isValidated && !value) || isError ? (
				<p className="invalid-feedback">
					Please enter a {label.toLowerCase()}.
				</p>
			) : null}
		</StyledField>
	);
};
export const MobileNumberInput = ({
	required,
	className,
	id,
	label,
	placeholder,
	defaultValue,
	loading = false,
	maxlength,
	onChange = () => {},
	onClick = () => {},
	isValidated = false,
	autoCapitalize,
}) => {
	const [isError, setIsError] = useState(false);
	const [value, setValue] = useState(defaultValue ?? "");

	const handleBlur = () => {
		if (required && (value === "" || value.length !== 10)) {
			setIsError(true);
		} else {
			setIsError(false);
		}
	};

	const handleKeypress = (e) => {
		const keyCode = e.keyCode || e.which;

		if (
			keyCode !== 9 &&
			(keyCode < 48 || keyCode > 57) &&
			(keyCode < 95 || keyCode > 106) &&
			keyCode !== 8 &&
			keyCode !== 46 &&
			e.key !== "v" &&
			!e.ctrlKey
		) {
			e.preventDefault();
		}
	};

	const handlePaste = (e) => {
		const pastedText = e.clipboardData.getData("text/plain"); // Remove non-numeric characters
		const regex = /^\d*$/;

		if (!regex.test(pastedText)) {
			e.preventDefault();
			return;
		}
		const maxLength = maxlength ? maxlength : 10;
		const currentValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters from the current value
		const remainingLength = maxLength - currentValue.length;

		if (pastedText.length > remainingLength) {
			// Truncate the pasted text to fit the remaining space
			const truncatedText = pastedText.substring(0, remainingLength);
			const newValue = currentValue + truncatedText;
			setValue(newValue);
			e.preventDefault();
		} else {
			const newValue = currentValue + pastedText;
			setValue(newValue);
		}
	};
	const allowText = (e) => {
		const regex = /^[6789]\d{0,9}$/;
		const inputValue = e.target.value;

		setIsError(inputValue === "");

		if (!regex.test(inputValue)) {
			e.preventDefault();
		}
	};

	return (
		<StyledField
			className={`form-group${className ? " " + className : ""}`}
			onClick={onClick}
		>
			{label ? (
				<label htmlFor={id} className="form-label">
					{label}
					{required ? (
						<span className="text-danger ms-1">*</span>
					) : null}
				</label>
			) : null}

			<input
				type="tel"
				required={required}
				className={`form-control${
					(isValidated && !value) || isError ? " is-invalid" : ""
				}`}
				name={id}
				id={id}
				maxLength={maxlength ? maxlength : "false"}
				placeholder={placeholder}
				onChange={(e) => {
					onChange(e);
					setValue(e.target.value);
					allowText(e);
				}}
				onInput={allowText}
				onBlur={handleBlur}
				onKeyDown={handleKeypress}
				onPaste={handlePaste}
				value={value}
				disabled={loading}
				autoCapitalize={autoCapitalize}
			/>

			{(required && placeholder) || (isValidated && !value) || isError ? (
				<p className="invalid-feedback">
					Please enter a valid {label.toLowerCase()}.
				</p>
			) : null}
		</StyledField>
	);
};
export const GenricInput = ({
	type = "text",
	required,
	className,
	id,
	label,
	placeholder,
	defaultValue,
	loading = false,
	maxlength,
	onBlur = () => {},
	onChange = () => {},
	onClick = () => {},
	isValidated = false,
	errorMsg,
	autoCapitalize,
	...rest
}) => {
	return (
		<StyledField
			className={`form-group${className ? " " + className : ""}`}
			onClick={onClick}
		>
			{label ? (
				<label htmlFor={id} className="form-label">
					{label}
					{required ? (
						<span className="text-danger ms-1">*</span>
					) : null}
				</label>
			) : null}

			<input
				type={type}
				required={required}
				className={`form-control`}
				name={id}
				id={id}
				placeholder={placeholder}
				onChange={onChange}
				onBlur={onBlur}
				value={defaultValue}
				disabled={loading}
				autoCapitalize={autoCapitalize}
				{...rest}
			/>

			{(required && placeholder) || isValidated ? (
				<p className="invalid-feedback">
					{errorMsg
						? errorMsg
						: `Please enter a valid ${placeholder.toLowerCase()}.`}
				</p>
			) : null}
		</StyledField>
	);
};

export const EmailInput = ({
	required,
	className,
	id,
	label,
	placeholder,
	defaultValue,
	loading = false,
	maxlength,
	onChange = () => {},
	onClick = () => {},
	isValidated = false,
	autoCapitalize,
}) => {
	const [isError, setIsError] = useState(false);
	const [email, setEmail] = useState(defaultValue ?? "");

	const validateEmail = (value) => {
		// Regular expression for basic email validation
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(value);
	};

	const handleBlur = () => {
		if (required && !validateEmail(email)) {
			setIsError(true);
		} else {
			setIsError(false);
		}
	};

	return (
		<StyledField
			className={`form-group${className ? " " + className : ""}`}
			onClick={onClick}
		>
			{label ? (
				<label htmlFor={id} className="form-label">
					{label}
					{required ? (
						<span className="text-danger ms-1">*</span>
					) : null}
				</label>
			) : null}

			<input
				type="email"
				required={required}
				className={`form-control${
					(isValidated && !email) || isError ? " is-invalid" : ""
				}`}
				name={id}
				id={id}
				maxLength={maxlength ? maxlength : "false"}
				placeholder={placeholder}
				onChange={(e) => {
					setEmail(e.target.value);
					onChange(e);
				}}
				onBlur={handleBlur}
				value={email}
				onInput={() => {
					setIsError(false);
				}}
				disabled={loading}
				autoCapitalize={autoCapitalize}
			/>

			{(required && placeholder) || (isValidated && !email) || isError ? (
				<p className="invalid-feedback">Please enter a valid email.</p>
			) : null}
		</StyledField>
	);
};
export const SelectField = ({
	required,
	className,
	id,
	label,
	placeholder,
	defaultValue,
	options = [],
	loading = false,
	columns,
	onChange = () => {},
	onClick = () => {},
	isValidated = false,
}) => {
	return (
		<StyledField
			className={`form-group${className ? " " + className : ""}`}
			onClick={onClick}
		>
			{label ? (
				<label htmlFor={id} className="form-label">
					{label}
					{required ? (
						<span className="text-danger ms-1">*</span>
					) : null}
				</label>
			) : null}
			<select
				required={required}
				className={`form-select ${
					isValidated && !defaultValue ? " is-invalid" : ""
				}`}
				name={id}
				id={id}
				defaultValue={defaultValue}
				onChange={(e) => onChange(e)}
				disabled={loading}
			>
				{placeholder ? (
					<option value="" disabled>
						{placeholder}
					</option>
				) : null}

				{options.length > 0
					? options.map((option, index) => {
							return (
								<option key={index} value={option.value}>
									{option.name}
								</option>
							);
					  })
					: null}
			</select>

			{required && label && isValidated && !defaultValue ? (
				<p className="invalid-feedback">
					Please select {label.toLowerCase()}{" "}
				</p>
			) : null}
		</StyledField>
	);
};
