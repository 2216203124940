import React, { Fragment } from 'react'
import './mandatorycommitteescard.css';
import { Modal } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr'
const MandatoryCommitteesCard = (props) => {
    return (
        <Fragment>
            <Modal
                {...props}
                
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >

                    <Modal.Title id="contained-modal-title-vcenter">
                        Mandatory Committees
                    </Modal.Title>
                    <GrClose onClick={props.hide} className='close-popup-btn' />
                </Modal.Header>
                <Modal.Body>
                    <div className='mandatory-committee-students-details'>
                        <ul>
                            {props.mandatory_committe_details.map((item, index) => {
                                return (
                                    <li key={index}>{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default MandatoryCommitteesCard