import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import StudentCouncil from '../student-council/StudentCouncil'

const StudentCouncilPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <StudentCouncil></StudentCouncil>
    </Fragment>
  )
}

export default StudentCouncilPage