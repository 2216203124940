import React, { Fragment, useEffect, useState } from "react";
import "./mmssyllabus.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const MMSSyllbaus = () => {
	// const mmsSyllabusDetails = [
	// 	{
	// 		id: 1,
	// 		subject_name: "Program Outcomes & Specific Outcomes",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 2,
	// 		subject_name: "Course Outcome",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 3,
	// 		subject_name: "MMS Further Revised Syllabus (2016-2017)",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 4,
	// 		subject_name: "MMS Revised Syllabus (2014-2015)",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 5,
	// 		subject_name: "MMS Syllabus (2013-2014)",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 6,
	// 		subject_name: "MMS Syllabus (2012-2013)",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 7,
	// 		subject_name: "Admission Details",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 8,
	// 		subject_name: "Admission Document Checklist",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 9,
	// 		subject_name: "Program Outcome  2019-2020",
	// 		url: "",
	// 	},
	// ];
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_mms_syllabus: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="mms-syllabus-container container">
				<div className="mms-syllabus-table-container">
					{data && data.length > 0 ? (
						<table className="mms-syllabus-table">
							{data.map((item, index) => {
								return (
									<tr key={index}>
										<td>{index + 1}.</td>
										<td>{item.title}</td>
										<td className="mms-syllabus-pdf">
											<a href={item.url}>
												<FaEye className="mms-syllabus-pdf-view" />
											</a>
										</td>
									</tr>
								);
							})}
						</table>
					) : (
						"NO DATA ADDED!"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default MMSSyllbaus;
