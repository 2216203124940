import React, { Fragment } from 'react'
import Conference from '../conference/Conference'
import PagesHeading from '../pages-heding/PagesHeading'

const ConferencePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <Conference></Conference>
    </Fragment>
  )
}

export default ConferencePage