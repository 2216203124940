import React, { Fragment } from "react";
import "./conferencereadmoremodal.css";
import { GrClose } from "react-icons/gr";
import { Modal } from "react-bootstrap";
const ConferenceReadMoreModal = (props) => {
	console.log(props.readmore.readmore_content);
	return (
		<Fragment>
			<Modal
				{...props}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className='modal-lg"'
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						{props.readmore.readmore_title}
					</Modal.Title>
					<GrClose
						onClick={props.onHide}
						className="close-popup-btn"
					/>
				</Modal.Header>
				<Modal.Body>
					<div
						className="conference-read-more-content"
						dangerouslySetInnerHTML={{
							__html: props.readmore.readmore_content,
						}}
					></div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default ConferenceReadMoreModal;
