import React from 'react'
import { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import FAQ from '../faq-page/FAQ'

const FAQPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <FAQ></FAQ>
    </Fragment>
  )
}

export default FAQPage