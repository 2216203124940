import React, { Fragment } from "react";
import PagesHeading from "../pages-heding/PagesHeading";
import ResearchPaperDetails from "../academicyear-details/ResearchPaperDetails";

const ResarchPaperDetailsPage = (props) => {
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<ResearchPaperDetails></ResearchPaperDetails>
		</Fragment>
	);
};

export default ResarchPaperDetailsPage;
