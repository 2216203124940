import React, { Fragment } from 'react'
import PageHeading from '../pages-heding/PagesHeading'
import Publications from '../publications/Publications'
const PublicationsPage = (props) => {
  return (
    <Fragment>
        <PageHeading page_title={props.page_title}></PageHeading>
        <Publications></Publications>
    </Fragment>
  )
}

export default PublicationsPage