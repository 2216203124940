import React, { Fragment, useEffect, useState } from "react";
import "./ceertificatecourse.css";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CertificateCourse = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_certificate_course: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="certificate-course-container container">
				<div className="certificate-course-image-container">
					<img
						src="images/certificate_course_banners.png"
						alt="Certificate course"
					/>
				</div>
				<div className="certificate-course-table-container">
					<div className="certificate-course-table1-container">
						{data.length > 0 ? (
							<table className="certificate-course-table1">
								<tbody>
									{data.map((item, id) => (
										<tr key={id}>
											<td>{id + 1}.</td>
											<td>{item.title}</td>
											<td className="certificate-course-table1-pfd">
												<a
													href={item.pdf}
													target="_blank"
												>
													<FaEye />
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							"No data Added"
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CertificateCourse;
