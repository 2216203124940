import React, { Fragment, useEffect, useState } from "react";
import "./naacpage.css";
import { Link } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const Naac = ({ yearData = "" }) => {
	const [naacCriteria, setNaacCriteria] = useState([]);
	const [naacQualityIndicator, setNaacQualityIndicator] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadNaacData = async () => {
			setLoading(true);

			const response = await api({
				method: "post",
				url: "fetch.php",
				data: { get_naac_criteria: true, naac_year: yearData },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setNaacCriteria(jsonData.data);
			setLoading(false);
		};
		const loadNaacQualityIndicator = async () => {
			setLoading(true);

			const response = await api({
				method: "post",
				url: "fetch.php",
				data: {
					get_naac_qualityIndicator: true,
					naac_year: yearData,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setNaacQualityIndicator(jsonData.data);
			setLoading(false);
		};
		if (yearData !== "") {
			loadNaacData();
			loadNaacQualityIndicator();
		}
	}, [yearData]);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="naac-page-container container">
				{/* <div className="naac-page-message-container">
					<p>
						Overview is the evaluation of work by one or more people
						with similar competences as the producers of the work
						(peers). ... In academia, scholarly peer review is often
						used to determine an academic paper's suitability for
						publication.
					</p>
				</div> */}
				{naacCriteria.length > 0 && (
					<div className="naac-top-6-conatiner-row">
						{naacCriteria.map((criteria, id) => (
							<div className="top-6-tags" key={id}>
								<Link
									to={`${criteria.naac_criteria_name
										.replace(/\s+/g, "-")
										.toLowerCase()}`}
								>
									{criteria.naac_criteria_name}
								</Link>
							</div>
						))}
					</div>
				)}
				<hr className="nacc-hr-line" />
				<div className="naac-quality-heading">
					<h4>QUALITY INDICATOR FRAMEWORK</h4>
				</div>
				<div className="naac-quality-row-container">
					{naacQualityIndicator.length > 0 &&
						naacQualityIndicator.map((qualityIndicator, id) => (
							<div
								className="naac-quality-anchor-container"
								key={id}
							>
								<Link
									to={`${qualityIndicator.naac_qualityIndicator_name
										.replace(/\s+/g, "-")
										.toLowerCase()}`}
								>
									{
										qualityIndicator.naac_qualityIndicator_name
									}
								</Link>
							</div>
						))}
				</div>
			</div>
		</Fragment>
	);
};

export default Naac;
