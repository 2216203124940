import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import RegisterForFDPMDP from '../register-for-fdp-mdp/RegisterForFDPMDP'

const RegisterForFDPMDPPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <RegisterForFDPMDP></RegisterForFDPMDP>
    </Fragment>
  )
}

export default RegisterForFDPMDPPage