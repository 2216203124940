import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import StudyMaterial from '../download-study-material/StudyMaterial'

const StudyMaterialPage = (props) => {
  return (
   <Fragment>
    <PagesHeading page_title={props.page_title}></PagesHeading>
    <StudyMaterial></StudyMaterial>
   </Fragment>
  )
}

export default StudyMaterialPage