import React, { Fragment, useState } from "react";
import "./academicyeardetails.css";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../Loading/Loading";
import { api } from "../../helper/api";
const AcademicYearDetails = () => {
	let loc = useParams();
	const [loading, setLoading] = useState(true);
	const [publicationData, setPublicationData] = useState(null);

	useEffect(() => {
		const loadData = async () => {
			let acYear = loc.year.split("-")[0];
			let level = loc.level != null ? loc.level : null;
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						get_research_publicaion_inside: true,
						year: acYear,
						level: level,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setPublicationData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadData();
	}, [loc]);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="academic-research-paper-dynamic-container container">
				<div className="academic-research-paper-dynamic-heading">
					<h2>Academic Year {loc.year}</h2>
				</div>
				{publicationData && publicationData.length > 0 ? (
					<div className="academic-research-paper-dynamic-list-container pt-3">
						<div className="">
							{publicationData.map((data, id) => (
								<div className="academic-research-paper-dynamic-single-box">
									<div className="academic-research-paper-dynamic-single-box-heading">
										<div className="academic-research-paper-dynamic-single-box-heading-first">
											<p>TITLE :-</p>
											<strong>{data.title}</strong>
										</div>
										<div className="academic-research-paper-dynamic-single-box-heading-first">
											<p>Type :-</p>
											<strong>{data.type}</strong>
										</div>
										<div className="academic-research-paper-dynamic-single-box-heading-first">
											<p>LEVEL :-</p>
											<strong> {data.level}</strong>
										</div>
									</div>
									<div className="academic-research-paper-dynamic-single-box-content-container">
										<div className="academic-research-paper-dynamic-single-box-heading-first">
											<p>
												ISSN/ISBN/Patent No./Others :-
												:-
											</p>
											<strong> {data.host_by}</strong>
											<br />
											<p>Citations In :-</p>
											<strong> {data.citation}</strong>
											<br />
											<p>By :-</p>
											<strong> {data.host_by}</strong>
										</div>
										<div className="academic-research-paper-dynamic-single-box-heading-first">
											<p>Publisher :-</p>
											<strong>{data.publisher}</strong>
											<br />
											<p>Impact Factor :-</p>
											<strong>
												{data.impact_factor}
											</strong>

											<br />
											<p>Date :</p>
											<strong> {data.date}</strong>
										</div>
										<div className="academic-research-paper-dynamic-single-box-heading-first">
											<p>Co-Authors(s) :-</p>
											<strong>{data.co_authors}</strong>
											<br />
											<p>H Index :</p>
											<strong> {data.hindex}</strong>
										</div>
									</div>
									<div className="conferenc-name-year pt-3">
										<p>{data.content}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				) : (
					"NO DATA ADDED"
				)}
			</div>
		</Fragment>
	);
};

export default AcademicYearDetails;
