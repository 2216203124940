import React, { Fragment } from 'react'
import './pagesquotesdown.css';
import { FaQuoteLeft } from 'react-icons/fa';
import { FaQuoteRight } from 'react-icons/fa'
export const PagesQuotesDown = (props) => {
    return (
        <Fragment>
            <div className='pages-quote-container'>
                <div className='pages-quote'>
                    <FaQuoteLeft className='opening-quotes-symbol' />
                    <div className='pages-quotes-content'>
                        <h5>{props.page_quote}</h5>
                    </div>
                    <FaQuoteRight className='closing-quotes-symbol' />
                </div>
            </div>
        </Fragment>
    )
}
