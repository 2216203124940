import React from 'react'
import { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import Mous from '../mous-page/Mous'

const MousPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <Mous></Mous>
    </Fragment>
  )
}

export default MousPage