import React, { Fragment, useEffect, useState } from 'react'
import HomeSlider from '../index-page-slider/HomeSlider'
import TypeWritter from '../typewritter/TypeWritter'
import CollegeProspectus from '../time-table-events-college/CollegeProspectus'
import WelcomeMessage from '../welcome-message/WelcomeMessage'
import LatestNews from '../lates-news-events/LatestNews'
import ChairMan from '../chairman-director/ChairMan'
import AlumniSpeak from '../alumni-speak/AlumniSpeak'
import TopRecruiters from '../our-top-recruiters/TopRecruiters'
import IndexPageAdmissionPanel from '../index-page-admission-panel/IndexPageAdmissionPanel'
import HomePageDynamicPopup from '../home-page-dynamic-popup/HomePageDynamicPopup'
const Index = () => {
    const [showDynamicPopu, setShowDynamicPopup] = useState(false)
    useEffect(() => {
        setShowDynamicPopup(true)
    }, [])
    const handleCloseModal = () => {
        setShowDynamicPopup(false);
    }
    return (
        <Fragment>
            {showDynamicPopu ? <HomePageDynamicPopup show={showDynamicPopu} onHide={handleCloseModal} /> : ''}
            <IndexPageAdmissionPanel></IndexPageAdmissionPanel>
            <HomeSlider></HomeSlider>
            <TypeWritter></TypeWritter>
            <CollegeProspectus></CollegeProspectus>
            <WelcomeMessage></WelcomeMessage>
            <LatestNews></LatestNews>
            <ChairMan></ChairMan>
            <AlumniSpeak></AlumniSpeak>
            <TopRecruiters></TopRecruiters>
        </Fragment>
    )
}

export default Index