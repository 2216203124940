import React, { Fragment } from "react";
import OtherEvents from "../extracurricular-events/OtherEvents";
import PagesHeading from "../pages-heding/PagesHeading";

const OtherEventsPage = (props) => {
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<OtherEvents></OtherEvents>
		</Fragment>
	);
};

export default OtherEventsPage;
