import React, { Fragment, useState } from "react";
import "./TeacherRegistration.css";
import { PagesQuotesDown } from "../pages-quotes-down/PagesQuotesDown";
import { api } from "../../helper/api";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import { EmailInput, MobileNumberInput, TextInput } from "../InputFeilds";
const TeacherRegistration = () => {
	const [formData, updateFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
			teacher_form: true,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		updateFormData({
			...formData,
			teacher_form: true,
		});
		const response = await api({
			method: "post",
			url: "inquiry",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;

		if (
			jsonData?.status_message === "Form Submitted" &&
			jsonData?.status === 200
		) {
			setLoading(false);
			toast.success("Registration successfully submitted!");
			window.location.reload();
		} else {
			setLoading(false);
			toast.error("Something Occur! Please try again later");
		}
	};
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="admission-enquiry-form-container faculty-login-container">
				<div className="admission-enquiry-form">
					<form
						className="admission-enquiry-form-start container"
						onSubmit={handleSubmit}
					>
						<TextInput
							label={"Full Name"}
							id="fullname"
							onChange={handleChange}
							placeholder="Enter Name"
							defaultValue={formData?.fullname}
							isValidated={isValidated}
							required
						/>

						<EmailInput
							label={"E-mail"}
							id="email"
							onChange={handleChange}
							isValidated={isValidated}
							defaultValue={formData?.email}
							placeholder="Enter email"
							className={"mt-3"}
							required
						/>
						<MobileNumberInput
							label={"Mobile No."}
							id="phone"
							onChange={handleChange}
							placeholder="Enter The Number"
							maxlength={"10"}
							className={"mt-3"}
							isValidated={isValidated}
							defaultValue={formData?.phone}
							required
						/>

						<div className="admission-enquiry-form-btn-container faculty-login-btn-continer">
							<button
								type="submit"
								className="admission-enquiry-form-submit-btn faculty-login-btn"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default TeacherRegistration;
