import React, { Fragment } from 'react'
import './dynamicexamtimetable.css';
import { FaEye } from 'react-icons/fa';
const DynamicExamTimeTable = (props) => {
    const ExaminationDetails = [{
        id:1,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:2,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:3,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:4,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:5,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:6,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:7,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    },{
        id:8,
        specialization:"",
        type:"University",
        semester:"Semester IV",
        date:"2021-01-20",
        academic_year:"2020-2021",
        notice:"MMS Sem IV Examination 2021 Time table (choice based)",
        file_url:""
    }]
  return (
    <Fragment>
        <div className='dynamic-exam-time-table-container container'>
            <div className='dynamic-course-heading'>
                <h1>{props.course_title}</h1>
            </div>
            <div className='dyanamic-exam-time-table'>
                <table className='dynamic-exam-time-table'>
                    <tr>
                        <th>No.</th>
                        <th>Specialization</th>
                        <th>Date</th>
                        <th>Notice</th>
                        <th>Type</th>
                        <th>Semester</th>
                        <th>Academic Year</th>
                        <th>File</th>
                    </tr>
                    {ExaminationDetails.map((item,index)=>{
                        return(
                        <tr key={index}>
                            <td>{item.id}.</td>
                            <td>{item.specialization}</td>
                            <td>{item.date}</td>
                            <td>{item.notice}</td>
                            <td>{item.type}</td>
                            <td>{item.semester}</td>
                            <td>{item.academic_year}</td>
                            <td className='dyanmic-exam-time-table-pdf'><FaEye className='dyanmic-exam-time-table-pdf-view'></FaEye></td>
                        </tr>)
                    })}
                </table>
            </div>
        </div>
    </Fragment>
  )
}

export default DynamicExamTimeTable