import React, { Fragment, useEffect, useState,useRef } from "react";
import "./latestnews.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const LatestNews = () => {
	const [newsData, setNewsData] = useState([]);
	const [loading, setLoading] = useState(true);

	const marqueeRef = useRef(null);
	const handleMouseEnter = () => {
		if (marqueeRef.current) {
		marqueeRef.current.stop();
		}
	};

	const handleMouseLeave = () => {
		if (marqueeRef.current) {
		marqueeRef.current.start();
		}
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { get_home_event: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setNewsData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadData();
	}, []);

	return (
		<Fragment>
			<div className="latest-news-events-container">
				<div className="latest-news-events">
					<div className="background-image home-page-latest-news-img">
						<img
							src="home_page_slider/college_image.png"
							alt="College Image"
						/>
					</div>
					<div className="latest-news">
						<h4>EVENTS & LATEST NEWS</h4>
						<div className="marquee-container"
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						>
							<marquee
								direction="up"
								className="latest-news-marquee-container"
								scrollamount="6"
								ref={marqueeRef}
							>
								{newsData.length > 0
									? newsData.map((data, id) => (
											<a
												href={`${data.event_link}`}
												className="marquee-content"
												target="_blank"
												key={id}
											>
												<h5 className="event-date">
													{data.event_date}
												</h5>
												<p>{data.event_name}</p>
											</a>
									  ))
									: "No EVENT & FOUND"}
							</marquee>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default LatestNews;
