import React, { Fragment, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import './mandatorycommittees.css'
import MandatoryCommitteesCard from '../popup-cards/MandatoryCommitteesCard'
const MandatoryCommittees = () => {
    const [showModal, setShowModal] = useState(false)
    const [getData, setGetData] = useState("")
    const mandatorycommitteesDetails = [
        {
            id: 1,
            title: "Student Council 2020-21",
            students_array: [
                "Ms. Sagar Chaudhari (Member (Students))",
                "Ms. Nasreen Ansari (Member (Students))",
                "Ms. Vaibhavi Parmar (Member (Students))",
                "Ms. Shivani Mungekar (Member (Students))",
                "Mr. Prakash Vaishnav (Member (Students))",
                "Mr. Omkar Phondekar (Member (Students))",
                "Ms. Chinmayi Bharadwaj (General Secretary)",
                "Mr. Siddesh Yadav (Joint Secretary)",
                "Dr. Ketan Vira (MENTOR-cum-FACILITATOR)",
                "Prof. Vivek Nerurkar (MENTOR-cum-FACILITATOR)"
            ],
            url: ""
        },
        {
            id: 1,
            title: "Student Council 2020-21",
            students_array: [
                "Ms. Sagar Chaudhari (Member (Students))",
                "Ms. Nasreen Ansari (Member (Students))",
                "Ms. Vaibhavi Parmar (Member (Students))",
                "Ms. Shivani Mungekar (Member (Students))",
                "Mr. Prakash Vaishnav (Member (Students))",
                "Mr. Omkar Phondekar (Member (Students))",
                "Ms. Chinmayi Bharadwaj (General Secretary)",
                "Mr. Siddesh Yadav (Joint Secretary)",
                "Dr. Ketan Vira (MENTOR-cum-FACILITATOR)",
                "Prof. Vivek Nerurkar (MENTOR-cum-FACILITATOR)"
            ],
            url: ""
        },
        {
            id: 1,
            title: "Student Council 2020-21",
            students_array: [
                "Ms. Sagar Chaudhari (Member (Students))",
                "Ms. Nasreen Ansari (Member (Students))",
                "Ms. Vaibhavi Parmar (Member (Students))",
                "Ms. Shivani Mungekar (Member (Students))",
                "Mr. Prakash Vaishnav (Member (Students))",
                "Mr. Omkar Phondekar (Member (Students))",
                "Ms. Chinmayi Bharadwaj (General Secretary)",
                "Mr. Siddesh Yadav (Joint Secretary)",
                "Dr. Ketan Vira (MENTOR-cum-FACILITATOR)",
                "Prof. Vivek Nerurkar (MENTOR-cum-FACILITATOR)"
            ],
            url: ""
        }, {
            id: 1,
            title: "Student Council 2020-21",
            students_array: [
                "Ms. Sagar Chaudhari (Member (Students))",
                "Ms. Nasreen Ansari (Member (Students))",
                "Ms. Vaibhavi Parmar (Member (Students))",
                "Ms. Shivani Mungekar (Member (Students))",
                "Mr. Prakash Vaishnav (Member (Students))",
                "Mr. Omkar Phondekar (Member (Students))",
                "Ms. Chinmayi Bharadwaj (General Secretary)",
                "Mr. Siddesh Yadav (Joint Secretary)",
                "Dr. Ketan Vira (MENTOR-cum-FACILITATOR)",
                "Prof. Vivek Nerurkar (MENTOR-cum-FACILITATOR)"
            ],
            url: ""
        }
    ]
    const getStudenDetails = (student_details) =>{
        console.log("Students_detaiils",student_details)
        setGetData(student_details)
        return (
            setShowModal(!false)
        )
    }
    console.log(getData)
    return (
        <Fragment>
            <div className='mandatory-committees-container container'>
                <div className='mandatory-committees-row'>
                    {mandatorycommitteesDetails.map((item, index) => {
                        return (
                            <div className='mandatory-committees-columns' key={index}>
                                <div className='mandatory-committees-title'>
                                    {item.title}
                                </div>
                                <div className='mandatory-committees-pdf' onClick={()=>{getStudenDetails(item.students_array)}}>
                                    <FaEye className='mandatory-committees-pdf-view' />
                                </div>
                            </div>
                        )
                    })}
                </div>
                { showModal ? <MandatoryCommitteesCard show={showModal} mandatory_committe_details={getData} hide={() => setShowModal(false)}/> : ""}
            </div>
        </Fragment>
    )
}

export default MandatoryCommittees