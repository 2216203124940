import React, { Fragment } from 'react'
import './pagesheading.css';

const PagesHeading = (props) => {
    return (
        <Fragment>
            <div className='pages-heading-container'>
                <div className='pages-heading'>
                    <h2>{props.page_title}</h2>
                </div>
            </div>

        </Fragment>
    )
}

export default PagesHeading;