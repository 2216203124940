import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "./codeofethics.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CodeOfEthics = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_code_ethics: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="code-of-ethics-container container">
				<div className="code-of-ethics-content">
					<p>
						A code of ethics document may outline the mission and
						values of the business or organization, how
						professionals are supposed to approach problems, the
						ethical principles based on the organization's core
						values and the standards to which the professional is
						held.
					</p>
				</div>
				<div className="code-of-ethics-table-container">
					{data.length > 0 ? (
						<table className="code-of-ethics-table">
							<tbody>
								{data.map((item, index) => {
									return (
										<tr key={index}>
											<td>{index + 1}.</td>
											<td>{item.title}</td>
											<td className="code-of-ethics-pdf">
												<a href={item.pdf}>
													<FaEye className="code-of-ethics-pdf-view" />
												</a>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						"No data Added"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CodeOfEthics;
