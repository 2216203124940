import React, { Fragment, useEffect, useState } from "react";
import "./mandatorydisclosure.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const MandatoryDisclosure = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_mandatory_disclosure: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="mandatory-disclosure-container container">
				<div className="mandatory-disclosur-view-table">
					<div className="table1-column-container">
						{data.length > 0 ? (
							<table className="table-column1" width="100%">
								<tbody>
									{data.map((item, id) => (
										<tr>
											<td>{id + 1}.</td>
											<td>{item.title}</td>
											<td className="view-pdf-eye">
												<a
													href={item.pdf}
													target="_blank"
												>
													<FaEye />
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							"No Data Added"
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default MandatoryDisclosure;
