import React, { Fragment, useEffect, useState } from "react";

import "./privacypolicy.css";
import Loading from "../Loading/Loading";
import { api } from "../../helper/api";
const PrivacyPolicy = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_privacy_policy: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="privacy-policy-container container">
				{data && (
					<div
						className="privacy-policy"
						dangerouslySetInnerHTML={{
							__html: data && data.privacy_policy,
						}}
					></div>
				)}
			</div>
		</Fragment>
	);
};

export default PrivacyPolicy;
