import React, { Fragment } from 'react'
import CodeOfEthics from '../code-of-ethics/CodeOfEthics'
import PagesHeading from '../pages-heding/PagesHeading'

const CodeOfEthicsPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <CodeOfEthics></CodeOfEthics>
    </Fragment>
  )
}

export default CodeOfEthicsPage