import React, { Fragment, useEffect, useState } from "react";
import "./studentsachievents.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const StudentsAchievements = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_student_achievements: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="students-achievements-container">
				<div className="students-achievements">
					{data.length > 0
						? data.map((item, id) => (
								<div className="students-achievements-columns">
									<div className="students-achievements-table-container">
										<table className="students-achievements-table">
											<caption>{item.title}</caption>
											<tr>
												<th>Date</th>
												<th>Academic Year</th>
												<th>Level</th>
											</tr>
											<tr>
												<td>{item.date}</td>
												<td>{item.academic_year}</td>
												<td>{item.level}</td>
											</tr>
										</table>
									</div>
									<div className="students-achiievements-mentors-heading">
										<h4>Mentor : </h4>
										<p>{item.mentor}</p>
									</div>
									<div className="students-achiievements-award">
										<p>{item.details}</p>
										<strong className="">
											Added By :{" "}
											{item.faculty_name
												? item.faculty_name
												: "Admin"}
										</strong>
										<br />
									</div>
								</div>
						  ))
						: "No Data Found !"}
				</div>
			</div>
		</Fragment>
	);
};

export default StudentsAchievements;
