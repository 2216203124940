import React, { Fragment, useEffect, useState } from "react";
import "./organizationalstructure.css";
import { api } from "../../helper/api";
const OrganizationalStructure = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_Organizationstructure: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				console.log(jsonData.data);
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	console.log(data);
	return (
		<Fragment>
			<div className="organizational-structure-container container">
				{data && (
					<div className="organizational-strcuture-image-container">
						<img src={data.img} alt="image alt" />
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default OrganizationalStructure;
