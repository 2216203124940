import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import NextSlider from "../custome-slider-button/NextSlider";
import PreviousSlider from "../custome-slider-button/PreviousSlider";
import "./toprecruiters.css";
import { api } from "../../helper/api";

const TopRecruiters = () => {
	const [recruiters, seRecruiters] = useState([]);
	const [loading, setLoading] = useState(true);

	// const recruiters = [
	// 	{
	// 		id: 1,
	// 		image: "Recruiters/tcs-logo.png",
	// 		image_alt: "TCS",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 2,
	// 		image: "Recruiters/hdfc.jpg",
	// 		image_alt: "HDFC",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 3,
	// 		image: "Recruiters/hm-logo.png",
	// 		image_alt: "H&M",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 4,
	// 		image: "Recruiters/idfc-bank.png",
	// 		image_alt: "IDFC",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 5,
	// 		image: "Recruiters/bata-india.jpg",
	// 		image_alt: "HDFC",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 6,
	// 		image: "Recruiters/capgemini.jpg",
	// 		image_alt: "HDFC",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 7,
	// 		image: "Recruiters/clover.jpg",
	// 		image_alt: "HDFC",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 8,
	// 		image: "Recruiters/mrninstar.jpg",
	// 		image_alt: "HDFC",
	// 		is_Active: true,
	// 	},
	// 	{
	// 		id: 10,
	// 		image: "Recruiters/jhonson.jpg",
	// 		image_alt: "HDFC",
	// 		is_Active: true,
	// 	},
	// ];

	const sliderSettings = {
		infinity: true,
		speed: 800,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: false,
		lazyload: true,
		swipe: true,
		autoplay: true,
		autoplaySpeed: 1000,
		speed: 1500,
		dots: false,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					lazyload: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					lazyload: true,
					dots: false,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					lazyload: true,
					swipe: true,
					dots: false,
				},
			},
		],
	};

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_recuriter: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				seRecruiters(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{recruiters && (
				<div className="our-top-recruiters-container">
					<div className="top-recuiters-heading">
						<h2>Our Top Recruiters </h2>
					</div>
					<div className="company-slider-container">
						<Slider {...sliderSettings}>
							{recruiters.length > 5 &&
								recruiters.map((item, index) => {
									return (
										<div
											className="company-container"
											key={index}
										>
											<img
												src={item.photo}
												alt={item.name}
												className="company-image"
											/>
										</div>
									);
								})}
						</Slider>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default TopRecruiters;
