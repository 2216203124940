import React, { Fragment, useState } from 'react'
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import './popupimagesliderlightbox.css'
const PopupImageSliderLightbox = (props) => {
    const [animationDuration, setAnimationDuration] = useState(500);
  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(1);
  const [zoomInMultiplier, setZoomInMultiplier] = useState(2);
  const [doubleTapDelay, setDoubleTapDelay] = useState(300);
  const [doubleClickDelay, setDoubleClickDelay] = useState(300);
  const [doubleClickMaxStops, setDoubleClickMaxStops] = useState(2);
  const [keyboardMoveDistance, setKeyboardMoveDistance] = useState(50);
  const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] = useState(100);
  const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] = useState(100);
  const [scrollToZoom, setScrollToZoom] = useState(false);
    // const imageslider = [
    //     {
    //         src:'https://www.radicalminds.in/assets/awards/29a6b8d14d38271d603ba24f6ae277e50_award_image0.jpg'
    //     },
    //     {
    //         src:'https://www.radicalminds.in/assets/awards/29a6b8d14d38271d603ba24f6ae277e50_award_image0.jpg'
    //     },
    //     {
    //         src:'https://www.radicalminds.in/assets/awards/29a6b8d14d38271d603ba24f6ae277e50_award_image0.jpg'
    //     },
    //     {
    //         src:'https://www.radicalminds.in/assets/awards/29a6b8d14d38271d603ba24f6ae277e50_award_image0.jpg'
    //     }
    // ]
    const [advancedExampleOpen,setAdvancedExampleOpen ] = useState(false)
  return (
   <Fragment>
    <button onClick={()=>{setAdvancedExampleOpen(true)}}>OPEN</button>
    <Lightbox
        open={props.isOpen}
        close={props.onHide}
        slides={props.photo_array}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
   </Fragment>
  )
}

export default PopupImageSliderLightbox