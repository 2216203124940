import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import RefundPolicy from '../refund-policy/RefundPolicy'

const RefundPolicyPage = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title}></PagesHeading>
            <RefundPolicy></RefundPolicy>
        </Fragment>
    )
}
export default RefundPolicyPage