import React, { Fragment } from "react";
import "./collegedevcommitee.css";
const CollegeDEvelopmentCommitteeTable = (props) => {
	return (
		<Fragment>
			<div className="college-development-committee-container container">
				<div className="college-development-commite-table-container">
					<table className="college-development-commite-table">
						<tbody>
							<tr>
								<th>Sr.no</th>
								<th>Name</th>
								<th>Designation</th>
							</tr>
							{props.CommitteMember_details.map((item, index) => {
								return (
									<tr key={index + 1}>
										<td>{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.designation}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	);
};

export default CollegeDEvelopmentCommitteeTable;
