import React, { Fragment } from "react";
import TeacherRegistration from "../teacher-registration/TeacherRegistration";
import PagesHeading from "../pages-heding/PagesHeading";

const TeacherRegistrationPage = (props) => {
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<TeacherRegistration></TeacherRegistration>
		</Fragment>
	);
};

export default TeacherRegistrationPage;
