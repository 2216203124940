import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import BestPracticesIGLTwoPointO from '../best-practices-igl-two-point-o/BestPracticesIGLTwoPointO'

const IGLTwoPointOPage = (props) => {
  return (
    <Fragment>
        <BestPracticesIGLTwoPointO/>
    </Fragment>
  )
}

export default IGLTwoPointOPage