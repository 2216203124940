import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import ManagementDevProgram from '../management-development-program/ManagementDevProgram'

const ManagementDevProgramPage = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title}></PagesHeading>
            <ManagementDevProgram></ManagementDevProgram>
        </Fragment>
    )
}

export default ManagementDevProgramPage