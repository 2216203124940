import { Fragment, useEffect, useState } from "react";
import "./footer.css";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";

const Footer = () => {
	const date = new Date();
	const [scriptLoaded, setScriptLoaded] = useState(false);

	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			"https://www.freevisitorcounters.com/en/home/counter/1142907/t/5";
		script.type = "text/javascript";
		script.async = true;

		const container = document.getElementById("visitors-count");
		container.appendChild(script);

		const handleScriptLoad = () => {
			setScriptLoaded(true);
		};

		script.addEventListener("load", handleScriptLoad);

		return () => {
			script.removeEventListener("load", handleScriptLoad);
			container.removeChild(script);
		};
	}, []);

	return (
		<Fragment>
			<div className="footer-container">
				<div className="footer-row">
					<div className="footer-column column1">
						<ul>
							<li>
								<Link to="/teacher-registration">Register</Link>
							</li>
							<li>
								<a href="manage" target="_blank">
									Faculty Login
								</a>
							</li>
							<li>
								<Link
									to="/admission
								">
									Online Payment
								</Link>
							</li>
							<li>
								<Link to="/student-council">
									Student Council
								</Link>
							</li>
							<li>
								<Link to="/massive-open-online-courses">
									Massive Open Online Courses (MOOCs)
								</Link>
							</li>
							<li>
								<Link to="https://www.education.gov.in/">
									MHRD Schemes
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer-column column2">
						<ul>
							<li>
								<Link to="/faculty-development-program">
									Faculty Development Program (FDP)
								</Link>
							</li>
							<li>
								<Link to="/management-development-program">
									Management Development Program (MDP)
								</Link>
							</li>
							<li>
								<Link to="/register-for-fdp-mdp">
									Register For FDP/MDP
								</Link>
							</li>
							<li>
								<Link to="/event-registration">
									Event Register
								</Link>
							</li>
							<li>
								<Link to="/student-welfare-services">
									Student Welfare Services
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer-column column3">
						<ul>
							<li>
								<Link to="/prospectus">Prospectus</Link>
							</li>
							<li>
								<Link to="/photo-gallery">Photo Gallery</Link>
							</li>
							<li>
								<Link to="/college-committees">
									College Committees
								</Link>
							</li>
							<li>
								<Link to="/mous">MoU</Link>
							</li>
							<li>
								<Link to="/nirf_d">NIRF</Link>
							</li>
							<li>
								<Link to="/download-study-material">
									Download Study Material
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer-column column3">
						<ul>
							<li>
								<Link to="/faq">FAQ</Link>
							</li>
							<li>
								<Link to="/feedback">Feedback</Link>
							</li>
							<li>
								<Link to="/privacy-policy">Privacy Policy</Link>
							</li>
							<li>
								<Link to="/terms-and-conditions">
									Terms and Conditions
								</Link>
							</li>
							<li>
								<Link to="/refund-policy">Refund Policy</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="footer-icons social-media-icons">
					<hr className="hrfirst"></hr>
					<div className="social-media">
						<SocialIcon
							url="https://api.whatsapp.com/send?phone=919321478440"
							network="whatsapp"
							style={{ height: 30, width: 30 }}
							fbcolor="#008080"
							bgColor="#fff"
							className="social-media-links whatsapp"
						/>
						<SocialIcon
							url="https://www.facebook.com/GNVSInstitute/"
							network="facebook"
							style={{ height: 30, width: 30 }}
							fbcolor="#008080"
							bgColor="#fff"
							className="social-media-links facebook"
						/>
						<SocialIcon
							url="https://www.instagram.com/gnvsiom/"
							network="instagram"
							style={{ height: 30, width: 30 }}
							fbcolor="#008080"
							bgColor="#fff"
							className="social-media-links instagram"
						/>
						<SocialIcon
							url="https://www.youtube.com/channel/UCbqn1hmUZ1l0kIV0TzzHWJA"
							network="youtube"
							style={{ height: 30, width: 30 }}
							fbcolor="#008080"
							bgColor="#fff"
							className="social-media-links youtube"
						/>
						<SocialIcon
							url="https://twitter.com/gnvs_im?lang=en"
							network="twitter"
							style={{ height: 30, width: 30 }}
							fbcolor="#008080"
							bgColor="#fff"
							className="social-media-links twitter"
						/>
						<SocialIcon
							url="https://www.linkedin.com/school/gnvs-institute-of-management-mumbai/"
							network="linkedin"
							style={{ height: 30, width: 30 }}
							fbcolor="#008080"
							bgColor="#fff"
							className="social-media-links linkedin"
						/>
					</div>
					<hr className="hrsecond"></hr>
				</div>
				<div className="all-right-reserved-container">
					<a href="/" className="all-right-reserved">
						All Rights Reserved | Copyright © {date.getFullYear()} -
						GNVS Institute of Management
					</a>
					<br />
					{/* {document.write(
						'<a href="https://www.freevisitorcounters.com/en/home/stats/id/1142846" target="_blank"><br><img src="https://www.freevisitorcounters.com/en/counter/render/1142846/t/5" border="0" class="counterimg"></a>'
					)} */}
					<div id="visitors-count">
						{scriptLoaded && (
							<img
								src="https://www.freevisitorcounters.com/en/counter/render/1142907/t/5"
								border="0"
								className="counterimg"
								alt="Visitor Counter"
							/>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default Footer;