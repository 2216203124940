import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import AdmissionEnquiryForm from '../online-enquiry-form/AdmissionEnquiryForm'

const AdmissionForm = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <AdmissionEnquiryForm></AdmissionEnquiryForm>
    </Fragment>
  )
}

export default AdmissionForm