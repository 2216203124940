import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import "./faqpage.css";
import { api } from "../../helper/api";
const FAQ = () => {
	const [faqData, setFaqData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_faq: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setFaqData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			<div className="faq-page-container container-fluid">
				<div className="faq-page-row-grid">
					{faqData.length > 0
						? faqData.map((item, index) => {
								return (
									<div
										className="faq-page-single-column"
										key={index}
									>
										<div className="faq-pagequestion-container">
											<p>{item.faq_quest}</p>
										</div>
										<div className="faq-page-answer-container">
											<p>{item.faq_ans}</p>
										</div>
									</div>
								);
						  })
						: "No Faq Added"}
				</div>
			</div>
		</Fragment>
	);
};

export default FAQ;
