import React, { Fragment, useEffect, useState } from "react";
import "./examtimetable.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
export const ExamTimeTable = () => {
	// const examTimeTable = [{
	//     id: 1,
	//     bach_year: "2019-2021",
	//     course_name: "Time Table MMS Semester IV Batch 2019-2021",
	//     time_table_pdf_url: ""
	// },
	// {
	//     id: 2,
	//     bach_year: "2020-2022",
	//     course_name: "Time Table MMS Semester I Batch 2020-2022",
	//     time_table_pdf_url: ""
	// },
	// {
	//     id: 3,
	//     bach_year: "2020-2022",
	//     course_name: "Revised Time Table MMS Semester I Batch 2020-2022",
	//     time_table_pdf_url: ""
	// }]

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_exam_timetable: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="exam-time-table-container container">
				<div className="exam-time-table">
					{data && data.length > 0 ? (
						<table className="time-table-container">
							<thead>
								<tr>
									<th>Sr.No.</th>
									<th>Program Name</th>
									<th>Date</th>
									<th>Notices</th>
									<th>Type</th>
									<th>Semester</th>
									<th>Academic Year</th>
									<th>File</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, id) => (
									<tr>
										<td className="time-table-course-name">
											{id + 1}
										</td>
										<td className="time-table-course-name">
											{item.name}
										</td>
										<td className="time-table-course-name">
										 	{item.date}
										</td>
										<td className="time-table-course-name">
											{item.notices}
										</td>
										<td className="time-table-course-name">
											{item.type}
										</td>
										<td className="time-table-course-name">
											Semester {item.semester}
										</td>
										<td className="time-table-course-name">
											{item.academic_year}
										</td>
										<td className="view-time-table-pdf">
											<a
												href={item.pdf_file}
												target="_blank"
											>
												<FaEye />
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						"NO DATA ADDED!"
					)}
				</div>
			</div>
		</Fragment>
	);
};
