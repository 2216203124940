import React, { Fragment } from 'react'
import './welcomemessage.css'
const WelcomeMessage = () => {
    return (
        <Fragment>
            <div className='welcome-message-container'>
                <div className='welcome-message-row'>
                    <div className='welcome-message column1'>
                        <h1>Welcome to gnvs institute of managment</h1>
                    </div>
                    <div className='welcome-message column2'>
                        <p className='first-para'>GNVS IOM takes prides in being a Management Institute that is student centric. Our students are our primary stakeholders and the pedagogy encompasses a new-age experiential learning mechanism that is up with current industry realities. Right since its inception in 2010, the institute has strived to be a centre of excellence. Affiliated to the University Of Mumbai, AICTE approved and it is a NAAC Accredited Management Institute.</p>
                       <br></br>
                        <p>The Institute offers the MMS program which is of two years. Human Resource Management, Marketing, Finance, Operations and Systems - IT are the specializations.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default WelcomeMessage