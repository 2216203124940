import React, { Fragment, useEffect, useState } from "react";
import "./refundpolicy.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const RefundPolicy = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_refund_policy: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="refund-policy-container container">
				{data && (
					<div
						className="refund-policy-content"
						dangerouslySetInnerHTML={{
							__html: data && data.refund_policy,
						}}
					></div>
				)}
			</div>
		</Fragment>
	);
};

export default RefundPolicy;
