import React from 'react'
import { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import NIRF from '../nirf-page/NIRF'

const NIRFPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <NIRF></NIRF>
    </Fragment>
  )
}

export default NIRFPage