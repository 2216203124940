import React, { Fragment } from 'react'
import CapabilityEnhancement from '../capability-enhancement/CapabilityEnhancement'
import PagesHeading from '../pages-heding/PagesHeading'

const CapabilityEnhancementPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <CapabilityEnhancement ></CapabilityEnhancement>
    </Fragment>
  )
}

export default CapabilityEnhancementPage