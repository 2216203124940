import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import MassiveOnlineCourses from '../massive-open-online-courses/MassiveOnlineCourses '

const MassiveOnlineCoursesPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <MassiveOnlineCourses></MassiveOnlineCourses>
    </Fragment>
  )
}

export default MassiveOnlineCoursesPage