import React, { Fragment, useEffect, useState } from "react";
import "./typewritter.css";
import Typewriter from "typewriter-effect";
import { api } from "../../helper/api";

const TypeWritter = () => {
	const [storyData, setStoryData] = useState([]);
	// const [loading, setLoading] = useState(true);
	const [strings, setStrings] = useState([]);

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { get_home_story: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setStoryData(jsonData.data);
				// setLoading(false);
			} catch (err) {
				// setLoading(false);
			}
		};
		loadData();
	}, []);
	useEffect(() => {
		if (storyData) {
			// setLoading(true);
			storyData.forEach((item) => {
				let story = `<a href='${item.story_link}' tagrget='_blank' class="Typewriter">${item.story_name}</a>`;

				setStrings((data) => [...data, story]);
			});
			// setLoading(false);
		}
	}, [storyData]);

	return (
		<Fragment>
			<div className="typewritter-container">
				<div className="type-wriiter">
					<Typewriter
						options={{
							strings: strings,
							autoStart: true,
							loop: true,
							pauseFor: 1500,
							wrapperClassName: "tye-writer-wraper",
						}}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default TypeWritter;
