import React, { Fragment } from "react";
import PagesHeading from "../pages-heding/PagesHeading";
import AcademicYearDetails from "../academicyear-details/AcademicYearDetails";

const AcademicYearDetailsPage = (props) => {
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<AcademicYearDetails></AcademicYearDetails>
		</Fragment>
	);
};

export default AcademicYearDetailsPage;
