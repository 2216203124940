import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./developmentinitiative.css";
const DevelopmentInitiative = () => {
	const DevelopmemntButtons = [
		{
			id: 1,
			button_name: "CO - CURRICULAR",
			is_Active: true,
			url: "/co-curricular",
		},
		{
			id: 2,
			button_name: "EXTRACURRICULAR",
			is_Active: true,
			url: "/extracurricular",
		},
		{
			id: 3,
			button_name: "CAPAPIBILTY ENHANCEMENT & DEVELOPMENTS SCHEMES",
			is_Active: true,
			url: "/capapibilty-enhancement-development-schemes",
		},
		{
			id: 4,
			button_name: "BEST PRACTICES",
			is_Active: true,
			url: "/best-practices",
		},
		{
			id: 5,
			button_name: "OTHERS",
			is_Active: true,
			url: "/other-events",
		},
	];
	return (
		<Fragment>
			<div className="development-initiative-container container">
				<div className="development-initiative-buttons">
					{DevelopmemntButtons.map((item, index) => {
						return (
							<div className="development-button" key={index}>
								<Link to={item.url}>
									<button>{item.button_name}</button>
								</Link>
							</div>
						);
					})}
				</div>
			</div>
		</Fragment>
	);
};

export default DevelopmentInitiative;
