import React, { Fragment, useEffect } from "react";
import "./publications.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const Publications = () => {
	const publicationsDetails = [
		{
			id: 1,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
		{
			id: 2,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
		{
			id: 3,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
		{
			id: 4,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
		{
			id: 5,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
		{
			id: 6,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
		{
			id: 7,
			year: "2010-2011",
			international: 11,
			national: 2,
			others: 0,
			url: "",
		},
	];

	const [publicationData, setPublicationData] = useState(null);
	const [totalNation, setTotalNation] = useState(0);
	const [totalInternational, setTotalInternational] = useState(0);
	const [totalOther, setTotalOther] = useState(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { get_research_publicaion: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setPublicationData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadData();
	}, []);

	useEffect(() => {
		const countOccurrences = (value, obj) => {
			let count = 0;
			Object.values(obj).forEach((item) => {
				console.log(item.level);
				if (item.level === value) {
					count++;
				}
			});
			return count;
		};
		if (publicationData) {
			let internationalCount = 0;
			let nationalCount = 0;
			let othersCount = 0;

			publicationData.forEach((item) => {
				internationalCount += item.international;
				nationalCount += item.national;
				othersCount += item.others;
			});

			setTotalNation(nationalCount);

			setTotalInternational(internationalCount);

			setTotalOther(othersCount);
		}
	}, [publicationData]);
	// console.log(publicationData);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="publications-container container">
				<div className="publications-content">
					<p>
						Research contains "imaginative and deliberate work
						attempted to expand the load of learning, including
						knowledge of humans, culture and society, and the
						utilization of this stock of knowledge to devise new
						applications. It is used to set up or affirm fact,
						reaffirm the consequences of past work, take care of new
						or existing issues, bolster hypotheses, or develop new
						speculations.A research project may also be an expansion
						on past work in the field. Research projects can be used
						to develop further knowledge on a topic.
					</p>
					<p>
						Following are the list of research publications by the
						faculty members and students of GNVS.
					</p>
				</div>
				<div className="all-publications-heading">
					<h4>All Publications</h4>
				</div>
				<div className="publication-count-container">
					<div className="publications-international-container">
						<div className="publications-international-count">
							<h5>{totalInternational}</h5>
						</div>
						<div className="publications-international-heading">
							<h5>International</h5>
						</div>
					</div>
					<div className="publications-national-container">
						<div className="publications-national-count">
							<h5>{totalNation}</h5>
						</div>
						<div className="publications-international-heading">
							<h5>National</h5>
						</div>
					</div>
					<div className="publications-other-container">
						<div className="publications-other-count">
							<h5>{totalOther}</h5>
						</div>
						<div className="publications-international-heading">
							<h5>Others</h5>
						</div>
					</div>
				</div>
				<div className="publications-table-container">
					{publicationData && publicationData.length > 0 ? (
						<table className="publications-table">
							<caption>Paper Presented</caption>
							<tr>
								<th>Year</th>
								<th>International</th>
								<th>National</th>
								<th>Others</th>
							</tr>
							{publicationData.map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<Link
												to={`/publications/${item.year}`}
											>
												{item.year}
											</Link>
										</td>
										<td>
											<Link
												to={`/publications/${item.year}/international`}
											>
												{item.international}
											</Link>
										</td>
										<td>
											<Link
												to={`/publications/${item.year}/national`}
											>
												{item.national}
											</Link>
										</td>
										<td>
											<Link
												to={`/publications/${item.year}/other`}
											>
												{item.others}
											</Link>
										</td>
									</tr>
								);
							})}
						</table>
					) : (
						""
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default Publications;
