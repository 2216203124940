import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import MandatoryCommittees from '../mandatory-committees/MandatoryCommittees'
const MandatoryCommitteesPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <MandatoryCommittees></MandatoryCommittees>
    </Fragment>
  )
}

export default MandatoryCommitteesPage