import React, { Fragment, useEffect, useState } from "react";

import GoverningBody from "../governing-body/GoverningBody";
import PagesHeading from "../pages-heding/PagesHeading";
import { api } from "./../../helper/api";

const GoverningBodyPages = (props) => {
	// const organisationMember = [
	// 	{
	// 		id: 1,
	// 		name: "Mrs. Harbajan Kaur Anand",
	// 		role: "Nominee of President",
	// 		position: "Chairman",
	// 		image: "images/chairman-image.jpg",
	// 	},
	// 	{
	// 		id: 2,
	// 		name: "Dr. R K Saily",
	// 		role: "Director In-charge",
	// 		position: "Member- Secretary",
	// 		image: "images/dr_raman_kumar_sailly.JPG",
	// 	},
	// 	{
	// 		id: 3,
	// 		name: "Dr. Bina Punjabi",
	// 		role: " Academic Advisor",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 4,
	// 		name: "Dr. Amit Dutta",
	// 		role: "Regional Officer & Deputy Director, AICTE Western Region",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 5,
	// 		name: "Mr. Pramod Naik",
	// 		role: "Jt. Director - DTE, Mumbai Regional Office",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 6,
	// 		name: "Mr. Narendra Patil",
	// 		role: "OSD - Systems - DTE, Mumbai Head Office",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 7,
	// 		name: "Mr. Datta Ghuge",
	// 		role: "Dy. Registrar - University of Mumbai",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 8,
	// 		name: "Mr. Joy Thomas",
	// 		role: "Banking Sector",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 9,
	// 		name: "Dr. Rajen Mehrotra",
	// 		role: "Education & Industrial Sector",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 10,
	// 		name: "Mr. Sivaram Maganty",
	// 		role: "Financial Sector",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 11,
	// 		name: "Dr. Anil Garg",
	// 		role: "Corporate Trainer",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 12,
	// 		name: "Dr. Ketan Vira ",
	// 		role: "Dean - Academics & Student Affairs",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// 	{
	// 		id: 13,
	// 		name: "Dr. Barnana Sarkar",
	// 		role: "Co-ordinator - IQAC",
	// 		position: "Member",
	// 		image: "/Alumni/Ajay.jpg",
	// 	},
	// ];

	const [organisationMember, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_GoverningBody: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				console.log(jsonData.data);
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<GoverningBody
				CommitteMember_details={organisationMember}
			></GoverningBody>
		</Fragment>
	);
};

export default GoverningBodyPages;
