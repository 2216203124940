import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./instituionachievements.css";
import ShowMoreAchievementsContent from "../popup-cards/ShowMoreAchievementsContent";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const InstitustionAchievements = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const [showModal, setShowModal] = useState(false);
	const getMoreContent = () => {
		return setShowModal(!false);
	};

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_institute_achievement: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="instituion-achievements-container container">
				<div className="instituion-achievements">
					{data.length > 0
						? data.map((item, id) => (
								<div
									className="instituion-achievements-columns"
									key={id}
								>
									<ul>
										<li>{item.title}</li>
									</ul>
								</div>
						  ))
						: "No Data Found"}
				</div>
				{showModal ? (
					<ShowMoreAchievementsContent
						show={showModal}
						onHide={() => {
							setShowModal(false);
						}}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};

export default InstitustionAchievements;
