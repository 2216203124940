import React, { Fragment, useEffect, useState } from "react";
import "./mastermanagementstudy.css";
import { Link } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const MasterManagementStudies = () => {
	// const mastersManagementStudyButtons = [
	// 	{
	// 		id: 1,
	// 		button_name: "E-Brochure for MMS",
	// 		button_classname: "e-brochure-for-mms mms-dyanmic-btn",
	// 		url: "/https://www.gnvsiom.org/assets/pdf/programs/814dad1d528bcb2fb38132e911795663GNVSIOM%20Brochure%202023.pdf",
	// 		redirection_link:
	// 			"https://www.gnvsiom.org/assets/pdf/programs/814dad1d528bcb2fb38132e911795663GNVSIOM%20Brochure%202023.pdf",
	// 	},
	// 	{
	// 		id: 2,
	// 		button_name: "Syllabus",
	// 		button_classname: "master-management-syllabus mms-dyanmic-btn",
	// 		url: "",
	// 		redirection_link: "/master-of-management-studies/syllabus",
	// 	},
	// 	{
	// 		id: 3,
	// 		button_name: "Admission",
	// 		button_classname: "master-management-admission mms-dyanmic-btn",
	// 		url: "",
	// 		redirection_link: "/admission",
	// 	},
	// 	{
	// 		id: 5,
	// 		button_name: "Admission Enquiry",
	// 		button_classname:
	// 			"master-management-admission-enquiry mms-dyanmic-btn",
	// 		url: "",
	// 		redirection_link: "/admission-enquiry",
	// 	},
	// 	{
	// 		id: 6,
	// 		button_name: "Time Table",
	// 		button_classname: "master-management-time-table mms-dyanmic-btn",
	// 		url: "",
	// 		redirection_link: "/exam-time-table",
	// 	},
	// 	{
	// 		id: 4,
	// 		button_name: "Academic & Activities Calendar",
	// 		button_classname:
	// 			"master-management-academic-activities mms-dyanmic-btn",
	// 		url: "",
	// 		redirection_link: "/academic-calendar",
	// 	},
	// ];

	const [tabs, setTabs] = useState([]);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadTabs = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_programTab: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setTabs(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_mms: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadTabs();
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="master-management-container container">
				<div className="master-management-imgage">
					<img
						src="images/masters_of_managment_banner.png"
						alt="MASTER OF MANAGEMENT STUDIES (MMS)"
					/>
				</div>
				{data.length > 0 &&
					data.map((item, id) => (
						<React.Fragment key={id}>
							<div className="objective-curriculum-heading">
								<h6>{item.heading}</h6>
							</div>
							<div
								className="objctive-curriculum-content"
								dangerouslySetInnerHTML={{
									__html: item && item.content,
								}}
							></div>
						</React.Fragment>
					))}

				<div className="master-management-buttons">
					{tabs &&
						tabs.length > 0 &&
						tabs.map((item, index) => {
							return (
								<a
									href={item.url}
									className="mms-dyanmic-btn"
									key={index}
								>
									<p>{item.name}</p>
								</a>
							);
						})}
				</div>
			</div>
		</Fragment>
	);
};

export default MasterManagementStudies;
