import React, { Fragment } from "react";
import PagesHeading from "../pages-heding/PagesHeading";
import BestPracticesBootCamp from "../best-practices-bootcamp/BestPracticesBootCamp";

const BestPracticesBootCampPage = () => {
	return (
		<Fragment>
			<BestPracticesBootCamp></BestPracticesBootCamp>
		</Fragment>
	);
};

export default BestPracticesBootCampPage;
