import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import './developmentinitiativepages.css';
import { FaEye } from 'react-icons/fa';
const CareerCounselling = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title}></PagesHeading>
            <div className='bridge-course-container container'>
                <div className='bride-course-heading'>
                    <h4>Campus to Corporate</h4>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>About</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>In semester 1, MMS students need counseling to identify to take the direction of their specialization, which will
                        eventually decide their future in the industry when they get their final placement. Another crucial juncture is
                        then in 3rd semester when they need to do job selection. That is when the professional career counselors step
                        in to guide the students.</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Associate Partner Description</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>Our initiative partner, the Board of Industry - Academia Partnerships is a youth organisation connecting & 
                        engaging Academics, Industry Professionals, and students on a common platform. BIAP has a team of experienced 
                        trainers, facilitators and academicians who have catered to varied learner groups right from school children, 
                        college students, private bankers, relationship managers, Individual Financial Advisors, Channel partner and 
                        Sales Managers.</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Objective</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>To bridge the gap between Industry and Academics</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Outcomes Description</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>To help improve Industry-readiness of MMS students by giving them a direction to their career</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Duration</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>One day, Semester 1 (Twice a academic year)</p>
                </div>
                <div className='associate-file-container'>
                    <div className='associate-file-heading'><p>Associate Partner File</p></div>
                    <button className='associate-file-pdf-button'><FaEye className='associate-file-pdf-view' /></button>
                </div>
            </div>
        </Fragment>
    )
}

export default CareerCounselling