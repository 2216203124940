import React, { Fragment } from 'react'
import DynamicExamTimeTable from '../dynamic-examination-time-table/DynamicExamTimeTable'
import PagesHeading from '../pages-heding/PagesHeading'

const ExaminationTimeTablePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <DynamicExamTimeTable course_title="Master Of Management Studies (MMS)"></DynamicExamTimeTable>
    </Fragment>
  )
}

export default ExaminationTimeTablePage