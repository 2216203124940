import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import ResearchJournal from '../research-journal/ResearchJournal'

const ResearchJournalPage = (props) => {
  return (
    <Fragment>
      <PagesHeading page_title={props.page_title}></PagesHeading>
      <ResearchJournal></ResearchJournal>
    </Fragment>
  )
}

export default ResearchJournalPage