import React, { Fragment } from "react";
import "./cocurricularcard.css";
const CoCurricularCard = (props) => {
	return (
		<Fragment>
			<div className="co-curricular-card-container">
				<div className="co-curricular-card-parent-container">
					{props.co_curricula_event.map((item, index) => {
						return (
							<div className="co-curricular-card" key={index}>
								<div className="co-curricular-card-heading">
									<h4>{item.name}</h4>
								</div>
								<div className="co-curricular-content">
									<div className="co-curricular-date">
										<strong>Date: {item.date}</strong>
									</div>
									<div className="co-curricular-event-content">
										<p>{item.details}</p>
										<strong className="">
											Added By :{" "}
											{item.faculty_name
												? item.faculty_name
												: "Admin"}
										</strong>
									</div>
									{/* {item.isMoreContent && (
                                        <div className='co-curricular-view-more-btn'>
                                            <button className='view-more-read-file-btn'>{item.isMoreContentType}</button>
                                        </div>
                                    )} */}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</Fragment>
	);
};

export default CoCurricularCard;
