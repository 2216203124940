import React, { Fragment, useEffect, useState } from "react";
import "./alumnispeak.css";
import { FaLinkedin } from "react-icons/fa";
import Slider from "react-slick";
import NextSlider from "../custome-slider-button/NextSlider";
import PreviousSlider from "../custome-slider-button/PreviousSlider";
import { api } from "../../helper/api";
const AlumniSpeak = () => {
	const [speakData, setSpeakData] = useState([]);
	const [loading, setLoading] = useState(true);

	const sliderSettings = {
		infinity: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		lazyload: true,
		swipe: true,
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 2000,
		dots: false,
		pauseOnHover: true,
		nextArrow: <NextSlider />,
		prevArrow: <PreviousSlider />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					lazyload: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					lazyload: true,
					dots: false,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					lazyload: true,
					swipe: true,
					dots: false,
				},
			},
		],
	};

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_alumni_speak: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setSpeakData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{speakData && (
				<div className="alumni-speak-container">
					<div className="alumni-speak">
						<div className="alumni-speak-heading">
							<h2>Alumni Speak</h2>
						</div>
						<div className="alumni-slider-container">
							<Slider {...sliderSettings}>
								{speakData.length > 0 &&
									speakData.map((data, id) => (
										<div className="alumni-slider" key={id}>
											<div className="alumni-img">
												<img
													src={data.profile}
													alt={data.name}
												/>
											</div>
											<div className="social-media-linkedin-icon">
												<a href={data.profile_link}>
													<FaLinkedin className="linked-icon-alumni" />
												</a>
											</div>
											<div className="alumni-message">
												<p>"{data.speak}".</p>
											</div>
											<div className="alumni-details">
												<h4>{data.name}</h4>
												<p>
													{data.year} Batch in
													{data.designation}
												</p>
											</div>
										</div>
									))}
							</Slider>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default AlumniSpeak;
