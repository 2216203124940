import React, { Fragment, useEffect, useState } from "react";

import PagesHeading from "../pages-heding/PagesHeading";
import "./bestpractices.css";
import { Link, useParams } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const BestPractices = (props) => {
	const url = useParams();
	console.log(url);
	const BestPracticesButtons = [
		{
			id: 1,
			button_name: "A.Y. 2020 - 2021",
			is_Active: true,
			url: "/best-practices/academic-year-2020",
		},
		{
			id: 2,
			button_name: "A.Y. 2018 - 2019",
			is_Active: true,
			url: "/best-practices/academic-year-2020",
		},
		{
			id: 3,
			button_name: "A.Y. 2019 - 2020",
			is_Active: true,
			url: "/best-practices/academic-year-2020 ",
		},
	];

	const [tab, setTab] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_tabs_best_practice: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setTab(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<PagesHeading page_title={props.page_title}></PagesHeading>
			<div className="best-practices-container container">
				<div className="best-practices-buttons">
					{tab.length > 0
						? tab.map((item, index) => {
								return (
									<div
										className="best-practices-button"
										key={index}
									>
										<Link to={item.tab_slug}>
											<button>{item.tab_name}</button>
										</Link>
									</div>
								);
						  })
						: "No Data Found"}
				</div>
			</div>
		</Fragment>
	);
};

export default BestPractices;
