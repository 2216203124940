import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import PhotoGallery from '../photo-gallery/PhotoGallery'

const PhotoGalleryPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <PhotoGallery></PhotoGallery>
    </Fragment>
  )
}

export default PhotoGalleryPage