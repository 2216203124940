import React, { Fragment } from 'react'
import './consulting.css'

const Cosulting = () => {
    return (
        <Fragment>
            <div className='consulting-container container'>
                <div className='consulting-content'>
                    <p>For any B-school the most important <b>stakeholder</b> is the corporate sector. The tie-up between a B-school and corporate is what gives the business schools a <b>cutting-edge advantage</b>. This tie-up is in the interest of both the parties. The business school gains in terms of academic inputs to its own students becoming more practical. The industry gains in terms of consulting / advisory services or training program at a reasonable cost. This is the reason why the business schools are trying to build up such symbiotic relationship.</p>
                </div>
                <div className='consulting-image-container'>
                    <img src="images/consulting_banner.png" alt="img alt" />
                </div>
            </div>
        </Fragment>
    )
}

export default Cosulting