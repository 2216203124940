import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import BestPracticesDetails from '../best-practices-details-page/BestPracticesDetails'

const BestPracticeDetailsPage = (porps) => {
  return (
    <Fragment>
        <BestPracticesDetails></BestPracticesDetails>
    </Fragment>
  )
}

export default BestPracticeDetailsPage