import React, { Fragment } from 'react'
import Cosulting from '../consulting/Cosulting'
import PageHeading from '../pages-heding/PagesHeading'
const ConsultingPage = (props) => {
  return (
    <Fragment>
        <PageHeading page_title={props.page_title}></PageHeading>
        <Cosulting></Cosulting>
    </Fragment>
  )
}

export default ConsultingPage