import React, { Fragment, useEffect, useState } from "react";

import "./bestpracticesbootcamp.css";
import { Link, useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import PagesHeading from "../pages-heding/PagesHeading";
const BestPracticesBootCamp = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	let loc = useParams();
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						fetch_inside_best_practice: true,
						subTab: loc.subtab_slug,
						tab: loc.tab_slug,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, [loc]);

	return (
		<Fragment>
			{data && (
				<PagesHeading page_title={data[0]?.subtab_name}></PagesHeading>
			)}

			<div className="best-practices-boot-camp-container container">
				<div className="best-practices-boot-camp-table-container">
					{data.length > 0 ? (
						<table className="best-practices-boot-camp-table">
							<tbody>
								{data.map((item, id) => (
									<tr key={id}>
										<td>{id + 1}</td>
										<td>{item.doc_title}</td>
										<td>
											<a target="_blank" href={item.pdf}>
												<FaEye className="best-practices-boot-camp-pdf-view" />
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						"No Data Found"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default BestPracticesBootCamp;
