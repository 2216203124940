import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import FacultyDevelopmentProgram from '../faculty-development-program/FacultyDevelopmentProgram'

const FacultyDevelopmentProgramPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <FacultyDevelopmentProgram></FacultyDevelopmentProgram>
    </Fragment>
  )
}

export default FacultyDevelopmentProgramPage