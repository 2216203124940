import React, { Fragment, useEffect, useState } from "react";
import "./massiveonlinecourses.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const MassiveOnlineCourses = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_mooc: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				console.log(jsonData.data);
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}
			<div className="massive-online-courses-container container">
				<div className="massive-online-courses-table-container">
					<table className="w-100 massive-online-courses-table">
						<tbody>
							{data &&
								data.length > 0 &&
								data.map((item, id) => (
									<tr>
										<td>{item.title}</td>
										<td>{item.content}</td>
										<td>
											<div className="massive-online-courses-view-link-container">
												<div className="massive-online-courses-pdf">
													<a
														href={item.link}
														target="_blank"
														className="mooc-link"
													>
														<FaEye className="massive-online-courses-pdf-view" />
														<p>Link Here</p>
													</a>
												</div>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	);
};

export default MassiveOnlineCourses;
