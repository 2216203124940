import React, { Fragment } from 'react'
import './facultydevprogramcard.css'
import { Modal } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr'
const FacultyDevelopmentProgramCard = (props) => {
    return (
        <Fragment>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.modalHeading}
                    </Modal.Title>
                    <GrClose onClick={props.onHide} className='close-popup-btn' />
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body row">
                        <strong className='col-3 faculty-development-p-modal'>Level</strong><span className='col-1 d-flex justify-content-center'>: </span><p className='col-8 faculty-development-p-modal'>{props.programdata.level}</p><br />
                        <strong className='col-3 faculty-development-p-modal'>Participated as</strong><span className='col-1 d-flex justify-content-center'>: </span><p className='col-8 faculty-development-p-modal'>{props.programdata.participated}</p>
                        <strong className='col-3 faculty-development-p-modal'>Venue</strong><span className='col-1 d-flex justify-content-center'>: </span><p className='col-8 faculty-development-p-modal'>{props.programdata.venue}</p>
                        <strong className='col-3 faculty-development-p-modal'>Date</strong><span className='col-1 d-flex justify-content-center'>: </span><p className='col-8 faculty-development-p-modal'>{props.programdata.date}</p>
                        <strong className='col-3 faculty-development-p-modal'>Academic year</strong><span className='col-1 d-flex justify-content-center'>: </span><p className='col-8 faculty-development-p-modal'>{props.programdata.academic_year}</p>
                        <strong className='col-3 faculty-development-p-modal'>Details</strong><span className='col-1 d-flex justify-content-center'>: </span><p className='col-8 faculty-development-p-modal'>{props.programdata.details}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default FacultyDevelopmentProgramCard