import React, { Fragment } from 'react'
import MMSSyllbaus from '../mms-syllabus/MMSSyllbaus'
import PagesHeading from '../pages-heding/PagesHeading'

const MMSSyllabusPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <MMSSyllbaus></MMSSyllbaus>
    </Fragment>
  )
}

export default MMSSyllabusPage