import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import Prospectus from '../prospectus/Prospectus'

const ProspectusPage = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title}></PagesHeading>
            <Prospectus></Prospectus>
        </Fragment>

    )
}

export default ProspectusPage