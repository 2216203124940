import React, { useEffect, useState } from "react";
import "./navbar.css";
import { FaChevronDown } from "react-icons/fa";
import { Link, Navigate } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const TopNavbar = () => {
	const [burgur, setBurgur] = useState(true);
	const [naacYear, setNaacYear] = useState([]);
	const [loading, setLoading] = useState(true);
	const [fraDisclouser, setFraDisclouser] = useState(null);

	const changeIcon = () => {
		setBurgur(!burgur);
	};
	const showSubMenu = (events) => {
		const active_submenu =
			events.target.classList.contains("submenu-active");
		events.currentTarget.classList.toggle("submenu-active");
	};

	useEffect(() => {
		const loadNaacYear = async () => {
			const response = await api({
				method: "post",
				url: "fetch.php",
				data: { header_naac_year: true },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setNaacYear(jsonData.data);
			setLoading(false);
		};
		const loadFRA = async () => {
			const response = await api({
				method: "post",
				url: "fetch.php",
				data: { header_fra: true },
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setFraDisclouser(jsonData.data);
			setLoading(false);
		};
		loadFRA();
		loadNaacYear();
	}, []);

	return (
		<>
			{/* {loading && <Loading />} */}
			<div className="collegelogo-container">
				<div className="collegelogo-row">
					<div className="college-logo column1 column">
						<div className="college-logo">
							<Link to="/">
								<img
									src="/images/collegelogo.png"
									alt="GNVS Institute of Management"
									className="college-logo-img"
								/>
							</Link>
						</div>
					</div>
					<div className="top-nav">
						<i
							onClick={() => {
								changeIcon();
							}}
							className={
								burgur
									? "fa-solid fa-bars burger-icon"
									: "fa-solid fa-xmark burger-icon"
							}
						></i>
					</div>
					<div className="college-email-phone column2 column">
						<div className="email-phone-container">
							<a
								href="tel:919321478440"
								className="header-college-phone-number"
							>
								<p>Phone : +91 9321478440 / +91 9326966204</p>
							</a>
							<a
								href="mailto:gnvsiom@gnvsiom.org"
								className="header-college-phone-number"
							>
								<p>Mail : gnvsiom@gnvsiom.org</p>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className={burgur ? "bottomnavbar" : "bottomnavbar active"}>
				<ul className="bottom-navbar-list">
					<li className="about" onClick={changeIcon}>
						<Link to="/">HOME</Link>
					</li>
					<li className="about" onClick={showSubMenu}>
						ABOUT US{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							<li>
								<Link
									to="/vision-missions"
									onClick={changeIcon}
								>
									VISION & MISSION
								</Link>{" "}
							</li>
							<li>
								<Link to="/core-values" onClick={changeIcon}>
									CORE VALUES
								</Link>
							</li>
							<li>
								<Link to="/about-us" onClick={changeIcon}>
									ABOUT US
								</Link>
							</li>
							<li>
								<Link
									to="/chairman-message"
									onClick={changeIcon}
								>
									CHAIRMAN'S MESSAGE
								</Link>
							</li>
							<li>
								<Link
									to="/director-message"
									onClick={changeIcon}
								>
									DIRECTOR'S MESSAGE
								</Link>
							</li>
							<li>
								<Link
									to="mandatory-disclosures"
									onClick={changeIcon}
								>
									MANDATORY DISCLOSURES
								</Link>
							</li>
							<li>
								<a
									target="_blank"
									href={
										fraDisclouser && fraDisclouser[0]?.pdf
									}
								>
									FRA DISCLOSURE
								</a>
							</li>
							<li>
								<Link
									to="/college-development-committee"
									onClick={changeIcon}
								>
									COLLEGE DEVELOPMENT COMMITTEE
								</Link>
							</li>
							<li>
								<Link to="/governing-body" onClick={changeIcon}>
									GOVERNING BODY
								</Link>
							</li>
							<li>
								<Link to="/organizational-structure">
									ORGANIZATIONAL STRUCTURE
								</Link>
							</li>
							<li className="last-list-btn">
								<Link to="hr-policy" onClick={changeIcon}>
									HR POLICY
								</Link>
							</li>
						</ul>
					</li>
					<li className="about" onClick={showSubMenu}>
						PROGRAM AND CERTIFICATIONS{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							<li>
								<Link
									to="master-of-management-studies"
									onClick={changeIcon}
								>
									MASTER OF MANAGEMENT STUDIES (M.M.S)
								</Link>
							</li>
							<li>
								<Link
									to="/certificate-course"
									onClick={changeIcon}
								>
									CERTIFICATE COURSES
								</Link>
							</li>
							<li className="last-list-btn" onClick={changeIcon}>
								<Link to="upcoming-events">
									UPCOMING EVENTS
								</Link>
							</li>
						</ul>
					</li>
					{/* <li className="about" onClick={showSubMenu}>
						EXAMINATION{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							<li>
								<Link
									to="/exam-time-table"
									onClick={changeIcon}
								>
									EXAM TIME TABLE
								</Link>
							</li>
							<li className="last-list-btn" onClick={changeIcon}>
								<Link to="/results">RESULTS</Link>
							</li>
						</ul>
					</li> */}
					<li className="about" onClick={showSubMenu}>
						RESEARCH AND CONSULTANCY{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							<li>
								<Link to="/researchcell" onClick={changeIcon}>
									RESEARCH CELL
								</Link>
							</li>
							<li>
								<Link to="/publications" onClick={changeIcon}>
									PUBLICATIONS
								</Link>
							</li>
							<li>
								<Link to="/research-paper" onClick={changeIcon}>
									RESEARCH PAPER
								</Link>
							</li>
							<li>
								<Link to="/consulting" onClick={changeIcon}>
									CONSULTING
								</Link>
							</li>
							<li>
								<Link to="/conference" onClick={changeIcon}>
									CONFERENCE
								</Link>
							</li>
							<li>
								<Link
									to="/research-journal"
									onClick={changeIcon}
								>
									RESEARCH JOURNAL
								</Link>
							</li>
							<li className="last-list-btn" onClick={changeIcon}>
								<Link to="/code-of-ethics">CODE OF ETHICS</Link>
							</li>
						</ul>
					</li>
					<li className="about">
						<Link to="/development-initiative" onClick={changeIcon}>
							DEVELOPMENT INITIATIVES
						</Link>
					</li>
					<li className="about" onClick={showSubMenu}>
						FACULTY{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							<li>
								<Link to="/core-faculty" onClick={changeIcon}>
									CORE FACULTY
								</Link>
							</li>
							<li className="last-list-btn" onClick={changeIcon}>
								<Link to="/visiting-and-guest">
									VISITING AND GUEST FACULTY
								</Link>
							</li>
						</ul>
					</li>
					<li className="about" onClick={showSubMenu}>
						ACHIEVEMENTS{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							<li>
								<Link
									to="/instituion-achievements"
									onClick={changeIcon}
								>
									INSTITUION ACHIEVEMENT
								</Link>
							</li>
							<li>
								<Link
									to="/best-research-paper"
									onClick={changeIcon}
								>
									BEST RESERACH PAPER
								</Link>
							</li>
							<li className="last-list-btn" onClick={changeIcon}>
								<Link to="/students-achievements">
									STUDENTS ACHIEVEMENT
								</Link>
							</li>
						</ul>
					</li>
					<li className="about" onClick={showSubMenu}>
						NAAC{" "}
						<FaChevronDown className="drop-down-arrow-nav-bar" />
						<ul className="about-list">
							{naacYear.length > 0 &&
								naacYear.map((naac, id) => (
									<li key={id}>
										<Link
											to={`/naac/${naac.naac_year}`}
											onClick={changeIcon}
										>
											{naac.naac_title}
										</Link>
									</li>
								))}
						</ul>
					</li>
					<li className="about">
						<Link to="/nirf_d" onClick={changeIcon}>
							NIRF
						</Link>
					</li>
				</ul>
			</div>
		</>
	);
};

export default TopNavbar;
