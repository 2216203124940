import React, { Fragment } from 'react'
import CoCurricularCard from '../co-curricular-card/CoCurricularCard'
import CoCurricularEvents from '../co-curricular-events/CoCurricularEvents'
import PagesHeading from '../pages-heding/PagesHeading'

const CoCurricular = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <CoCurricularEvents></CoCurricularEvents>
    </Fragment>
  )
}

export default CoCurricular