import axios from "axios";

let URL = "";

// console.log(window.location.origin);

switch (window.location.origin) {
	case "https://zerobroker.incincmedia.com":
		URL = "https://zerobroker.incincmedia.com/server/api/"; 
		break;

	case "https://gnvsiom.org":
		URL = "https://gnvsiom.org/server/api/";
		break;

	case "http://localhost:3002": 
		URL = "https://zerobroker.incincmedia.com/server/api/";
		break;

	default:
		URL = "https://gnvsiom.org/server/api/";
		break;
}

export const api = axios.create({
	baseURL: URL,
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		// const { status } = error.response;
		if (error.response.status === 401) {
		}
		return Promise.reject(error);
	}
);
