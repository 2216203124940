import React, { Fragment, useEffect, useState } from "react";
import "./prospectus.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const Prospectus = () => {
	const [prospectusData, setProspectusData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_prospectus: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setProspectusData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="prospectus-page-comtainer container">
				<div className="prospectus-page-table-container">
					{prospectusData.length > 0 ? (
						<table className="prospectus-page-table">
							<thead>
								<tr>
									<th>Sr.no</th>
									<th>Title</th>
									<th>Academic year</th>
									<th>File</th>
								</tr>
							</thead>
							<tbody>
								{prospectusData.map((data, id) => (
									<tr key={id}>
										<td>{id + 1}</td>
										<td>{data.name}</td>
										<td>{data.year}</td>
										<td className="prospectus-page-pdf">
											<div className="prospectus-page-pdf-view">
												<a
													href={data.doc}
													target="_blank"
													className="link-cta"
												>
													<FaEye />
												</a>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						"No Data Found"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default Prospectus;
