import React, { Fragment, useState } from 'react'
import './indexpageadmissionpanel.css'
import HomePageAdmissionPanelModal from '../popup-cards/HomePageAdmissionPanelModal'
const IndexPageAdmissionPanel = () => {
    const [openModal,setOpenModal] = useState(false)
    const admissionPanleOpen = () =>{
        return setOpenModal(true)
    }
    return (
        <Fragment>
            <div className='home-page-admission-panel-container'>
                <h2 className='home-page-admission-panel-heading' onClick={()=>{admissionPanleOpen()}}>Have a Query ?</h2>
            </div>
            {openModal? <HomePageAdmissionPanelModal show={openModal} onHide={()=>{setOpenModal(false)}}/>:''}
        </Fragment>
    )
}

export default IndexPageAdmissionPanel