import React, { Fragment } from 'react'
import ExtracurricularEvents from '../extracurricular-events/ExtracurricularEvents'
import PagesHeading from '../pages-heding/PagesHeading'

const ExtracurricularEventsPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <ExtracurricularEvents></ExtracurricularEvents>
    </Fragment>
  )
}

export default ExtracurricularEventsPage