import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "./dynamicresult.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const DynamicResult = (props) => {
	const [dynamicResultDetails, setDynamicResultDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	// const dynamicResultDetails = [{
	//     id: 1,
	//     type: "CBSGS",
	//     semester: "Semester I",
	//     academic_year: "2020-2021",
	//     notice: "MMS Year 1 Semester 1 (Div B) Result",
	//     file_url: ""
	// }, {
	//     id: 2,
	//     specialization: "",
	//     type: "CBSGS",
	//     semester: "Semester I",
	//     academic_year: "2020-2021",
	//     notice: "MMS Year 1 Semester 1 (Div A) Result",
	//     file_url: ""
	// }, {
	//     id: 3,
	//     specialization: "",
	//     type: "CBSGS",
	//     semester: "Semester IV",
	//     academic_year: "2019-2020",
	//     notice: "Semester IV Choice Based - December 2020",
	//     file_url: ""
	// }, {
	//     id: 4,
	//     specialization: "",
	//     type: "CBSGS",
	//     semester: "Semester IV",
	//     academic_year: "2019-2020",
	//     notice: "Semester IV CBSGS Revised - December 2020",
	//     file_url: ""
	// }, ]

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_results: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setDynamicResultDetails(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="dynamic-results-container container">
				<div className="dynamic-result-course-heading">
					<h1>{props.course_title}</h1>
				</div>
				<div className="dynamic-result-table-container">
					{dynamicResultDetails.length > 0 ? (
						<table className="dynamic-result-table">
							<thead>
								<tr>
									<th>No.</th>
									<th>Type</th>
									<th>Semester</th>
									<th>Academic Year</th>
									<th>Notices</th>
									<th>File</th>
								</tr>
							</thead>
							<tbody>
								{dynamicResultDetails.map((item, index) => {
									return (
										<tr key={index}>
											<td>{index + 1}.</td>
											<td>{item.type}</td>
											<td>Semester {item.semester}</td>
											<td>{item.academic_year}</td>
											<td>{item.notices}</td>
											<td className="dynamic-result-pdf">
												<a
													href={item.pdf_file}
													target="_blank"
													className="link-cta"
												>
													<FaEye className="dynamic-result-pdf-eye" />
												</a>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						"No Reult Found"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default DynamicResult;
