import React, { Fragment } from 'react'
import './registerforfdpmdp.css'
const RegisterForFDPMDP = () => {
    return (
        <Fragment>
            <div className='register-for-fdp-mdp-container'>
                <diiv className="register-for-fdp-mdp-heading">
                    <h3>One day - Faculty Development Program</h3>
                    <h3>NBA - Accreditation Process and Upcoming SAR (2016)</h3>
                </diiv>
                <div className='register-for-fdp-mdp-table-container'>
                    <table className='register-for-fdp-mdp-table'>
                        <tr>
                            <th>Date:</th>
                            <th>Venue:</th>
                            <th>Timing:</th>
                        </tr>
                        <tr>
                            <td>Saturday, 24th Sept. 2016</td>
                            <td>Emperor Hall, GNVS Institute of Management</td>
                            <td>8.30 am to 6.00 pm</td>
                        </tr>
                    </table>
                </div>
                <div className='register-for-fdp-mdp-workship-container'>
                    <div className='workship-objective-heading'>Workshop Objectives:</div>
                    <div className='workship-objective-list'>
                        <ul>
                            <li>To understand the features of Upcoming NBA-SAR* (2016).</li>
                            <li>To discuss the operational difficulties of the Accreditation process.</li>
                            <li>To know the role of technological support (Software) in the implementation process.</li>
                        </ul>
                    </div>
                </div>
                <div className='register-for-fdp-mdp-cards-container'>
                    <div className='register-for-fdp-mdp-cards-row'>
                        <div className='register-for-fdp-mdp-cards-column'>
                            <strong>Dr. S. C. Sahasrabudhe</strong>
                            <ul>
                                <li>Former Director â€“ IIT Bombay, DA-IICT (Gujrat)The member of NBA-Executive CommitteeKey
                                    Contributor for Upcoming SAR (2016)</li>
                            </ul>
                        </div>
                        <div className='register-for-fdp-mdp-cards-column'>
                            <strong>Mr. Suresh Mhatre</strong>
                            <ul>
                                <li>BE, ME (Florida), Fellowship â€“ IE & IMCI.Ex-Vice President of TCS Ltd.Project Director -
                                    SAP: MCGMProfessor Emeritus â€“ NMIMS UniversityPresident of BMAMember â€“ EEAC, Academic
                                    Advisory Committee - NBABoard of Governers â€“ SPIT.</li>
                            </ul>
                        </div>
                        <div className='register-for-fdp-mdp-cards-column'>
                            <strong>Prof. A. Sridharan</strong>
                            <ul>
                                <li>PhD, D.Sc,FNA, FASc,FNAE, Hon. FIGS.Hon. Scientist â€“ Indian National Science AcademyFormer
                                    Dy. Director â€“ Indian Institute of ScienceGeotechnical ConsultantMember â€“ NBA Committee</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className='register-for-fdp-mdp-hr-line'></hr>
                <div className='register-for-fdp-mdp-details-container'>
                    <strong className='register-for-fdp-mdp-strong-tags'>Participants:</strong>
                    <p>The Faculty Development Workshop is designed for Directors, Head of the Institutions, Registrars and Senior Faculty members from Management Institutes.</p>
                    <strong className='register-for-fdp-mdp-strong-tags'>Promoter:</strong>
                    <p>Dr. R.K. SinghDirector â€“ GNVS Institute of Management</p>
                    <strong className='register-for-fdp-mdp-strong-tags'>Contact Details:</strong>
                    <p>Dr. Mrinali Tikare - 9820493668</p>
                    <strong className='register-for-fdp-mdp-strong-tags'>Registration Charges:</strong>
                    <p className='register-for-fdp-mdp-remove-margin'>Rs. 3000/- Per ParticipantNote: Seminar Kit, Food & Beverages charges are inclusive. The assistance of accommodation will be provided (with extra charges).</p>
                    <p>Those who have attended earlier workshop (NBA- Accrediation Workshop â€“ Part I) conducted on 11th June 2016 will have to pay only Rs. 1000/- as registration charges.</p>
                    <strong className='register-for-fdp-mdp-strong-tags'>Registration Details:</strong>
                    <p>Participants can pay in cash or by Demand Draft (in favour of GNVS Institute of Management, Mumbai).</p>
                </div>
                <div className='register-for-fdp-mdp-account-details'>
                    <div className='account-details-row row'>
                        <strong className='account-number col-4 register-for-fdp-mdp-account'>Bank A/C Number</strong> <strong className='col-1'>:</strong> <p className='account-number-value col-7'>31386113516</p>
                        <strong className='account-number col-4 register-for-fdp-mdp-account'>Name of the Bank</strong> <strong className='col-1'>:</strong> <p className='bank-name-value col-7'>State Bank of India</p>
                        <strong className='account-number col-4 register-for-fdp-mdp-account'>Bank Branch Name & Address</strong> <strong className='col-1'>:</strong> <p className='bank-branch-value col-7'>Kelvani Mandal, Gujarati School Bldg, Rafi Ahmed Kidwai Road, Mumbai â€“ 400037.</p>
                        <strong className='account-number col-4 register-for-fdp-mdp-account'>Bank IFSC code</strong> <strong className='col-1'>:</strong> <p className='bank-ifsc-code-value col-7'>SBIN0005350</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RegisterForFDPMDP