import { Fragment, useState } from "react";
import "./topheader.css";
import { Link, Navigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
const TopHeader = () => {
	const [fbColorChange, setFbColorChange] = useState(false);
	return (
		<Fragment>
			<div className="top-header-container">
				<div className="top-header-rows">
					<div className="tabs top-headers-columns">
					<Link to="/admission">
							<button className="payment-btn">Online Payment</button>
						</Link>
						<Link to="/placements">
							<button className="placement-btn">Placement</button>
						</Link>
						<Link to="/college-committees">
							<button>Committees</button>
						</Link>
						<Link to="/contact-us">
							<button>Contact</button>
						</Link>
						<Link to="/results">
							<button>Result</button>
						</Link>
						<Link to="/admission-enquiry">
							<button className="admission-btn">Admission</button>
						</Link>
					</div>
					<div className="footer-icons social-media-icons top-headers-columns">
						<div className="social-media">
							<SocialIcon
								url="https://api.whatsapp.com/send?phone=919321478440"
								network="whatsapp"
								style={{ height: 30, width: 30 }}
								fbcolor="#008080"
								className="social-media-links-top whatsapp"
							/>
							<SocialIcon
								url="https://www.facebook.com/GNVSInstitute/"
								network="facebook"
								style={{ height: 30, width: 30 }}
								bgColor="#fff"
								className="social-media-links-top facebook"
							/>
							<SocialIcon
								url="https://www.instagram.com/gnvsiom/"
								network="instagram"
								style={{ height: 30, width: 30 }}
								fbcolor="#008080"
								className="social-media-links-top instagram"
							/>
							<SocialIcon
								url="https://www.youtube.com/channel/UCbqn1hmUZ1l0kIV0TzzHWJA"
								network="youtube"
								style={{ height: 30, width: 30 }}
								fbcolor="#008080"
								className="social-media-links-top youtube"
							/>
							<SocialIcon
								url="https://twitter.com/gnvs_im?lang=en"
								network="twitter"
								style={{ height: 30, width: 30 }}
								fbcolor="#008080"
								className="social-media-links-top twitter"
							/>
							<SocialIcon
								url="https://www.linkedin.com/school/gnvs-institute-of-management-mumbai/"
								network="linkedin"
								style={{ height: 30, width: 30 }}
								fbcolor="#008080"
								className="social-media-links-top linkedin"
							/>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default TopHeader;
