import React, { Fragment } from 'react'
import AcademicCalendar from '../academic-calendar/AcademicCalendar';
import PagesHeading from '../pages-heding/PagesHeading'

const AcademicCalendarPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <AcademicCalendar></AcademicCalendar>
    </Fragment>
  )
}

export default AcademicCalendarPage;