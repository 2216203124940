import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import EventRegistration from '../event-registration/EventRegistration'

const EventRegistrationPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <EventRegistration></EventRegistration>
    </Fragment>
  )
}

export default EventRegistrationPage