import React, { Fragment } from 'react'
import CoreValue from '../core-values/CoreValue'
import PagesHeading from '../pages-heding/PagesHeading'

const CoreValues = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title} />
           <CoreValue />
        </Fragment>
    )
}

export default CoreValues