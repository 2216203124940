import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import VisitingGuest from '../visiting-and-guest/VisitingGuest'

const VisitingGuestPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <VisitingGuest></VisitingGuest>
    </Fragment>
  )
}

export default VisitingGuestPage