import React, { Fragment } from 'react'
import './bestpracticesigltwopointo.css'
import PagesHeading from '../pages-heding/PagesHeading'
import { Link } from 'react-router-dom'
const BestPracticesIGLTwoPointO = () => {
    return (
        <Fragment>
            <PagesHeading page_title="Show Best Practices 2019-2020"></PagesHeading>
            <div className='best-practcies-igl-two-point-o-container container'>
                <div className='best-practice-details-row'>
                    <div className='best-practice-details-column'>
                        <Link to="/best-practices/bestpracticesyear/igl-2-o/bestpracticebootcamp/rular-immersion-program">Rural Immersion Program</Link>
                    </div>
                    <div className='best-practice-details-column'>
                        <Link to="/best-practices/bestpracticesyear/igl-2-o/bestpracticebootcamp/igl">LGL</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default BestPracticesIGLTwoPointO