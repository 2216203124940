import React, { Fragment } from "react";
import "./contactuspage.css";
import { MdCall } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import { useState } from "react";
import { api } from "./../../helper/api";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import {
	EmailInput,
	MobileNumberInput,
	TextAreaInput,
	TextInput,
} from "../InputFeilds";
// import {} from "react-bootstrap";
const ContactUs = () => {
	const [formData, updateFormData] = useState({
		fullname: "",
		phone: "",
		email: "",
		message: "",
		contact_form: true,
	});
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsValidated(false);

		if (
			!formData.fullname ||
			!formData.email ||
			!formData.phone ||
			!formData.message
		) {
			setIsValidated(true);
			return;
		}
		setLoading(true);
		const response = await api({
			method: "post",
			url: "inquiry",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;

		if (
			jsonData?.status_message === "Form Submitted" &&
			jsonData?.status === 200
		) {
			setLoading(false);
			toast.success("Your Inquiry Submitted Successfully");

			updateFormData({
				fullname: "",
				phone: "",
				email: "",
				message: "",
				contact_form: true,
			});
			e.target.reset();
			window.location.reload();
		} else {
			setLoading(false);
			toast.error("Something Occur! Please try again later");
		}
	};
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="contact-us-container">
				<div className="contact-us-map-container">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.6437999499576!2d72.86281801490047!3d19.035411387111274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf32f2e0a165%3A0x429ed5ccb3facb72!2sGNVS%20Institute%20Of%20Management!5e0!3m2!1sen!2sin!4v1682449329203!5m2!1sen!2sin"
						width="100%"
						height="100%"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						className="gnvs-location-g-map"
					></iframe>
				</div>
				<div className="contact-us-details-container">
					<div className="contact-us-details-row">
						<div className="contact-us-details-phone-column">
							<MdCall className="contact-us-details-phone-icon" />
							<div className="contact-us-details-phone-number">
								<strong>Phone:</strong>
								<p>(022) 2403 0054 / (022) 2403 0053</p>
								<br />
								<br />
								<strong>Mobile:</strong>
								<p>
								+91 9321478440 / +91 9326966204
								</p>
							</div>
						</div>
						<div className="contact-us-details-email-column">
							<HiMail className="contact-us-details-email-icons" />
							<div className="contact-us-details-email">
								<p className="contact-us-details-email-id">
									gnvsiom@gnvsiom.org
								</p>
							</div>
						</div>
					</div>
					<div className="contact-us-details-address-container">
						<ImLocation className="contact-us-details-address-icon" />
						<div className="contact-us-details-address-content">
							<p>
								GNVS Institute of Management, R Jaimal Singh
								Marg, GTB Nagar, Sion (E), Mumbai 400 037. INDIA
							</p>
						</div>
					</div>
					<div className="contact-us-page-form-container">
						<form
							className="contact-us-page-form"
							onSubmit={handleSubmit}
							noValidate
						>
							<TextInput
								label={"Full Name"}
								id="fullname"
								onChange={handleChange}
								placeholder="Enter Name"
								defaultValue={formData?.fullname}
								isValidated={isValidated}
								required
							/>
							<div className="row">
								<div className="col-md-6 col-12 mt-3">
									<MobileNumberInput
										label={"Mobile No."}
										id="phone"
										onChange={handleChange}
										placeholder="Enter The Number"
										maxlength={"10"}
										isValidated={isValidated}
										defaultValue={formData?.phone}
										required
									/>
								</div>
								<div className="col-md-6 col-12 mt-3">
									<EmailInput
										label={"E-mail"}
										id="email"
										onChange={handleChange}
										isValidated={isValidated}
										defaultValue={formData?.email}
										placeholder="Enter email"
										required
									/>
								</div>
							</div>
							<TextAreaInput
								label={"Your Query"}
								placeholder="Write Here"
								id="message"
								className={"mt-3"}
								onChange={handleChange}
								multiline={"4"}
								isValidated={isValidated}
								defaultValue={formData?.message}
								required
							/>

							<div className="contact-us-page-form-submit-btn-container">
								<button
									type="submit"
									className="contact-us-page-form-submit-btn"
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ContactUs;
