import React, { Fragment } from 'react'
import InstitustionAchievements from '../Instituion-achievements/InstitustionAchievements'
import PagesHeading from '../pages-heding/PagesHeading'
const InstitusionAchievementsPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <InstitustionAchievements></InstitustionAchievements>
    </Fragment>
  )
}

export default InstitusionAchievementsPage