import React, { Fragment } from "react";
import CoreFacultyDetails from "../core-faculty-details/CoreFacultyDetails";

const CoreFacultyDetailsPage = () => {
	return (
		<Fragment>
			<CoreFacultyDetails />
		</Fragment>
	);
};

export default CoreFacultyDetailsPage;
