import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import "./mouse.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const Mous = () => {
	const [data, seData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_mou: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				seData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="mous-page-container container">
				<div className="mous-page-table-container">
					{data.length > 0 ? (
						<table className="mous-page-table">
							<tbody>
								{data.map((item, id) => (
									<tr>
										<td>{id + 1}</td>
										<td>{item.content}</td>
										<td className="mous-page-pdf">
											<a href={item.pdf} target="_blank">
												<FaEye className="mous-page-pdf-view" />
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						"No Data Added"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default Mous;
