import React, { Fragment, useEffect, useState } from "react";
import "./termsandcondition.css";

import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const TermsAndCondition = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_terms: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="terms-and-conditions-container container">
				{data && (
					<div
						className="terms-and-conditions-content"
						dangerouslySetInnerHTML={{
							__html: data && data.terms,
						}}
					></div>
				)}
			</div>
		</Fragment>
	);
};

export default TermsAndCondition;
