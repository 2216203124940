import React, { Fragment, useEffect, useState } from "react";
import "./feedbackpage.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
const FeedBack = () => {
	const [toggleTabs, setToggleTabs] = useState(0);
	const toggleTabContent = (index) => {
		setToggleTabs(index);
	};
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_feedback: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	console.log(data);
	return (
		<Fragment>
			<div className="feedback-page-container">
				<div className="feedback-page-row">
					{data && data.length > 0 ? (
						<>
							<div className="feed-back-tabs-left-column">
								{data.map((item, id) => (
									<div
										className={
											toggleTabs === id
												? "active-feed-back-left-tabs"
												: "feed-back-left-tabs"
										}
										onClick={() => {
											toggleTabContent(id);
										}}
									>
										<h5>{item.type}</h5>
									</div>
								))}
							</div>
							<div className="feed-back-tables-right-column container">
								{data.map((items, id) => (
									<table
										className={
											toggleTabs === id
												? "active-feed-back-tables"
												: "feed-back-tables"
										}
									>
										<thead>
											<tr>
												<th className="feed-back-tables-heading">
													Sr.no
												</th>
												<th className="feed-back-tables-heading">
													Title
												</th>
												<th className="feed-back-tables-heading">
													Academic Year
												</th>
												<th className="feed-back-tables-heading">
													File
												</th>
											</tr>
										</thead>
										<tbody>
											{items.data.map((item, id) => (
												<tr>
													<td>{id + 1}</td>
													<td>{item.title}</td>
													<td>{item.year}</td>
													<td className="feed-back-pdf">
														<a
															href={item.pdf}
															target="_blank"
														>
															<FaEye className="placement-table-overview-pdf-view" />
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								))}
							</div>
						</>
					) : (
						"NO DATA ADDED"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default FeedBack;
