import React from 'react'
import { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import CollegeCommittess from '../college-committees/CollegeCommittess'

const CollegeCommitteesPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <CollegeCommittess></CollegeCommittess>
    </Fragment>
  )
}

export default CollegeCommitteesPage