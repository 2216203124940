import React, { Fragment, useEffect, useState } from "react";
import "./facultydevelopmentprogram.css";
import { FaEye } from "react-icons/fa";
import FacultyDevelopmentProgramCard from "../popup-cards/FacultyDevelopmentProgramCard";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const FacultyDevelopmentProgram = () => {
	const [showModal, setShowModal] = useState(false);
	const [modalHeading, setModalHeading] = useState("");
	const [getData, setGetData] = useState({
		level: "",
		participated: "",
		venue: "",
		date: "",
		academic_year: "",
		details: "",
	});
	// const mandatorycommitteesDetails = [
	// 	{
	// 		id: 1,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "GNVSIOM",
	// 		date: "23/08/2016",
	// 		academic_year: "2016-2017",
	// 		details: "National Board Of Accreditation Process",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 2,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "GNVSIOM",
	// 		date: "23/09/2016",
	// 		academic_year: "2016-2017",
	// 		details: "National Board Of Accreditation",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 3,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "LLIM Mumbai",
	// 		date: "22/02/2018",
	// 		academic_year: "2017-2018",
	// 		details: "Effective Teaching & Research Engagement",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 4,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "MITCOM Pune",
	// 		date: "14/07/2018",
	// 		academic_year: "2018-2019",
	// 		details:
	// 			"Innovative Teaching Learning Methods In Management Education And Case Based Teaching & Case Writing",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 5,
	// 		title: "Mrinali Baburao Tikare",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Zeal Education Society - Pune",
	// 		date: "30/10/2017",
	// 		academic_year: "2016-2017",
	// 		details: "NBA Quality Management - Two Days FDP",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 6,
	// 		title: "Mrinali Baburao Tikare",
	// 		level: "State",
	// 		participated_as: "Delegate",
	// 		venue: "MIT PUNE",
	// 		date: "15/07/2016",
	// 		academic_year: "2015-2016",
	// 		details: "NBA QUALITY EDUCATION - TWO DAYS FDP",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 7,
	// 		title: "Mrinali Baburao Tikare",
	// 		level: "State",
	// 		participated_as: "Delegate",
	// 		venue: "Sanjay Ghodawat Group Of Institutions - KOLHAPUR",
	// 		date: "13/04/2016",
	// 		academic_year: "2015-2016",
	// 		details: "NBA Paving A Path Towards",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 8,
	// 		title: "Mrinali Baburao Tikare",
	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "IIM INDORE",
	// 		date: "21/04/2013",
	// 		academic_year: "2013-2014",
	// 		details: "THREE MONTHS FDP AT IIM INDORE",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 9,
	// 		title: "Mrinali Baburao Tikare",
	// 		level: "State",
	// 		participated_as: "Delegate",
	// 		venue: "SIMSREE",
	// 		date: "08/07/2012",
	// 		academic_year: "2012-2013",
	// 		details: "ORGANISED BY AIMS - NEW AGE TEACHER",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 10,
	// 		title: "Vivek Pandurang Nerurkar",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "Lala Lajpatrai Institute Of Management Seminar Hall",
	// 		date: "12/03/2016",
	// 		academic_year: "2016-2017",
	// 		details:
	// 			'FDP Organised By Lala Lajpatrai Institute Of Management, Mumbai On "Recapturing Classrooms Through Innovative Pedagogy".',
	// 		url: "",
	// 	},

	// 	{
	// 		id: 11,
	// 		title: "Vivek Pandurang Nerurkar",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "Lala Lajpatrai Institute Of Management Seminar Hall",
	// 		date: "25/11/2013",
	// 		academic_year: "2013-2014",
	// 		details:
	// 			'FDP Organised By Lala Lajpatrai Institute Of Management And Sponsored By Western Regional Centre Of ICSSR On "Research Methodology".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 12,
	// 		title: "Vivek Pandurang Nerurkar",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "Lala Lajpatrai Institute Of Management Seminar Hall",
	// 		date: "04/08/2012",
	// 		academic_year: "2012-2013",
	// 		details:
	// 			'FDP Organised By Lala Lajpatrai Institute Of Management On "Advanced Approaches And Practices In Management Vducation".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 13,
	// 		title: "Vivek Pandurang Nerurkar",
	// 		level: "State",
	// 		participated_as: "Delegate",
	// 		venue: "Saraswati Colleg Of Engineering, Kharghar, Navi Mumbai",
	// 		date: "11/01/2010",
	// 		academic_year: "2010-20113",
	// 		details:
	// 			'ISTE Approved Five Day Short Term Training Programme On "Emerging Trends In Manufacturing Engineering".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 14,
	// 		title: "Vivek Pandurang Nerurkar",
	// 		level: "State",
	// 		participated_as: "Delegate",
	// 		venue: "Shivajirao S. Jondhale College Of Engineering, Dombivli",
	// 		date: "19/07/2011",
	// 		academic_year: "2011-2012",
	// 		details:
	// 			'ISTE Approved Short Term Training Programme On "Research Methodology, Mathematical Modelling And Research Based Industry Institute Interaction Activities In Engineering & Technlogy".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 15,
	// 		title: "Rashpal Kaur Saini",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Lala Lajpatrai Institute Of Management",
	// 		date: "22/02/2018",
	// 		academic_year: "2017-2018",
	// 		details: "FDP On Effective Teaching & Research Engagement",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 16,
	// 		title: "Rashpal Kaur Saini",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "GNVS Institute Of Management",
	// 		date: "23/08/2016",
	// 		academic_year: "2016-2017",
	// 		details:
	// 			"Training Program On National Board Of Accreditation-Process.",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 17,
	// 		title: "Ankit Kishor Oza",
	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "LLIM, Mumbai",
	// 		date: "24/03/2018",
	// 		academic_year: "2017-2018",
	// 		details: '"Motivating The Motivators".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 18,
	// 		title: "Barnana Sarkar",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Indian Institute Of Management, Indore",
	// 		date: "24/04/2017",
	// 		academic_year: "2017-2018",
	// 		details:
	// 			"24th April- 30thMay, 2017 Faculty Development Programme At IIM (Indore)",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 19,
	// 		title: "Dr. R K Saily",
	// 		level: "State",
	// 		participated_as: "Others",
	// 		venue: "GNVSIOM",
	// 		date: "24/09/2016",
	// 		academic_year: "2015-2016",
	// 		details: "PARTICIPATED IN - NATIONAL BOARD OF ACCREDITATION (NBA)",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 20,
	// 		title: "Yashoda Krishna Durge",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Indian Institute Of Management (IIMA)",
	// 		date: "13/05/2018",
	// 		academic_year: "2018-2019",
	// 		details:
	// 			"Faculty Development In Management With Specialization In Marketing. Indian Institute Of Management (IIMA)",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 21,
	// 		title: "Yashoda Krishna Durge",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Institute Of Rural Management, Jaipur",
	// 		date: "06/11/2017",
	// 		academic_year: "2017-2018",
	// 		details:
	// 			"Advanced Research Methods And Innovative Teaching Techniques, 6th-18th November, 2017",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 22,
	// 		title: "Yashoda Krishna Durge",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "SCMS School Of Technology And Management, Kochi, Kerala",
	// 		date: "20/11/2017",
	// 		academic_year: "2017-2018",
	// 		details:
	// 			"Data Science And Decision Making Processes, 20th-03rd December, 2017",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 23,
	// 		title: "Barnana Sarkar",
	// 		level: "National",
	// 		participated_as: "Presenter",
	// 		venue: "Faculty Development Programme At SIMSREE, Mumbai",
	// 		date: "24/03/2012",
	// 		academic_year: "2011-2012",
	// 		details: "Research And Case Study Methodology Of Teaching",
	// 		url: "",
	// 	},

	// 	{
	// 		id: 24,
	// 		title: "Barnana Sarkar",
	// 		level: "National",
	// 		participated_as: "Others",
	// 		venue: "IIM (Indore)",
	// 		date: "24/04/2017",
	// 		academic_year: "2016-2017",
	// 		details:
	// 			"Faculty Development Programme At IIM (Indore) On Research Methodology",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 25,
	// 		title: "Barnana Sarkar",
	// 		level: "National",
	// 		participated_as: "Resource Person",
	// 		venue: "Lala Lajpat Rai Institute Of Management",
	// 		date: "22/02/2018",
	// 		academic_year: "2017-2018",
	// 		details:
	// 			"FDP Conducted As Resource Person For Innovative Teaching Pedagogy",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 26,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "GNVSIOM",
	// 		date: "31/07/2013",
	// 		academic_year: "2013-2014",
	// 		details:
	// 			"3 Days Faculty Development Program Was Organized By The Institute On International Business Simulations In Association With IBizSim From 31.7.2013 To 2.8.2013 To Train The Faculty Members On Business Simulation And Get Hands-on Training.",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 27,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "GNVSIOM",
	// 		date: "06/05/2019",
	// 		academic_year: "2018-2019",
	// 		details:
	// 			'Organized One Day Faculty Development Program On "Mentoring Of Students At B-School" . Resource Person: Dr. Geeta Shetty, Associate Professor, St Xavier\'s Institute Of Education',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 28,
	// 		title: "Deepa Manoj Nair",
	// 		level: "State",
	// 		participated_as: "Others",
	// 		venue: "Vivekanand Education Society'sInstitute Of Technology, Mumbai",
	// 		date: "02/07/2018",
	// 		academic_year: "2017-2018",
	// 		details:
	// 			'6 Days FDP On " Deep Learning And Artificial Intelligence" From July 2 -7 ,2018 Organized By Vivekanand Education Society\'sInstitute Of Technology, Mumbai In Association With Revert Technology Pvt. Ltd., E-Summit, IIT Roorkee',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 29,
	// 		title: "Deepa Manoj Nair",
	// 		level: "Local",
	// 		participated_as: "Others",
	// 		venue: "GNVSIOM, Sion(E), Mumbai",
	// 		date: "06/05/2019",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'One Day FDP On " Mentoring Of Students At B-School" Organized By The Institute On May 6,2019 At GNVSIOM.',
	// 		url: "",
	// 	},

	// 	{
	// 		id: 30,
	// 		title: "Ritesh Kumar",
	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "NMIMS University, School Of Business Management",
	// 		date: "05/12/2016",
	// 		academic_year: "2015-2016",
	// 		details:
	// 			"FDP Was Organised At SBM From 5th To 10th December. Dr. Sarosh Kuruvilla From Cornell University Conducted The FDP. It Provided Deeper Understanding About High Quality Research, Research Design Issues And Writing And Publications. Understanding About Different Paradigms And Multidisciplinary Approach To Research Were Some Of The Key Aspects Of The Program.",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 31,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "Zoom",
	// 		date: "02/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"International FDP On How To Write Effective Case Study ? Organized By Atmiya University",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 32,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "10/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"Faculty Awareness Program On NAAC Accreditation Organized By Shri Chhatrapati Shivajiraje College Of Engineering, Pune",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 33,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "08/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"Participated At FDP On Google Meet 101 Organized By VET Institute Of Arts & Science, Erode",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 34,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "17/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"National Level Faculty Development Program On â€œWhere Technology Meets Business: Industry 4.Oâ€ Organized By Nehru Institute Of Management Studies (NIMS) On 17th May, 2020",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 35,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "10/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'4 Days Online FDP On "Research, Funding Projects & IPR Organized By IQAR, R & D Cell Under The Banner Of IETE & IIC By K.C. College Of Engineering And Management Studies',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 36,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "21/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"3 Days Online Course On Writing Case Studies, Project Preparation And Funding Of Research Projects From 21.5.2020 To 23.5.2020 Organized By IARA In Association With University Of Madras, Dept Of Commerce. Completed Course With A Grade",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 37,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "31/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"Completed Online Course On Artificial Intelligence - An Innovative Paradigm For Smart Computing Conducted By BECH-RUSA 2.0, Bharathidasan University , TN",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 38,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "28/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"National Level FDP On Social Entrepreneurship Organized By K.P.B. Hinduja College Of Commerce",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 39,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "08/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'FDP On "Future Of Examinations Bloom\'s Taxonomy & Multiple Assessment Tools"conducted By G.T.N. Arts College, TN',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 40,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "02/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'7 Days FDP On "Pedagogical Methods Suitable For A Pandemic Situation: A Webinar Series"organized By NGM College & Erode College, TN',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 41,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "09/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			"FDP On Pedagogy Organized By Mary Matha College, Tamil Nadu",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 42,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "07/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'One Week FDP On "Transforming Education - The Google Classroom Way" Organized By Aachi Institute Of Management & Entrepreneurial Development (AIMED), Chennai',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 43,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "22/05/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'FDP On "Writing Quality Research Paper And Proposal"organized By International School Of Management, Patna.',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 44,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Dr. V. N. Bedekar Of Institute Of Management Studies, Thane",
	// 		date: "18/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			"5 DAYS ONLINE RESEARCH FDP Between May 18 - 22, 2020 On â€œHOW TO PUBLISH RESEARCH PAPER IN SCOPUS INDEXED JOURNALâ€",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 45,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "IMS Noida, UP In Association With Spoken Tutorial Project, IIT Bombay",
	// 		date: "25/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			"National Level One Week Virtual Faculty Development Program On R Programming During This Lockdown Period Organized By IMS Noida, UP In Association With Spoken Tutorial Project, IIT Bombay Between May 25 - 29, 2020.",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 46,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "K. C. COLLEGE OF ENGINEERING & MANAGEMENT STUDIES AND RESEARCH, THANE (EAST)",
	// 		date: "07/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Online FDP On "RESEARCH, FUNDING & IPR" Jointly Organized By Department Of Electronics & Telecommunication, IQAC AND R & D Cell, K. C. COLLEGE OF ENGINEERING & MANAGEMENT STUDIES AND RESEARCH, THANE (EAST) In Association Withunder The Banner Of IETE And Institution\'s Innovation Cell (IIC) Between May 7 -10, 2020',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 47,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "FR. C. Rodrigues Institute Of Technology, Navi Mumbai",
	// 		date: "18/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'One Week Online FDP On " R Programming" Organized By The Dept. Of IT At FR. C. Rodrigues Institute Of Technology, Vashi In Association With Spoken Tutorial IITB, Mumbai Between May 18-23, 2020.',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 48,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "K.C. College Of Engineering & Management Studies & Research, Thane, Mumbai",
	// 		date: "07/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'4 Days FDP On "Research Funding Projects & IPR" Jointly Organized By Dept. Of Electronics & Telecommunication Under The Banner Of IETE And Institution\'s Innovation Council (IRC) Of K.C. College Of Engineering & Management Studies & Research, Thane, Mumbai Between May 7 - 10,2020.',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 49,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "IQAC Of Bhavans College, Andheri(W). Mumbai",
	// 		date: "05/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			"Three Day National Level Faculty Development Programme (Workshop) Onâ€œSkill Development For Online Teachingâ€ Organized By The IQAC Of Bhavans College, Andheri(W). Mumbai.held From 5th May To 7th May 2020.",
	// 		url: "",
	// 	},
	// 	{
	// 		id: 50,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Sridevi Women's Engineering College,Hyderabad In Collaboration With National Youth Council Of India And Brainovision Solutions India Pvt. Ltd.",
	// 		date: "22/05/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'5 Day National Level OnlineFDP On "Artificial Intelligence" Organised By Dept. Of Computer Science And Engineering, Sridevi Women\'s Engineering College, Hyderabad In Collaboration With National Youth Council Of India And Brainovision Solutions India Pvt. Ltd. Held Between May 22-26,2020',
	// 		url: "",
	// 	},

	// 	{
	// 		id: 51,
	// 		title: "Deepa Manoj Nair",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Anurag University,Hyderabad, Telangana",
	// 		date: "03/06/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'	Three Day Online Faculty Development Program On â€œCyber Security â€ From 3rd June - 5th June 2020 Hosted By Departments Of Electronics And Communication Engineering, Anurag University, Hyderabad, Telangana,in Association With ISTE And INDIAN SERVERS".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 52,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "14/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'7 -Day Faculty Development Programme On "Institutional Bench-marking Of Best Practices" Hosted By Mallikarjun & Chetan Desai College, Canacona, Goa.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 53,
	// 		title: "Ketan Shantilal Vira",
	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "	30/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'One Week FDP On "Teach The Teacher" Organized By The Headway Academy From 24.06.2020 To 30.6.2020".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 54,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "30/06/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'	One Week International Faculty Development Program On " Teacher\'s Leadership Quality In Higher Education" From 24.6.2020 To 30.6.2020".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 55,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "09/07/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'2 Day Faculty Development Program On Effective And Efficient Online Teaching And Content Development Organized IICMR And IIDE.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 56,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "	08/07/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'5 Day Executive Development Programme On Basic Analytics Tools Organized By Pondicherry University, School Of Management, P U D U C H E R R Y".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 57,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "21/07/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'2 Day FDP On " Hands-on Training To Write Quality Research Paper Using Latex" Organized By Magestic Technology Solutions Pvt. Ltd. (MTSPL)".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 58,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "18/07/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'6 Day Virtual FDP On "Robotics And Automation"organized By Vellore Institute Of Technology, Chennai.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 59,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "14/07/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'The National Level Faculty Development Program On "Effective Marketing Strategies Including Digital Amidst Pandemic" Organized By Maratha Mandir’s BABASAHEB GAWDE INSTITUTE OF MANAGEMENT STUDIES In Collaboration With INDIAN ACCOUNTING ASSOCIATION- THANE".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 60,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "Online",
	// 		date: "14/08/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'One Day FDP On " Advanced Training On Google Classroom" Organized By GNVSIOM".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 61,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "	12/12/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'AICTE Sponsored STTP On "Application Of R In Management Research" Organized By GRG School Of Management, Coimbatore, Tamil Nadu".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 62,
	// 		title: "Deepa Manoj Nair",

	// 		level: "National",
	// 		participated_as: "Others",
	// 		venue: "BBDNIIT , Lucknow",
	// 		date: "26/12/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'FDP On "DATA ANALYSIS USING MS EXCEL" From 25th December To 27th December,2020.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 63,
	// 		title: "Deepa Manoj Nair",

	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "GNVSIOM",
	// 		date: "29/01/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'FDP On "Effective Learning Using MOOC Platform - MOODLE" Held On Jan 29,2020 Organized By IT Forum In Association With BVIMIT, CBD Belapur.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 64,
	// 		title: "Deepa Manoj Nair",

	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "GNVSIOM",
	// 		date: "14/08/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Faculty Development Workshop Held On August 14,2020 Attended By The Core And Corporate Visiting Faculty Of GNVSIOM.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 65,
	// 		title: "Deepa Manoj Nair",

	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "GNVSIOM",
	// 		date: "18/08/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'One Day Online Faculty Development Workshop On "Advanced Training On Google Classroom" Held On August 14,2020 Organized By Systems Dept. And IQAC In Association With MyClassroom @ GNVSIOM, Mumbai.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 66,
	// 		title: "Deepa Manoj Nair",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "HAL",
	// 		date: "14/06/2020",
	// 		academic_year: "2010-2011",
	// 		details:
	// 			'A Three Day Faculty Development Program Was Held On Gas Turbine Design And Development (14th - 16th June 2020) This Three-day Event Provided A Forum To Know The Design Aspects Of Gas Turbine By Eminent Personalities From Hindustan Aeronautics Limited (HAL) Mr. K. V. L. Narayana Rao, Sr. Manager HAL - 14th June 2020 . Mr. Prateek Mehrotra, Sr. Manager HAL - 15th June 2020 . Mr. Dani Davis, Manager HAL - 16th June 2020 .".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 67,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "29/05/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'6 Days Virtual National Level FDP On NEP 2020 And Higher Education In India Organized By Dr. N.S.A.M First Grade College (NITTE) , Bengaluru.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 68,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "07/06/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'FDP On Research Funding And IPR Organized By K.C.College Of Engineering And Management.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 69,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "International",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "23/06/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Three-day Faculty Development Program On Citation Styles And Reference Lists Conducted By Siva Sivani Institute Of Management, Telangana".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 70,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "Local",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "03/07/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'One Day FDP On Google Updates & Classroom Tools For Remote Creative Teaching Organized By GNVS Institute Of Management".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 71,
	// 		title: "Deepa Manoj Nair",

	// 		level: "Local",
	// 		participated_as: "Convener",
	// 		venue: "GNVSIOM",
	// 		date: "03/07/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Organized An Online Faculty Development Workshop On " Google Updates & Classroom Tools For Remote Creative Teaching" Organized By IQAC And Systems Department In Association With MyClassroom, Mumbai.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 72,
	// 		title: "Usha Ramesh Kumar",

	// 		level: "Local",
	// 		participated_as: "Others",
	// 		venue: "Virtual",
	// 		date: "	14/08/2020",
	// 		academic_year: "2020-2021",
	// 		details: '“Advanced Training On Google Classroom”.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 73,
	// 		title: "Usha Ramesh Kumar",

	// 		level: "Local",
	// 		participated_as: "Others",
	// 		venue: "Virtual",
	// 		date: "30/06/2021",
	// 		academic_year: "2020-2021",
	// 		details: '\'Inspiring To Perform" A Program On Mentoring.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 74,
	// 		title: "Nisha Siddhesh Tatkar",

	// 		level: "National",
	// 		participated_as: "Resource Person",
	// 		venue: "Online",
	// 		date: "02/04/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Invited As An Resource Person In UGC NET Paper -II FDP And Guided Faculties For Following Topic. 3: Business Economics • Meaning And Scope Of Business Economics • Objectives Of Business Firms • Demand Analysis: Law Of Demand; Elasticity Of Demand And Its Measurement; Relationship Between AR And MR • Consumer Behavior: Utility Analysis; Indifference Curve Analysis • Law Of Variable Proportions: Law Of Returns To Scale • Theory Of Cost: Short-run And Long-run Cost Curves • Price Determination Under Different Market Forms: Perfect Competition; Monopolistic Competition; Oligopoly- Price Leadership Model; Monopoly; Price Discrimination • Pricing Strategies: Price Skimming; Price Penetration; Peak Load Pricing".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 75,
	// 		title: "Nisha Siddhesh Tatkar",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "	07/05/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Participated In “One Week Online National Faculty Development Program On E-Contents And ICT Tools For Innovative Teaching & Learning" Organized By RCP, Roorkee From 1 May To 7 May, 2021.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 76,
	// 		title: "Ketan Shantilal Vira",

	// 		level: "National",
	// 		participated_as: "Delegate",
	// 		venue: "Online",
	// 		date: "	14/07/2021",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'Participated In All India Faculty Development Programme On Emerging Trends In Online Education Organized By Trinity College For Women, Tamil Nadu & Edugroom India Foundation, Kerala In Collaboration With UGC -HRDC, Baghat Phool Singh Mahila Viswavidhyalaya University, Haryana From 1.7.2021 To 14.7.2021".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 77,
	// 		title: "Vivek Pandurang Nerurkar",

	// 		level: "National",
	// 		participated_as: "Others",
	// 		venue: "Swayam - NPTEL Online Platform (Funded By Ministry Of HRD, Govt.of India)",
	// 		date: "	30/04/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'	It Was 12 Weeks FDP On "Modelling And Analytics For Supply Chain Management" At Resource Faculty Of Management, IIT, Kharagpur. Participated As Learning Faculty. It Is Equivalent To One And Half FDP.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 78,
	// 		title: "Vivek Pandurang Nerurkar",

	// 		level: "N/a",
	// 		participated_as: "Others",
	// 		venue: "Swayam - NPTEL Online Platform (Funded By Ministry Of HRD, Govt.of India)",
	// 		date: "	31/12/2020",
	// 		academic_year: "2020-2021",
	// 		details:
	// 			'FDP Titled "Project Management For Managers" Was Organised By Faculty Of Management IIT Roorkee. This 12 Week FDP Is One And Half FDP.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 79,
	// 		title: "Vivek Pandurang Nerurkar",

	// 		level: "Local",
	// 		participated_as: "Chairperson",
	// 		venue: "GNVS Institute Of Management",
	// 		date: "29/01/2020",
	// 		academic_year: "2019-2020",
	// 		details:
	// 			'One Day FDP On "Effective Learning Using MOOC Platform - Moodle" By GNVSIOM Jointly With MCA Dept. Bharati Vidyapeeth.".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 80,
	// 		title: "Vivek Pandurang Nerurkar",

	// 		level: "Local",
	// 		participated_as: "Others",
	// 		venue: "GNVS Institute Of Management",
	// 		date: "	06/05/2019",
	// 		academic_year: "2018-2019",
	// 		details: 'One Day FDP On " Mentoring Of Students At B School"".',
	// 		url: "",
	// 	},
	// 	{
	// 		id: 81,
	// 		title: "Usha Ramesh Kumar",

	// 		level: "National",
	// 		participated_as: "Convener",
	// 		venue: "Online",
	// 		date: "	30/06/2021",
	// 		academic_year: "2021-2022",
	// 		details: "	FDP On Mentoring Students- 'Inspiring To Perform'\".",
	// 		url: "",
	// 	},
	// ];

	const [mandatorycommitteesDetails, setMandatorycommitteesDetails] =
		useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_fdp: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setMandatorycommitteesDetails(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	const getStudenDetails = (
		level,
		participated_as,
		venue,
		date,
		academic_year,
		details,
		title
	) => {
		let updatefacultyprogram = {};
		updatefacultyprogram = {
			level: level,
			participated: participated_as,
			venue: venue,
			date: date,
			academic_year: academic_year,
			details: details,
		};
		setModalHeading(title);
		setGetData(updatefacultyprogram);
		return setShowModal(!false);
	};
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="faculty-development-program-container container">
				<div className="faculty-development-program-test">
					<p>
						The Institute offers management research, training and
						consultancy services through its faculty and has
						developed corporate tie ups for various programs and
						events conducted at the institute. The Institute has a
						number of corporate specific collaborative programs.
						Some of this are
					</p>
				</div>
				{mandatorycommitteesDetails.length > 0 ? (
					<div className="mandatory-committees-row">
						{mandatorycommitteesDetails.map((item, index) => {
							return (
								<div
									className="mandatory-committees-columns"
									key={index}
								>
									<div className="mandatory-committees-title">
										{item.name}
									</div>
									<div
										className="mandatory-committees-pdf"
										onClick={() => {
											getStudenDetails(
												item.level,
												item.participate,
												item.venue,
												item.date,
												item.academic_year,
												item.content,
												item.name
											);
										}}
									>
										<FaEye className="mandatory-committees-pdf-view" />
									</div>
								</div>
							);
						})}
					</div>
				) : (
					"No Data Added"
				)}
				{showModal ? (
					<FacultyDevelopmentProgramCard
						show={showModal}
						programdata={getData}
						modalHeading={modalHeading}
						onHide={() => setShowModal(false)}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};

export default FacultyDevelopmentProgram;
