import React from 'react'
import './researchjournal.css';
const ResearchJournal = () => {
    return (
        <div className='research-journal-container container'>
            <div className='research-journal-heading'>
                <h3>VIDYAK JOURNAL OF RESEARCH</h3>
            </div>
            <div className='research-journal-content'>
                <p>‘‘VIDYAK JOURNAL OF RESEARCH” is a peer-reviewed annual journal of GNVS Institute of Management having
                    ISSN NO: 2455-722. The aim of this journal is to provide a platform for researchers, academicians and professionals from diverse
                    domains of management to share innovative research achievements and practical experiences to stimulate scholarly debate in the
                    development of management science and decision making. This Journal is dedicated to publish high quality research papers providing
                    meaningful insights into the substantial management content, both in Indian as well as the global context. Original research
                    papers, conceptual articles, case studies and book reviews will be considered.</p>
            </div>
            <div className='research-journal-guideline-heading'>
                <h4>Guideline</h4>
            </div>
            <div className='research-journal-guideline-note'>
                <p>Vidyak Journal of Research invites original, unpublished empirical and theoretical papers, case studies, major research project reports and scholarly articles consistent with the scope of the journal.</p>
            </div>
            <div className='research-journal-points'>
                <ul>
                    <li>A declaration: Authors must declare originality of the work. The contribution must be original, neither published nor under consideration for publication anywhere else.</li>
                    <li>Covering Letter: Should contain the title of the paper, author's name, designation, official address, contact number and email address.</li>
                    <li>Abstract: Not more than 150-300 words, along with 3 key words.</li>
                    <li>Format: Articles/papers (5000 words normally, up to 8000 words in exceptional cases), Times New Roman font and font size for heading – 14 point (should be Bold), for remaining text 12 point font size and justified alignment.</li>
                    <li>The author(s) must submit soft copy of the complete article/paper through email.</li>
                    <li>References should be given in APA format and at the end of the manuscript. It should contain only those cited in the text of the manuscript.</li>
                    <li>Tables, charts, graphs should be numbered, cited and referenced properly.</li>
                    <li>The author / authors should obtain copyright clearance for the information / data used in the manuscript and openly recognize the source of reference and citation.</li>
                    <li>Authors who have used a questionnaire for data collection should attach the same along with the manuscript.</li>
                </ul>
            </div>
        </div>
    )
}

export default ResearchJournal