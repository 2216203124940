import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import StudentsAchievements from '../students-achievements/StudentsAchievements'

const StudentsAchievementPages = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <StudentsAchievements></StudentsAchievements>
    </Fragment>
  )
}

export default StudentsAchievementPages