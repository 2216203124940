import React, { Fragment } from 'react'
import {GrFormNext} from 'react-icons/gr';
const NextSlider = (props) => {
    const { className, style, onClick } = props;
    return (
        <Fragment>
            <GrFormNext className={className + " slick-next-btn"}
            style={{ }}
            onClick={onClick} />
            
        </Fragment>
    )
}

export default NextSlider