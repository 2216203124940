import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import './developmentinitiativepages.css'
import { FaEye } from 'react-icons/fa'
const SoftSkillDevelopment = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <div className='bridge-course-container container'>
                <div className='bride-course-heading'>
                    <h4>E Learn India</h4>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>About</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>GNVS-IOM caters to students from vernacular medium. When they take admission in MMS course they need to 
                        understand management education through the medium of English language. This Communication Skill training 
                        course is made for such students who have the challenge of understanding the medium of teaching.</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Associate Partner Description</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>ELearn India LLP started in year 2005, as eLearn India, a proprietary company, and in the past 11 years of 
                        operations has have trained people from various organization in Corporate training programs as well as Open
                         Training. Their portfolio of offerings includes: â— Training and Intervention Design â— Consulting â— 
                         Education</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Objective</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>To offer courses in some of the top B-Schools in the country, offering a flavor of Industry standards and 
                        International Framework to the MBA Graduates</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Outcomes Description</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>To help improve Industry-readiness of MMS students by boosting their confidence by training them in communication skills.</p>
                </div>
                <div className='bridge-course-about-heading'>
                    <p>Duration</p>
                </div>
                <div className='bridge-course-about-content'>
                    <p>As per requirement</p>
                </div>
                <div className='associate-file-container'>
                    <div className='associate-file-heading'><p>Associate Partner File</p></div>
                    <button className='associate-file-pdf-button'><FaEye className='associate-file-pdf-view' /></button>
                </div>
            </div>
    </Fragment>
  )
}

export default SoftSkillDevelopment