import React, { Fragment } from "react";
import PagesHeading from "../pages-heding/PagesHeading";
import BestResearchPaperDetails from "../academicyear-details/BestResearchPaperDetails";

const BestResarchPaperDetailsPage = (props) => {
	return (
		<Fragment>
			<PagesHeading page_title={props.page_title}></PagesHeading>
			<BestResearchPaperDetails></BestResearchPaperDetails>
		</Fragment>
	);
};

export default BestResarchPaperDetailsPage;
