import React, { Fragment } from 'react'
import { ExamTimeTable } from '../exam-time-table/ExamTimeTable'
import PagesHeading from '../pages-heding/PagesHeading'

const ExamTimeTablePage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <ExamTimeTable></ExamTimeTable>
    </Fragment>
  )
}

export default ExamTimeTablePage