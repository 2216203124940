import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "./collegecommittees.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CollegeCommittess = () => {
	// const CollegeCommittess = [{
	//     id:1,
	//     ethics_heading:"Anti Ragging Committee",
	//     url:"Committees/Anti Ragging Committee.pdf"
	// },{
	//     id:2,
	//     ethics_heading:"Anti Ragging Squad",
	//     url:"Committees/Anti Ragging Squad.pdf"
	// },{
	//     id:3,
	//     ethics_heading:"Anti Sexual Harassement Cell",
	//     url:"Committees/Anti Sexual Harassement Cell.pdf"
	// },
	// {
	//     id:4,
	//     ethics_heading:"Attendance Committee",
	//     url:"Committees/Attendance Committee.pdf"
	// },
	// {
	//     id:5,
	//     ethics_heading:"Canteen Committe",
	//     url:"Committees/Canteen Committe.pdf"
	// },
	// {
	//     id:6,
	//     ethics_heading:"College Development Commitee & Governing Body",
	//     url:"Committees/College Development Commitee & Governing Body.pdf"
	// },
	// {
	//     id:7,
	//     ethics_heading:"Committee for Admission and Prevention of Atrocities",
	//     url:"Committees/Committee for Admission and Prevention of Atrocities.pdf"
	// },
	// {
	//     id:8,
	//     ethics_heading:"Examination - Unfair Means Committee",
	//     url:"Committees/Examination - Unfair Means Committee.pdf"
	// },
	// {
	//     id:9,
	//     ethics_heading:"Examination Committee",
	//     url:"Committees/Examination Committee.pdf"
	// },
	// {
	//     id:10,
	//     ethics_heading:"Internal Complaint Committee",
	//     url:"Committees/Internal Complaint Committee.pdf"
	// },
	// {
	//     id:11,
	//     ethics_heading:"Internal Quality Assuranc Cell (IQAC)",
	//     url:"Committees/Internal Quality Assuranc Cell (IQAC).pdf"
	// },
	// {
	//     id:12,
	//     ethics_heading:"Library Comittee",
	//     url:"Committees/Library Comittee.pdf"
	// },
	// {
	//     id:13,
	//     ethics_heading:"Mahila Takrar Nivaran Samiti",
	//     url:"Committees/Mahila Takrar Nivaran Samiti.pdf"
	// },
	// {
	//     id:14,
	//     ethics_heading:"Monitoring Committe - Code of Conduct for Staff & Students",
	//     url:"Committees/Monitoring Committe - Code of Conduct for Staff & Students.pdf"
	// },
	// {
	//     id:15,
	//     ethics_heading:"NAAC Criterian Owner Team",
	//     url:"Committees/NAAC Criterian Owner Team.pdf"
	// },
	// {
	//     id:16,
	//     ethics_heading:"Right to Information Committee",
	//     url:"Committees/Right to Information Committee.pdf"
	// },
	// {
	//     id:17,
	//     ethics_heading:"Staff Grievance Redressal Committee",
	//     url:"Committees/Staff Grievance Redressal Committee.pdf"
	// },
	// {
	//     id:18,
	//     ethics_heading:"Student Grievance Redressal Committee",
	//     url:"Committees/Student Grievance Redressal Committee.pdf"
	// }
	// ]
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_college_committess: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="code-of-ethics-container container">
				{/* <div className='code-of-ethics-content'>
                <p>A code of ethics document may outline the mission and values of the business or organization, how professionals are supposed to approach problems, the ethical principles based on the organization's core values and the standards to which the professional is held.</p>
            </div> */}
				<div className="code-of-ethics-table-container">
					{data && data.length > 0 ? (
						<table className="code-of-ethics-table">
							<tbody>
								{data.map((item, index) => {
									return (
										<tr key={index}>
											<td>{index + 1}.</td>
											<td>{item.title}</td>
											<td className="code-of-ethics-pdf">
												<a href={item.pdf}>
													<FaEye className="code-of-ethics-pdf-view" />
												</a>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						"No Data Added"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CollegeCommittess;
