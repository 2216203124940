import React, { Fragment } from 'react'
import MasterManagementStudies from '../master-management-studies/MasterManagementStudies'
import PagesHeading from '../pages-heding/PagesHeading'
const MasterManagementStudyPage = (props) => {
  return (
   <Fragment>
    <PagesHeading page_title={props.page_title}/>
    <MasterManagementStudies></MasterManagementStudies>
   </Fragment>
  )
}

export default MasterManagementStudyPage