import React, { Fragment } from 'react'
import { PagesQuotesDown } from '../pages-quotes-down/PagesQuotesDown';
import './aboutus.css';
const AboutUs = () => {
    return (
        <Fragment>
            <div className='about-us-container'>
                <div className='about-us-message-container'>
                    <p>GNVS IOM takes prides itself in being a Management Institute that is student centric. Our students are our primary stakeholders and the pedagogy encompasses a new-age experiential learning mechanism that is up with current industry realities. Right since its inception in 2010, the institute has strived to be a centre of excellence. Affiliated to the University Of Mumbai, AICTE approved and it is a NAAC Accredited Management Institute.</p>
                    <p>The Institute offers the MMS program which is of two years. Human Resource Management, Marketing, Finance, Operations and Systems - IT are the specializations.</p>
                </div>
                <div className='unique-accolades-container'>
                    <div className='unique-features-contaniner'>
                        <div className='unique-features-heading'>
                            <h2>Unique Features: </h2>
                        </div>
                        <div className='unique-features-content'>
                            <ul>
                                <li>Quality placement & strong Industry connect</li>
                                <li>Top Notch Faculties with Ph. D & Industry credentials</li>
                                <li>Efficacious Mentoring & Career Development</li>
                                <li>Innovative Teaching & Learning Pedagogies</li>
                                <li>Add On Certificate Courses</li>
                                <li>Student Centric & Student Driven Ideology</li>
                            </ul>
                        </div>
                    </div>
                    <div className='accolades-container'>
                        <div className='accolades-content'>
                            <ul>
                                <li>1st AIMA Bizlab Accredited B-School in Mumbai</li>
                                <li>6th Best B-School in Mumbai Fortune India ndia 2022</li>
                                <li>10th Best B-School in Mumbai Times B-school Survey 2022</li>
                                <li>29th Best B-School in Western Region Fortune India 2022</li>
                                <li>62nd Best B-School in India Times B-school Survey 2022</li>
                                <li>76th Best B-School in India - Fortune India 2022</li>
                                <li>Ranked Among Best B-School in India - Business Today 2022</li>
                                <li>Mumbai University MMS Toppers for 2 consecutive years</li>
                            </ul>
                        </div>
                        <div className='accolades-heading'>
                            <h2>Accolades and Placement Record: </h2>
                        </div>
                    </div>
                </div>
                <PagesQuotesDown page_quote={"Over the last decade, GNVS Institute of Management has been always striving to excel in our quest to be a center of excellence for making students industry ready."}></PagesQuotesDown>
            </div>
        </Fragment>
    )
}

export default AboutUs