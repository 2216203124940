import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import VissionAndMissions from '../vision-missions/VissionAndMissions'

const VisionMissions = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}/>
        <VissionAndMissions />
    </Fragment>
  )
}

export default VisionMissions