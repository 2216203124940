import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import AdmissionPayOnline from '../admission-pay-online/AdmissionPayOnline';

const AdmissionPage = (props) => {
  return (
    <Fragment>
      <PagesHeading page_title={props.page_title}></PagesHeading>
      <AdmissionPayOnline></AdmissionPayOnline>
    </Fragment>

  )
}

export default AdmissionPage;