import React, { Fragment } from 'react'
import './directormessage.css';
import { PagesQuotesDown } from '../pages-quotes-down/PagesQuotesDown';
const DirectorMessage = () => {
  return (
    <Fragment>
        <div className='director-message-container'>
                <div className='director-details'>
                    <div className='director-image'>
                        <img src="images/ritu-director.jpg" alt="Dr. Ritu Bhattacharyya" />
                        <h2>Dr. Ritu Bhattacharyya Director</h2>
                    </div>
                        
                </div>
                <div className='director-message-content'>
                    <strong>Dear Students,</strong><br />
                    <p>At GNVSIOM we are always guided by a student-centric approach as we help them become industry ready. The experiential learning model imbibes beyond university curriculum. Sessions on mentoring, current affairs, industry based institutional activities, business simulations and campus to corporate program are an integral part of the syllabus. In addition, there are 6 add-on certification programs for our budding industry managers. There are several student forums to drive the various extra-curricular and co-curricular activities. We are proud of this fact that our top notch faculty are enabling our students in excelling in academics in a sustained way year after year. Testimony to this is an IRC that was organized in collaboration with an international affiliate in 2022 where our 20 students contributed research papers and participated in the conference.</p>
                    <p>Accolades & Placement Track Record:</p>
                    <ul>
                        <li>8th Best B-School in Mumbai as per Times B-School Ranking in 2024 moving up a place from the 10th Best B- School Ranking achieved in 2022.</li>
                        <li>Ranked amongst the Best B-Schools in India for 3 consecutive years by BT B-School Survey.</li>
                        <li>Mumbai University MMS Toppers for Two Consecutive Years.</li>
                        <li>90%+ students placed consistently year after year.</li>
                        <li>Highest package offered has been CTC of INR 11.25 Lacs.</li>
                        <li>Average CTC package INR 4.45 Lacs.</li>
                        <li>60+ companies visited for final placements in 2024.</li>
                    </ul>
                    <p className='with-best'>With Best Wishes and Regards</p>
                    <p className='with-best'>Dr. Ritu Bhattacharyya</p>
                    <p className='with-best'>Director - GNVS Institute of Management, Mumbai.</p>
                </div>
                <PagesQuotesDown page_quote={"Our quest for academic excellence continues unabated as we continue to churn out future business managers and leaders"}></PagesQuotesDown>
            </div>
    </Fragment>
  )
}

export default DirectorMessage