import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy'

const PrivacyPolicyPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <PrivacyPolicy></PrivacyPolicy>
    </Fragment>
  )
}

export default PrivacyPolicyPage