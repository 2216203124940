import React, { Fragment, useEffect, useState } from "react";
import CoCurricularCard from "../co-curricular-card/CoCurricularCard";
import "./extracurricularevents.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const ExtracurricularEvents = () => {
	// const ExtracurricularEvent = [{
	//     id: 1,
	//     event_heading: "Business Plan Competition",
	//     event_date: "09-03-2022",
	//     event_content: "Batch 2021-23 The main objective of this activity is to foster entrepreneurship amongst students and to give them a platform to showcase their ideas. This experiential activity provides integrative learning to students and teaches them applications of theories they have learnt.",
	//     isMoreContent: false,
	//     isMoreContentType: "Photo Gallery",
	//     url: ""
	// },
	// {
	//     id: 2,
	//     event_heading: "ISR Project at GTB Station",
	//     event_date: "16-02-2022",
	//     event_content: "Students of MMS Batch 2020-22 visited GTB Station and worked on a cleaning & beautification project as part of their ISR project. A patch of land was cleared of weeds and plastic in order that planting may be undertaken by the Railway authorities.",
	//     isMoreContent: false,
	//     isMoreContentType: "",
	//     url: ""
	// },
	// {
	//     id: 3,
	//     event_heading: "Study Visit to IKEA",
	//     event_date: "05-01-2022",
	//     event_content: "Students of MMS Batch 2020-22 visited IKEA to study Big Box Retail.",
	//     isMoreContent: false,
	//     isMoreContentType: "",
	//     url: ""
	// },
	// {
	//     id: 4,
	//     event_heading: "1st edition of HR Summit on â€œHR & The Future of Worka",
	//     event_date: "01-02-2020",
	//     event_content: "GNVS Institute of Management hosted the 1st edition, HR Summit, on the theme â€œHR & The Future of Workâ€ on 1st February 2020. HR Summit is an annual event with an aim to provide platform for sharing industry best practices and gaining insights from industry experiences of HR thought leaders.",
	//     isMoreContent: true,
	//     isMoreContentType: "Photo Gallery",
	//     url: ""
	// },
	// {
	//     id: 5,
	//     event_heading: "Industrial Visit to Dainik Bhaskar and Pyrotech Electronics.",
	//     event_date: "01-02-2020",
	//     event_content: "Industrial visit organised by GNVSIOM to Rajasthan. Students could observe and understand basic working of printing machines at Dainik Bhaskar and making of security instruments at Pyrotech electronics.",
	//     isMoreContent: true,
	//     isMoreContentType: "Photo Gallery",
	//     url: ""
	// },
	// {
	//     id: 6,
	//     event_heading: "Edition 1: HR SUMMIT 2020 - HR & The FUTURE of WORK",
	//     event_date: "01-02-2020",
	//     event_content: "GNVS Institute of Management hosted the 1st edition, HR Summit, on the theme â€œHR & The Future of Workâ€ on 1st February 2020. HR Summit is an annual event with an aim to provide platform for sharing industry best practices and gaining insights from industry experiences of HR thought leaders.",
	//     isMoreContent: true,
	//     isMoreContentType: "Reprt File",
	//     url: ""
	// }]

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						fetch_development_initiative: true,
						page: "Extra Curricular Events",
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			{data.length > 0 ? (
				<CoCurricularCard co_curricula_event={data}></CoCurricularCard>
			) : (
				"No data found"
			)}
		</Fragment>
	);
};

export default ExtracurricularEvents;
