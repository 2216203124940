import React, { Fragment, useState } from "react";
import "./admissionpayonline.css";
import { api } from "./../../helper/api";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import {
  EmailInput,
  GenricInput,
  MobileNumberInput,
  SelectField,
  TextInput,
  TextAreaInput,
} from "../InputFeilds";

const AdmissionPayOnline = () => {
  const [formData, updateFormData] = useState({
    admission_payment: true,
  });
  const [loading, setLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    handlePayment(null);  // Assuming order_id is returned by your API

    /*const response = await api({
      method: "post",
      url: "inquiry",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    const jsonData = await response.data;
      console.log(Object.entries(jsonData));
    if (jsonData?.status_message === "Form Submitted" && jsonData?.status === 200) {
      setLoading(false);
      toast.success("Your Form Submitted Successfully");
     
    } else {
      setLoading(false);
      toast.error("Something Occur! Please try again later");
    }
    */
  };

  const handlePayment = (order_id) => {
    const options = {
      key: "rzp_live_fF2uBiT6QZX9bT", // Enter the Live Key ID generated from the Dashboard
      amount: formData.amount * 100, // Razorpay expects the amount in paisa
      currency: "INR",
      name: "GNVSIOM",
      description: "Test Transaction",
      image: "images/collegelogo.png",
      // order_id: "GNVIS" + Date.now(), // This is a sample Order ID. Pass the `id` obtained in the previous step
      handler: async function (response) {
        setLoading(false);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        formData['payment_id'] = response.razorpay_payment_id;
        const responses = await api({
          method: "post",
          url: "inquiry",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await responses.data;
        console.log(Object.entries(jsonData));
        if (jsonData?.status_message === "Form Submitted" && jsonData?.status === 200) {
          setLoading(false);
          toast.success("Payment Successful and form Submitted Successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setLoading(false);
          toast.error("Payment Successful, but there was an issue with form submission. Please contact the authorities.");
        }
      },
      prefill: {
        email: formData.email,
        contact: formData.phone,
      },
      notes: {
        name: formData.firstname + " " + formData.lastname,
        address: formData.address_line,
      },
      theme: {
        color: "#008080",
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
          toast.info("You have closed the payment window. Click on 'Submit' to continue with the payment.");
        }
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      setLoading(false);
      toast.error("Something Occur! Please try again later");
    });
    rzp1.open();
  };

  return (
    <Fragment>
      {loading && <Loading />}
      <div className="admission-enquiry-form-container">
        <div className="admission-enquiry-form admission-form-padding">
          <form
            onSubmit={handleSubmit}
            className="admission-enquiry-form-start container"
          >
            <SelectField
              label={"Course/Program/Event"}
              id="course"
              name="course"
              onChange={handleChange}
              placeholder="Select Course/Program/Event"
              options={[
                {
                  name: "Admission form fees",
                  value: "Admission form fees",
                },
                {
                  name: "MMS fees I year full payment",
                  value: "MMS fees I year full payment",
                },
                {
                  name: "MMS fees II year full payment",
                  value: "MMS fees II year full payment",
                },
                {
                  name: "MMS fees installment I",
                  value: "MMS fees installment I",
                },
                {
                  name: "MMS fees installment II ",
                  value: "MMS fees installment II ",
                },
                {
                  name: "Examination / KT exam fees ",
                  value: "Examination / KT exam fees ",
                },
                {
                  name: "IV fees ",
                  value: " IV fees ",
                },
                {
                  name: "Research conference paper fees ",
                  value: "Research conference paper fees ",
                },
                {
                  name: "Certification courses fees ",
                  value: "Certification courses fees ",
                },
                {
                  name: "International conference payment",
                  value: "International conference payment",
                },
                {
                  name: "UGC Care conference payment",
                  value: "UGC Care conference payment",
                },
                {
                  name: "FDP-Payment",
                  value: "FDP-Payment",
                },
              ]}
              isValidated={isValidated}
              defaultValue={formData?.course || ""}
              required
            />
            <TextInput
              label={"First Name"}
              id="firstname"
              name="firstname"
              onChange={handleChange}
              className={"mt-3"}
              placeholder="Enter First Name"
              defaultValue={formData?.firstname}
              isValidated={isValidated}
              required
            />
            <TextInput
              label={"Last Name"}
              id="lastname"
              name="lastname"
              onChange={handleChange}
              className={"mt-3"}
              placeholder="Enter Last Name"
              defaultValue={formData?.lastname}
              isValidated={isValidated}
              required
            />
            <TextAreaInput
              label={"Address Line"}
              placeholder="Enter Address Line"
              id="address_line"
              name="address_line"
              className={"mt-3"}
              onChange={handleChange}
              multiline={"4"}
              isValidated={isValidated}
              defaultValue={formData?.address_line}
              required
            />
            <div className="admission-enquiry-city-state-pin">
              <div className="admission-enquiry-gender-container">
                <TextInput
                  label={"City"}
                  id="city"
                  name="city"
                  onChange={handleChange}
                  className={"mt-3"}
                  placeholder="Enter your city"
                  defaultValue={formData?.city}
                  isValidated={isValidated}
                  required
                />
              </div>
              <div className="admission-state-container">
                <TextInput
                  label={"State"}
                  id="state"
                  name="state"
                  onChange={handleChange}
                  className={"mt-3"}
                  placeholder="Enter your state"
                  defaultValue={formData?.state}
                  isValidated={isValidated}
                  required
                />
              </div>
              <div className="admission-country-container">
                <TextInput
                  label={"Country"}
                  id="country"
                  name="country"
                  onChange={handleChange}
                  className={"mt-3"}
                  placeholder="Enter your country"
                  defaultValue={formData?.country}
                  isValidated={isValidated}
                  required
                />
              </div>
              <div className="admission-pincode-container">
                <GenricInput
                  type="number"
                  maxlength={"6"}
                  label={"Pincode"}
                  id="pincode"
                  name="pincode"
                  onChange={handleChange}
                  className={"mt-3"}
                  placeholder="Enter your pincode"
                  defaultValue={formData?.pincode}
                  isValidated={isValidated}
                  required
                />
              </div>
            </div>
            <EmailInput
              label={"E-mail"}
              id="email"
              name="email"
              className={"mt-3"}
              onChange={handleChange}
              isValidated={isValidated}
              defaultValue={formData?.email}
              placeholder="Enter email"
              required
            />
            <div className="admission-mobile-amount-row">
              <div className="admission-mobile-container">
                <MobileNumberInput
                  label={"Mobile No."}
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  placeholder="Enter The Number"
                  className={"mt-3"}
                  maxlength={"10"}
                  isValidated={isValidated}
                  defaultValue={formData?.phone}
                  required
                />
              </div>
              <div className="admission-amount-container">
                <GenricInput
                  type="number"
                  maxlength={"6"}
                  label={"Amount"}
                  id="amount"
                  name="amount"
                  onChange={handleChange}
                  className={"mt-3"}
                  placeholder="Enter amount"
                  defaultValue={formData?.amount}
                  isValidated={isValidated}
                  required
                />
              </div>
            </div>
            <div className="admission-form-payment-container">
              <button
                type="submit"
                className="admission-form-payment-btn"
              >
                Submit and proceed with online payment.
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AdmissionPayOnline;
