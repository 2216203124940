import React, { Fragment } from "react";
import "./studentcouncilcard.css";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
const StudentCouncilCard = (props) => {
	return (
		<Fragment>
			<Modal
				{...props}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						Student Council
					</Modal.Title>
					<GrClose onClick={props.hide} className="close-popup-btn" />
				</Modal.Header>
				<Modal.Body>
					<div
						className="mandatory-committee-students-details"
						dangerouslySetInnerHTML={{
							__html: props.content && props.content,
						}}
					></div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default StudentCouncilCard;
