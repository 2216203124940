import React, { Fragment, useEffect, useState } from "react";
import "./studentcpuncil.css";
import { FaEye } from "react-icons/fa";
import StudentCouncilCard from "../popup-cards/StudentCouncilCard";
import { api } from "../../helper/api";
const StudentCouncil = () => {
	const [showModal, setShowModal] = useState(false);
	const [getData, setGetData] = useState("");
	const getStudenDetails = (student_details) => {
		console.log("Students_detaiils", student_details);
		setGetData(student_details);
		return setShowModal(!false);
	};
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_student_council: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			<div className="student-council-container container">
				{data.length > 0
					? data.map((item, index) => {
							return (
								<div
									className="student-council-content"
									key={index}
								>
									<div
										className="student-council-heading"
										key={index}
									>
										{item.title}
									</div>
									<button
										className="student-councill-pdf"
										onClick={() => {
											getStudenDetails(item.content);
										}}
									>
										<FaEye className="student-councill-pdf-view" />
									</button>
								</div>
							);
					  })
					: "No Data Added"}

				{showModal ? (
					<StudentCouncilCard
						show={showModal}
						content={getData}
						hide={() => {
							setShowModal(false);
						}}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};

export default StudentCouncil;
