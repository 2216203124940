import React, { Fragment } from "react";
import "./corefacultycard.css";
import { Link } from "react-router-dom";
const CoreFacultyCard = (props) => {
	return (
		<Fragment>
			<div className="core-faculty-card-container">
				<div className="core-faculty-name-role row">
					{props.CommitteMember_details.map((item, index) => {
						return (
							<Fragment>
								<Link to={`/core-faculty/${item.faculty_url}`}>
									<div
										className="core-faculty-name-role-profile column"
										key={index}
									>
										<div className="core-faculty-profile-img">
											<img
												src={item.faculty_profile}
												alt="Image Alt"
											/>
										</div>
										<div className="core-faculty-details">
											<p className="core-faculty-name">
												{item.faculty_name}
											</p>
										</div>
									</div>
								</Link>
							</Fragment>
						);
					})}
				</div>
			</div>
		</Fragment>
	);
};

export default CoreFacultyCard;
