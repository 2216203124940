import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "./nirf.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const NIRF = () => {
	// const nirfDetails = [
	// 	{
	// 		id: 1,
	// 		title: "GNVSIOM-NIRF INDIA RANKINGS 2018-DCS-DATA-Institute- IR-1-M-M-C-45186",
	// 		url: "/nirf/388e05c72fa78b72bbf00f0473b92ed5GNVSIOM-NIRF INDIA RANKINGS 2018-DCS-DATA-Institute _ IR-1-M-M-C-45186.pdf",
	// 	},
	// 	{
	// 		id: 2,
	// 		title: "MHRD, National Institutional Ranking Framework (NIRF)",
	// 		url: "/nirf/40c8b7bf0574fcad81f0902174eb4aebFull Report-MHRD, National Institutional Ranking Framework (NIRF).pdf",
	// 	},
	// 	{
	// 		id: 3,
	// 		title: "NIRF_Consultancty Projects",
	// 		url: "/nirf/2a0f3433986ecdd87a24be1fb91befe31_NIRF_Consultancy projects Details.pdf",
	// 	},
	// 	{
	// 		id: 4,
	// 		title: "NIRF_Enterpreneurship Data",
	// 		url: "/nirf/135b7205819a839c08b241d6d52fb1bf2_NIRF_Enterpreurship Details.pdf",
	// 	},
	// 	{
	// 		id: 5,
	// 		title: "NIRF_ Executive Development Programmes.",
	// 		url: "/nirf/5e67126818ab2ea4c59c24d7f59b5edf3_NIRF_Executive development programs Details.pdf",
	// 	},
	// 	{
	// 		id: 6,
	// 		title: "NIRF_Placement Data.",
	// 		url: "/nirf/f638d4bd5735f34662df7307e9e570814_NIRF_Placement Data.pdf",
	// 	},
	// 	{
	// 		id: 7,
	// 		title: "NIRF_ Higher Studies Data",
	// 		url: "/nirf/4f132dd8784eace77c3bf08e9e5362175_Higher studies Data.pdf",
	// 	},
	// 	{
	// 		id: 8,
	// 		title: "NIRF_Sponsored Research Projects",
	// 		url: "/nirf/f41d64e788c647d0d15d5bec84b6d5ee6_NIRF_Sponsored research projects Details.pdf",
	// 	},
	// 	{
	// 		id: 9,
	// 		title: "NIRF INDIA RANKINGS 2019",
	// 		url: "/nirf/68e814edc1d0c3f553960b818ad8c394GNVSIOM - FINAL SUBMITTED DATA ON DCS NIRF INDIA RANKINGS 2019.pdf",
	// 	},
	// 	{
	// 		id: 10,
	// 		title: "NIRF 2020",
	// 		url: "/nirf/4e2d8fffb192da4774471711c69f9e3aG. N. V. S. Institute of Managment20200106-NIRF INDIA RANKINGS 2020.pdf",
	// 	},
	// 	{
	// 		id: 11,
	// 		title: "GNVS INSTITUTE OF MANAGEMENT - DCS SUBMISSION NIRF RANKINGS 2021",
	// 		url: "/nirf/ba1dfebab8ac07523aa7860f4c3fcc89GNVS INSTITUTE OF MANAGEMENT - DCS SUBMISSION NIRF RANKINGS 2021.pdf",
	// 	},
	// 	{
	// 		id: 12,
	// 		title: "NIRF 2023",
	// 		url: "/nirf/09392dbf7d4e4decd423f857662070bfG. N. V. S. Institute of Managment NIRF.pdf",
	// 	},
	// ];

	const [nirfDetails, setNirfDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_nirf: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setNirfDetails(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}

			<div className="nirf-page-container container">
				<div className="nirf-page-table-container">
					<table className="nirf-page-table">
						{nirfDetails.map((item, index) => {
							return (
								<tr key={index}>
									<td>{index + 1}.</td>
									<td>{item.title}</td>
									<td className="nirf-page-pdf">
										<a href={item.pdf} target="_blank">
											<FaEye className="nirf-page-pdf-view" />
										</a>
									</td>
								</tr>
							);
						})}
					</table>
				</div>
			</div>
		</Fragment>
	);
};

export default NIRF;
