import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import "./homepagedyanmicpopup.css";
import { api } from "./../../helper/api";
const HomePageDynamicPopup = (props) => {
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setShowModal(true);
	}, []);

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_homepage_popup: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{data && (
				<Modal
					{...props}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="dynamic-home-page-image-modal-container"
				>
					<Modal.Body className="dynamic-home-page-image-modal-body">
						<div className="dynamic-home-page-image-modal-close-btn-container">
							<GrClose
								onClick={props.onHide}
								className="close-popup-btn close-home-dynamic-popup-btn"
							/>
						</div>

						<a
							href={data.link ? data.link : "#"}
							target="_blank"
							className="dynamic-home-page-image-container"
						>
							<img src={data.img} alt="image Alt" />
						</a>
					</Modal.Body>
				</Modal>
			)}
		</Fragment>
	);
};

export default HomePageDynamicPopup;
