import React, { Fragment, useEffect, useState } from "react";
import "./placements.css";
import Accordion from "react-bootstrap/Accordion";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const Placements = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_placement: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="placements-container">
				{data.length > 0
					? data.map((item, id) => (
							<div
								className="placement-table-overview-container container"
								key={id}
							>
								<table className="placement-table-overview">
									<caption>{item.title}</caption>
									<tbody>
										{item.data.map((da, index) => (
											<tr key={index}>
												<td>{index + 1}.</td>
												<td>{da.name}</td>
												<td className="placement-table-overview-pdf">
													<a
														href={da.pdf_file}
														target="_blank"
													>
														<FaEye className="placement-table-overview-pdf-view" />
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
					  ))
					: "No Data Added"}
			</div>
		</Fragment>
	);
};

export default Placements;
