import React, { Fragment, useEffect, useState } from "react";
import "./conference.css";
import ConferenceReadMoreModal from "../popup-cards/ConferenceReadMoreModal";
import PopupImageSliderLightbox from "../popup-image-slider-lightbox/PopupImageSliderLightbox";
import { api } from "../../helper/api";
const Conference = () => {
	const [showModal, setShowModal] = useState(false);
	const [readmoreContent, setReadMoreContent] = useState({
		readmore_title: "",
		readmore_content: "",
	});
	const [showImageSliderbtn, setShowImageSlider] = useState(false);
	const [photosArray, setPhotosArray] = useState("");
	const getReadMoreContent = (readmore, readmore_title) => {
		setReadMoreContent({
			readmore_content: readmore,
			readmore_title: readmore_title,
		});
		return setShowModal(!false);
	};
	const showImageSlider = (image_array) => {
		setPhotosArray(image_array);
		return setShowImageSlider(true);
	};
	// const confernce_array = [
	// 	{
	// 		id: 1,
	// 		title: "1st International Conference - 2016",
	// 		content:
	// 			"1st International Conference was held on 5th and 6th March 2016. Conference theme was â€œStrategies for Business Intelligenceâ€.",
	// 		images_array: [
	// 			{
	// 				id: 1,
	// 				src: "https://gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG",
	// 			},
	// 			{
	// 				id: 2,
	// 				src: "https://gnvsiom.org/photo_gallery/48cdeef289226ac3e9a3ac1e86ac1922DSC_5020.JPG",
	// 			},
	// 			{
	// 				id: 3,
	// 				src: "https://gnvsiom.org/photo_gallery/6070e50319cb9b469cd1546d74661249IMG_8815.JPG",
	// 			},
	// 			{
	// 				id: 4,
	// 				src: "https://gnvsiom.org/photo_gallery/768ecdb609ed2fba134fe889019c8b29IMG_8817.JPG",
	// 			},
	// 			{
	// 				id: 5,
	// 				src: "https://gnvsiom.org/photo_gallery/eae2e6540381fa9bd50b9d848911a813IMG_8819.JPG",
	// 			},
	// 			{
	// 				id: 6,
	// 				src: "https://gnvsiom.org/photo_gallery/cd49ff67189bd0d99f19aab5e6237216IMG_8830.JPG",
	// 			},
	// 			{
	// 				id: 7,
	// 				src: "https://gnvsiom.org/photo_gallery/1fadec9fa32de2ab140c55ce345d2687IMG_8839.JPG",
	// 			},
	// 			{
	// 				id: 8,
	// 				src: "https://gnvsiom.org/photo_gallery/e20b5976b91fa421529008451ea062afIMG_8850.JPG",
	// 			},
	// 			{
	// 				id: 9,
	// 				src: "https://gnvsiom.org/photo_gallery/ec125dcc595f88e99e15cae1d4fe3770IMG_8855.JPG",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 2,
	// 		title: "2nd International Conference - 2018",
	// 		content:
	// 			"2nd International Conference was held on 6th and 7th April 2018. Conference theme was â€œInnovative Business Practices and Sustainability in VUCA worldâ€.",
	// 		images_array: [
	// 			{
	// 				id: 1,
	// 				src: "https://gnvsiom.org/photo_gallery/576424216f3052ad92e59f69e28912ddDSC_2847.JPG",
	// 			},
	// 			{
	// 				id: 2,
	// 				src: "https://gnvsiom.org/photo_gallery/ed837212cf3760b5a6a8ec2c5a456ef0DSC_2856.JPG",
	// 			},
	// 			{
	// 				id: 3,
	// 				src: "https://gnvsiom.org/photo_gallery/7f6137fffe9220d94ce70c9294d18508DSC_2925.JPG",
	// 			},
	// 			{
	// 				id: 4,
	// 				src: "https://gnvsiom.org/photo_gallery/6666cf4c12603feb19e4913fa06103b9DSC_3275.JPG",
	// 			},
	// 			{
	// 				id: 5,
	// 				src: "https://gnvsiom.org/photo_gallery/1f94ecea1ef67dc027c4e9d3ff68abceDSC_3303.JPG",
	// 			},
	// 			{
	// 				id: 6,
	// 				src: "https://gnvsiom.org/photo_gallery/69d243c8e0197fac0cea9805ef883858DSC_3339.JPG",
	// 			},
	// 			{
	// 				id: 7,
	// 				src: "https://gnvsiom.org/photo_gallery/03a2c3cd4db3d6619d5b49e8aa211329DSC_3343.JPG    ",
	// 			},
	// 			{
	// 				id: 8,
	// 				src: "https://gnvsiom.org/photo_gallery/674abddab04f10b58ae1f535d91d4e90DSC_3386.JPG",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 3,
	// 		title: "3rd International Conference - June 6th, 2020",
	// 		content:
	// 			"The GNVS Institute of Management( GNVSIOM), in association with the University of Mumbai, JJT University and the Bombay Management Association (BMA) is organizing the 3rd International Conference on the 6th of June, 2020 . Theme : â€œBuilding Core Competencies: Disrupting the Disruptorâ€",
	// 		images_array: [
	// 			{
	// 				id: 1,
	// 				src: "https://www.gnvsiom.org/con/images/conference/ff97d3c7bda66d4db938dbe5a7c44b05Slide1.JPG",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 4,
	// 		title: "4th International Conference - June 18th , 2022",
	// 		content:
	// 			'The GNVS Institute of Management( GNVSIOM), in association with the University of Mumbai, Association of Indian Management Schools and Dhaka School of Economics organized the 4th International Conference on the 18th of June, 2022 . Theme :"Business Sustainability: Revisiting Strategies in the Post Covid Scenario"',
	// 		images_array: [
	// 			{
	// 				id: 1,
	// 				src: "https://www.gnvsiom.org/con/images/conference/6334ebac315c5ecbe229409cefd98f124th%20IRC-%20VIDYAK%20JOURNAL%20OF%20RESEARCH_Page_001.jpg",
	// 			},
	// 		],
	// 	},
	// ];
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_conference: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			<div className="conference-container container">
				{data.length > 0
					? data.map((item, index) => {
							{
								/* const contetn_substring = item.content.substring(
								0,
								230
							); */
							}
							return (
								<div className="single-conference-comtainer">
									<div className="conference-heading">
										<h4>{item.title}</h4>
									</div>
									<div className="conference-message">
										{item.content.length <= 50 ? (
											<div
												dangerouslySetInnerHTML={{
													__html:
														item && item.content,
												}}
											></div>
										) : (
											<>
												<div
													className="confrence-more-content-paragraph"
													dangerouslySetInnerHTML={{
														__html:
															item &&
															item.content,
													}}
												></div>
												<button
													className="confrence-more-content-button"
													onClick={() => {
														getReadMoreContent(
															item.content,
															item.title
														);
													}}
												>
													Read more
												</button>
											</>
										)}
									</div>
									<div className="conference-images-container">
										{item.images_array.map(
											(images, index) => {
												return (
													<Fragment>
														{index >= 3 ? (
															""
														) : (
															<img
																src={images.src}
																alt="Image Alt"
																loading="lazy"
															/>
														)}
													</Fragment>
												);
											}
										)}
									</div>
									{item.images_array.length > 3 ? (
										<div className="conference-more-image">
											<button
												className="conference-more-image-button"
												onClick={() => {
													showImageSlider(
														item.images_array
													);
												}}
											>
												View More
											</button>
										</div>
									) : (
										""
									)}
								</div>
							);
					  })
					: "No data Added"}
				{showModal ? (
					<ConferenceReadMoreModal
						show={showModal}
						readmore={readmoreContent}
						onHide={() => setShowModal(false)}
					/>
				) : (
					""
				)}
				{showImageSliderbtn ? (
					<PopupImageSliderLightbox
						isOpen={showImageSliderbtn}
						photo_array={photosArray}
						onHide={() => {
							setShowImageSlider(false);
						}}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};

export default Conference;
