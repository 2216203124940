import React, { Fragment } from "react";
import "./homepageadmissionpanelmodal.css";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import { useState } from "react";
import { api } from "../../helper/api";
import {
	EmailInput,
	MobileNumberInput,
	TextAreaInput,
	TextInput,
} from "../InputFeilds";

const HomePageAdmissionPanelModal = (props) => {
	const [formData, updateFormData] = useState({ contact_form: true });
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
			contact_form: true,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		updateFormData({
			...formData,
		});
		const response = await api({
			method: "post",
			url: "inquiry",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;

		if (
			jsonData?.status_message === "Form Submitted" &&
			jsonData?.status === 200
		) {
			setLoading(false);
			updateFormData({});
			toast.success("Your Inquiry Submitted Successfully");
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} else {
			setLoading(false);
			toast.error("Something Occur! Please try again later");
		}
	};
	return (
		<Fragment>
			{loading && <Loading />}
			<Modal
				{...props}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size="lg"
				className="modal fade"
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">ASK YOUR QUERY?</Modal.Title>
					<GrClose
						onClick={props.onHide}
						className="close-popup-btn"
					/>
				</Modal.Header>
				<Modal.Body>
					<div className="admission-panel-page-form-container">
						<form
							className="contact-us-page-form"
							onSubmit={handleSubmit}
							noValidate
						>
							<TextInput
								label={"Full Name"}
								id="fullname"
								onChange={handleChange}
								placeholder="Enter Name"
								defaultValue={formData?.fullname}
								isValidated={isValidated}
								required
							/>
							<div className="row">
								<div className="col-md-6 col-12 mt-3">
									<MobileNumberInput
										label={"Mobile No."}
										id="phone"
										onChange={handleChange}
										placeholder="Enter The Number"
										maxlength={"10"}
										isValidated={isValidated}
										defaultValue={formData?.phone}
										required
									/>
								</div>
								<div className="col-md-6 col-12 mt-3">
									<EmailInput
										label={"E-mail"}
										id="email"
										onChange={handleChange}
										isValidated={isValidated}
										defaultValue={formData?.email}
										placeholder="Enter email"
										required
									/>
								</div>
							</div>
							<TextAreaInput
								label={"Your Query"}
								placeholder="Write Here"
								id="message"
								className={"mt-3"}
								onChange={handleChange}
								multiline={"4"}
								isValidated={isValidated}
								defaultValue={formData?.message}
								required
							/>

							<div className="contact-us-page-form-submit-btn-container">
								<button
									type="submit"
									className="contact-us-page-form-submit-btn"
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default HomePageAdmissionPanelModal;
