import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import ContactUs from '../contact-us-page/ContactUs'

const ContactUsPage = (props) => {
  return (
   <Fragment>
    <PagesHeading page_title={props.page_title}/>
    <ContactUs></ContactUs>
   </Fragment>
  )
}

export default ContactUsPage