import React, { Fragment, useEffect, useState } from "react";
import CollegeDeveleopmentCommittee from "../college-development-committee/CollegeDeveleopmentCommittee";
import CoreFacultyCard from "../core-faculty-card/CoreFacultyCard";
import "./corefaculty.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const CoreFaculty = () => {
	const coreFacultyDetails = [
		{
			id: 1,
			faculty_profile: "images/dr_raman_kumar_sailly.JPG",
			faculty_name: "Prof. Dr.R K Saily",
			isActive: true,
			faculty_post: "",
			url: "/pawan",
		},
		{
			id: 2,
			faculty_profile: "images/dr_yashoda_durge_1.JPG",
			faculty_name: "Dr. Yashoda Krishna Durge",
			isActive: true,
			faculty_post: "",
			url: "/shukla",
		},
		{
			id: 3,
			faculty_profile: "images/dr_usha_kumar_1.JPG",
			faculty_name: "Dr. Usha Ramesh Kumar",
			isActive: true,
			faculty_post: "",
			url: "/akash",
		},
		{
			id: 4,
			faculty_profile: "images/Neha Verma.png",
			faculty_name: "Dr. Neha Verma",
			isActive: true,
			faculty_post: "",
			url: "/shivam",
		},
		{
			id: 5,
			faculty_profile: "images/prof_avishek_ganguly_1.jpg",
			faculty_name: "Mr. Avishek Ganguly",
			isActive: true,
			faculty_post: "",
			url: "/hardik",
		},
		{
			id: 6,
			faculty_profile: "images/prof_arun_saxena.JPG",
			faculty_name: "Mr. Arun Saxena",
			isActive: true,
			faculty_post: "",
			url: "/kartik",
		},
		{
			id: 7,
			faculty_profile: "images/prof_shawn_langford_1.jpg",
			faculty_name: "Mr. Shawn Langford",
			isActive: true,
			faculty_post: "",
			url: "/ajay",
		},
		{
			id: 8,
			faculty_profile: "images/dr_hitesh_suthar_1.jpg",
			faculty_name: "Dr. Hites Suthar",
			isActive: true,
			faculty_post: "",
			url: "/sohan",
		},
	];

	const [coreDetails, setCoreDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadPageData = async () => {
			setLoading(true);

			const response = await api({
				method: "post",
				url: "fetch.php",
				data: {
					get_core_facult: true,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setCoreDetails(jsonData.data);
			setLoading(false);
		};
		console.log(coreDetails);
		loadPageData();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="core-faculty-container container">
				<div className="core-fcaulty">
					<CoreFacultyCard CommitteMember_details={coreDetails} />
				</div>
			</div>
		</Fragment>
	);
};

export default CoreFaculty;
