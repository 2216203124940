import React, { Fragment, useEffect, useState } from "react";
import PagesHeading from "../pages-heding/PagesHeading";
import Naac from "../naac-page/Naac";
import { useParams } from "react-router-dom";

const NaccPage = (props) => {
	const [year, setYear] = useState("");
	const [title, setTitle] = useState("");

	let loc = useParams();
	// setYear(loc);

	useEffect(() => {
		setYear(loc.year);
		let nexty = parseInt(loc.year) + 1;
		setTitle(loc.year + "-" + nexty.toString().slice(2));
	}, [loc]);

	return (
		<Fragment>
			<PagesHeading
				page_title={props.page_title + " " + title}
			></PagesHeading>
			<Naac yearData={year}></Naac>
		</Fragment>
	);
};

export default NaccPage;
