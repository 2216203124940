import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import PagesHeading from "../pages-heding/PagesHeading";
import "./developmentinitiativepages.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
import { useParams } from "react-router-dom";
const BridgeCourses = (props) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	let loc = useParams();
	// console.log(loc);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: {
						fetch_inside_capability_enhancement: true,
						tab: loc?.tab,
					},
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, [loc]);
	return (
		<Fragment>
			{loading && <Loading />}
			{data && (
				<>
					<PagesHeading page_title={data.name}></PagesHeading>
					<div className="bridge-course-container container">
						<div
							className="text-start"
							dangerouslySetInnerHTML={{
								__html: data && data.content,
							}}
						></div>
						{data.pdf && (
							<div className="associate-file-container">
								<div className="associate-file-heading">
									<p>Associate Partner File</p>
								</div>
								<a
									href={data.pdf}
									target="_blank"
									className="d-inline-block associate-file-pdf-button"
								>
									<FaEye className="associate-file-pdf-view" />
								</a>
							</div>
						)}
					</div>
				</>
			)}
		</Fragment>
	);
};

export default BridgeCourses;
