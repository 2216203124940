import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "./academiccalendar.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const AcademicCalendar = () => {
	const academicCalendarDetails = [
		{
			id: 1,
			academic_year: "2020-2021",
			url: "",
		},
		{
			id: 2,
			academic_year: "2020-2021",
			url: "",
		},
		{
			id: 3,
			academic_year: "2020-2021",
			url: "",
		},
		{
			id: 4,
			academic_year: "2019-2020",
			url: "",
		},
		{
			id: 5,
			academic_year: "2018-2019",
			url: "",
		},
		{
			id: 5,
			academic_year: "2017-2018",
			url: "",
		},
		{
			id: 6,
			academic_year: "2016-2017",
			url: "",
		},
		{
			id: 7,
			academic_year: "2015-2016",
			url: "",
		},
	];

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_academicReport: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="academic-calendar-container container">
				<div className="academic-calendar-table-container">
					{data && data.length > 0 ? (
						<table className="academic-table">
							<tr>
								<th>No.</th>
								<th>Academic Year</th>
								<th>Report File</th>
							</tr>
							{data.map((item, index) => {
								return (
									<tr key={index}>
										<td>{index + 1}.</td>
										<td>{item.academic_year}</td>
										<td className="academic-calendar-pdf">
											<a
												href={item.pdf_file}
												target="_blank"
											>
												<FaEye className="academic-calendar-pdf-view" />
											</a>
										</td>
									</tr>
								);
							})}
						</table>
					) : (
						" NO DATA FOUND"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default AcademicCalendar;
