import React, { Fragment, useEffect, useState } from "react";
import "./photogallery.css";
import PopupImageSliderLightbox from "../popup-image-slider-lightbox/PopupImageSliderLightbox";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const PhotoGallery = () => {
	const [openImageSlider, setOpenImageSlider] = useState(false);
	const [photosArray, setPhotosArray] = useState();
	const [photosData, setPhotosData] = useState();
	const [loading, setLoading] = useState(true);

	// const photo_gallery = [
	// 	{
	// 		id: 1,
	// 		title: "2015 - 2016",
	// 		photo_array: [
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/67db5af48bd7f517c70bcc8329b3e2daDSC_4963.JPG",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 2,
	// 		title: "2016 - 20217",
	// 		photo_array: [
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/47c0b46244732ef43b7a46dbe53d14b9anual_day.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/2c53463e713a76aa21f87fecb9fdd8b6anual_day2.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 3,
	// 		title: "2017 - 2018",
	// 		photo_array: [
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/576424216f3052ad92e59f69e28912ddDSC_2847.JPG",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/3e3b947a352d220e1f4c0cea1b792be7IMG_20171023_155353.jpg",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 4,
	// 		title: "2018 - 2019",
	// 		photo_array: [
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/acd0f9fbb71fee5ae65281efc895f2fesports%20day%203%202018-2019.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/f277a7bb915021df85dbe4581275f3c6lgl%201.0%20sheeba.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/0fd39b03f04b903c89532173dfd2cd7astudent%20faculty%20add%20feathers.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/91ecc94f2971ca2b231eca6758447891iv3%20dainik.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/98dc2d4845831ec32820ed684e88aaeatarang1.png",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/9eb37223c042cac78ec12e89c38f077fmain_gate_gnvs_iom.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/2ae26dd54047aa1962694868a31d7d46IMG_7061.JPG",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 5,
	// 		title: "2019 - 2020",
	// 		photo_array: [
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/257afd54-bec1-4996-9132-cb6ed4348b86RBI%20Administrative%20Building.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/b1127ce232a64050e6d6e9d737472561Bisleri%20industry%20visit.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/6771807d-d6ba-403e-96a1-13c407e6a425IMG_8607.JPG",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/c66982bef3fef18c5a8f13ea7cb6e4d12.jpeg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/dbeae801e97284e9fb64947927f3b836Sports%20Day.jpg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/69d5bf5162b7712a06583ac1761335a4corporate%20academic%20connect%201.jpeg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/31b66b91e893da9076d9725dcaff6043LGL.jpeg",
	// 			},
	// 			{
	// 				src: "https://www.gnvsiom.org/photo_gallery/7f0f88f894c05cd6ae1a0b37027aaf0920191014_124047.jpg",
	// 			},
	// 		],
	// 	},
	// ];

	const openIMageSliderLightbox = (photosData) => {
		setPhotosArray(photosData);
		return setOpenImageSlider(true);
	};

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_all_photoGallery: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setPhotosData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="photo-gallery-page-container container">
				<div className="photo-gallery-page-card-container">
					{photosData && photosData.length > 0
						? photosData.map((item, index) => {
								return (
									<div
										className="photo-gallery-page-single-card"
										key={index}
									>
										<h5>{item.year}</h5>
										<div className="photo-gallery-page-images-container">
											{item.photo_array.map(
												(images, index) => {
													return (
														<Fragment>
															{index >= 3 ? (
																""
															) : (
																<img
																	src={
																		images.src
																	}
																	alt="image alt"
																/>
															)}
														</Fragment>
													);
												}
											)}
										</div>
										{item.photo_array.length > 3 && (
											<div className="photo-gallery-page-more-image">
												<button
													className="photo-gallery-page-more-image-button"
													onClick={() => {
														openIMageSliderLightbox(
															item.photo_array
														);
													}}
												>
													View More
												</button>
											</div>
										)}
									</div>
								);
						  })
						: "No Photo Added"}
				</div>
			</div>
			{openImageSlider ? (
				<PopupImageSliderLightbox
					isOpen={openImageSlider}
					photo_array={photosArray}
					onHide={() => {
						setOpenImageSlider(false);
					}}
				/>
			) : (
				""
			)}
		</Fragment>
	);
};

export default PhotoGallery;
