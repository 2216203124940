import React, { Fragment, useEffect, useState } from "react";
import "./upcomingevents.css";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const UpcomingEvents = () => {
	const upcomingEvents = [
		{
			id: 1,
			event_date: "April,2022",
			event_for: "MMS Year I and II - Final Exam",
			url: "",
		},
	];

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_upcoming_event: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);

	return (
		<Fragment>
			{loading && <Loading />}
			<div className="upcoming-events-container container">
				<div className="upcoming-events-image">
					<img
						src="images/upcomming_events_banner.png"
						alt="Upcomng events"
					/>
				</div>
				<div className="upcoming-events-table-container">
					{data.length > 0 ? (
						<table className="upcoming-events-table">
							<thead>
								<tr>
									<th>Sr.no</th>
									<th>Year</th>
									<th>Events</th>
									<th>File</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, id) => (
									<tr key={id}>
										<td>{id + 1}</td>
										<td>{item.year}</td>
										<td>{item.title}</td>
										<td className="upcoming-events-pdf">
											<a href={item.pdf} target="_blank">
												<FaEye className="upcoming-events-pdf-eye" />
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						"No Data Added"
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default UpcomingEvents;
