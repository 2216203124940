import React, { Fragment, useEffect, useState } from "react";
import "./accolades.css";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const Accolades = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_accoladesPage: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="master-management-imgage container pb-4">
				{data ? (
					<img
						src={data.img}
						alt="MASTER OF MANAGEMENT STUDIES (MMS)"
						className="img-fluid"
					/>
				) : (
					"NO DATA FOUND"
				)}
			</div>
		</Fragment>
	);
};

export default Accolades;
