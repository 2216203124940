import React, { Fragment } from "react";
import CollegeDeveleopmentCommittee from "../college-development-committee/CollegeDeveleopmentCommittee";
import "./governingbody.css";
const GoverningBody = (props) => {
	return (
		<Fragment>
			<div className="governig-body-container container">
				<div className="organisational-chart-container">
					<div className="organisational-chart-heading">
						<h2>Organisation Chart</h2>
					</div>
					<div className="organisational-chart-image">
						<img
							src="https://www.gnvsiom.org/assets/images/structure.jpg"
							alt="image alt"
						/>
					</div>
					<div className="college-development-commite-table-container governing-body-table">
						<table className="college-development-commite-table">
							<tr>
								<th>Sr.no</th>
								<th>Name</th>
								<th>Designation</th>
							</tr>
							{props.CommitteMember_details.map((item, index) => {
								return (
									<tr>
										<td>{index + 1}.</td>
										<td>{item.name} </td>
										<td>{item.designation}</td>
									</tr>
								);
							})}
						</table>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default GoverningBody;
