import React, { Fragment, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import FacultyDevelopmentProgramCard from "../popup-cards/FacultyDevelopmentProgramCard";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const ManagementDevProgram = () => {
	const [showModal, setShowModal] = useState(false);
	const [modalHeading, setModalHeading] = useState("");
	const [getData, setGetData] = useState({
		level: "",
		participated: "",
		venue: "",
		date: "",
		academic_year: "",
		details: "",
	});
	// const mandatorycommitteesDetails = [
	//     {
	//         id: 1,
	//         title: "Yashoda Krishna Durge",
	//         level: "National",
	//         participated_as: "Delegate",
	//         venue: "Xavier Institute Of Management & Entrepreneurship, Kochi",
	//         date: "20/04/2017",
	//         academic_year: "2017-2018",
	//         details: "Management Development Program In People Analytics 20-21 April, 2017",
	//         url: ""
	//     },
	//     {
	//         id: 2,
	//         title: "Barnana Sarkar",
	//                 level: "National",
	//                 participated_as: "Resource Person",
	//                 venue: "GNVS-IOM, Mumbai",
	//                 date: "20/10/2016",
	//                 academic_year: "2015-2016",
	//                 details: "Soft Skills Training For LIC At Workshop On â€˜High Net Worth Individual",
	//         url: ""
	//     },
	//     {
	//         id: 3,
	//         title: "Barnana Sarkar",
	//                 level: "National",
	//                 participated_as: "Resource Person",
	//                 venue: "Larson And Toubro, Powai, Mumbai, Maharashtra",
	//                 date: "28/02/2017",
	//                 academic_year: "2016-2017",
	//                 details: "Korean Language Training At L&T Heavy Engineering Dept.",
	//         url: ""
	//     }, {
	//         id: 4,
	//         title: "Usha Ramesh Kumar",

	//                 level: "National",
	//                 participated_as: "Delegate",
	//                 venue: "Virtual",
	//                 date: "08/05/2020",
	//                 academic_year: "2019-2020",
	//                 details: "MDP On Training",
	//         url: ""
	//     },
	//     {
	//         id: 5,
	//         title: "Usha Ramesh Kumar",

	//                 level: "National",
	//                 participated_as: "Others",
	//                 venue: "IBS",
	//                 date: "14/02/2014",
	//                 academic_year: "2013-2014",
	//                 details: "Enhancing Personal Growth- Business Etiquette. Corporate Grooming And Image Mgmt",
	//         url: ""
	//     }
	//     ,
	//     {
	//         id: 6,
	//         title: "Usha Ramesh Kumar",

	//                 level: "National",
	//                 participated_as: "Delegate",
	//                 venue: "Virtual",
	//                 date: "	19/06/2020",
	//                 academic_year: "2019-2020",
	//                 details: "Topic - Rethinking Case Method Of Teaching",
	//         url: ""
	//     }
	//     ,
	//     {
	//         id: 7,
	//         title: "Nisha Siddhesh Tatkar",

	//                 level: "State",
	//                 participated_as: "Resource Person",
	//                 venue: "Online",
	//                 date: "07/08/2021",
	//                 academic_year: "2021-2022",
	//                 details: "2 Days ITR Workshop Conducted Through MOU Signed Between GNVSIOM, IJCAMS And Umang Gitai Collage.",
	//         url: ""
	//     }
	// ]
	const getStudenDetails = (
		level,
		participated_as,
		venue,
		date,
		academic_year,
		details,
		title
	) => {
		let updatefacultyprogram = {};
		updatefacultyprogram = {
			level: level,
			participated: participated_as,
			venue: venue,
			date: date,
			academic_year: academic_year,
			details: details,
		};
		setModalHeading(title);
		setGetData(updatefacultyprogram);
		return setShowModal(!false);
	};

	const [mandatorycommitteesDetails, setMandatorycommitteesDetails] =
		useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_mdp: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setMandatorycommitteesDetails(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="faculty-development-program-container container">
				<div className="faculty-development-program-test">
					<p>
						The Institute offers management research, training and
						consultancy services through its faculty and has
						developed corporate tie ups for various programs and
						events conducted at the institute. The Institute has a
						number of corporate specific collaborative programs.
						Some of this are
					</p>
				</div>
				<div className="mandatory-committees-row">
					{mandatorycommitteesDetails.map((item, index) => {
						return (
							<div
								className="mandatory-committees-columns"
								key={index}
							>
								<div className="mandatory-committees-title">
									{item.name}
								</div>
								<div
									className="mandatory-committees-pdf"
									onClick={() => {
										getStudenDetails(
											item.level,
											item.participate,
											item.venue,
											item.date,
											item.academic_year,
											item.content,
											item.name
										);
									}}
								>
									<FaEye className="mandatory-committees-pdf-view" />
								</div>
							</div>
						);
					})}
				</div>
				{showModal ? (
					<FacultyDevelopmentProgramCard
						show={showModal}
						programdata={getData}
						modalHeading={modalHeading}
						onHide={() => setShowModal(false)}
					/>
				) : (
					""
				)}
			</div>
		</Fragment>
	);
};

export default ManagementDevProgram;
