import React, { Fragment, useEffect, useState } from "react";
import "./visitingguest.css";
import PopupVisitingGuest from "../popup-visiting-guest/PopupVisitingGuest";
import VistingGuestCard from "../visiting-guest-card/VistingGuestCard";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const VisitingGuest = () => {
	// const vistingGuestDetails = [
	// 	{
	// 		id: 1,
	// 		image: "Visiting-Faculty/CA Dr Das.png",
	// 		faculty_name: "CA Dr Das",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "",
	// 		cor_experience: "21 Years",
	// 		teaching_expe: "10 Years",
	// 		qualification:
	// 			"B. Com, MA (Economics), MBA (Finance), M. Phil, (Economics), PhD (Economics), Charter Accountant",
	// 		company_name:
	// 			"KDP & Co., Chartered Accountants 2Milkyway E-Solutions Vyankatesh V Joshi & Co. Suresh M Bhatija & Co.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 2,
	// 		image: "Visiting-Faculty/Dr. Sudheer Gopal Bhide.png",
	// 		faculty_name: "Dr. Sudheer Gopal Bhide",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "30 Years",
	// 		teaching_expe: "10 Years",
	// 		qualification: "PhD, BE, M.S.C, D.M.S, DIS",
	// 		company_name:
	// 			"Antifriction Bearings Corporation Ltd. Poysha Industrial Company Ltd Universal Cans and Containers.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 3,
	// 		image: "Visiting-Faculty/JayKumar Acharya.png",
	// 		faculty_name: "JayKumar Acharya",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "30 Years",
	// 		teaching_expe: "10 Years",
	// 		qualification: "BE, Masters in Human Resources",
	// 		company_name:
	// 			"Gladwin International Ernst & Young LLP Siemens Ltd Godrej & Boyce Mfg. Co. Ltd.	",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 4,
	// 		image: "Visiting-Faculty/Dr. R. C.Walke.png",
	// 		faculty_name: "	Dr. R. C.Walke",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner ",
	// 		cor_experience: "12 Years",
	// 		teaching_expe: "15 Years",
	// 		qualification: "BE, ME, MBA, PhD",
	// 		company_name:
	// 			"DCW HomeproductsLtd.Mumbai Sankpal Kulkarni and Associates Balaji Architects.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 5,
	// 		image: "Visiting-Faculty/Saurabh Pramanick.png",
	// 		faculty_name: "Saurabh Pramanick",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Developer",
	// 		cor_experience: "15 Years",
	// 		teaching_expe: "5 Years",
	// 		qualification: "Master in data science Bachelor of Engineering",
	// 		company_name:
	// 			"PricewaterhouseCoopers (PwC) Wolters Kluwer Financial Services Tata Consultancy Services.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 6,
	// 		image: "Visiting-Faculty/Sandeep kumar Vishwakarma.png",
	// 		faculty_name: "Sandeep kumar vishwakarma",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "2 Years",
	// 		teaching_expe: "15 Years",
	// 		qualification:
	// 			"B.E. (Electrical), M.Sc. P.G. Diploma In Management StudiesPhD, MCS, BSc",
	// 		company_name: "",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 7,
	// 		image: "Visiting-Faculty/Dr. Sharukh Tara.png",
	// 		faculty_name: "Dr. Sharukh Tara",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "12 Years",
	// 		teaching_expe: "15 Years",
	// 		qualification: "B,Com M.Com LL.B MBA ACMA MCT Ph.D",
	// 		company_name:
	// 			"Mumbai Stock Exchange Center for Advancement of Philanthropy Senior Functional Consultant and Trainer.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 8,
	// 		image: "Visiting-Faculty/N. BALASUBRAMANIAN.png",
	// 		faculty_name: "N. BALASUBRAMANIAN",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "20 Years",
	// 		teaching_expe: "8 Years",
	// 		qualification:
	// 			"Masters in Business Administration, Bachelor of Science",
	// 		company_name:
	// 			"Tata InfoTech Ltd. now TCS Ltd, Mumbai Mahindra & Mahindra Ltd, Mumbai PIZO Project & Engineering Consultants Pvt. Ltd, Mumbai.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 9,
	// 		image: "Visiting-Faculty/Lalit Vispute.png",
	// 		faculty_name: "Lalit Vispute",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "14 Years",
	// 		teaching_expe: "10 Years",
	// 		qualification: "MBA, BMM Digital Marketing",
	// 		company_name:
	// 			"Jalesh Cruises (Zen Cruises Pvt. Ltd). SOTC Travel Ltd. SIA Jewellery ZODIAC Clothing LTD. MUDRA COMMUNICATION.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 10,
	// 		image: "Visiting-Faculty/Mr. Rajat Kapoor.png",
	// 		faculty_name: "Mr. Rajat Kapoor",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "General Manager (Product Management and Marketing)",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Schnieder Electric",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 11,
	// 		image: "Visiting-Faculty/Milan Thakkar.png",
	// 		faculty_name: "Milan Thakkar",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Walplast",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "GUEST Faculty",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 12,
	// 		image: "Visiting-Faculty/Adv CS. Kunal R Sarpal.png",
	// 		faculty_name: "Adv CS. Kunal R Sarpal",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Founder Partner",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "White Collar Legal LLP",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 13,
	// 		image: "Visiting-Faculty/Ms. Mansi Zaveri.png",
	// 		faculty_name: "Ms. Mansi Zaveri",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Kidsstoppress Media Pvt. Ltd.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 14,
	// 		image: "Visiting-Faculty/Akshay Gurani.png",
	// 		faculty_name: "Akshay Gurani",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Co-founder, CEO",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Schbang",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 15,
	// 		image: "Visiting-Faculty/Roshan Chhabra.png",
	// 		faculty_name: "Roshan Chhabra",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Founder and CEO",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Dextrus.",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 16,
	// 		image: "Visiting-Faculty/Kumaresh Bhatt.png",
	// 		faculty_name: "Kumaresh Bhatt",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Co- Founder",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "URpopular",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 17,
	// 		image: "Visiting-Faculty/Ravish Saily.png",
	// 		faculty_name: "Ravish Saily",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Managing Partner and Consultant",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Syngenta",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 18,
	// 		image: "Visiting-Faculty/Sonali Sharma.png",
	// 		faculty_name: "Sonali Sharma",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "ACC- ACL Leadership Academy",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "ACC- ACL Leadership Academy",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 19,
	// 		image: "Visiting-Faculty/Kush Kapoor.png",
	// 		faculty_name: "Kush Kapoor",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "CEO",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Roseate Hotels and Resorts",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 20,
	// 		image: "Visiting-Faculty/Dr. Chintan Vora.png",
	// 		faculty_name: "Dr. Chintan Vora",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Sr. Vice President",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "5 nance.com",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 21,
	// 		image: "Visiting-Faculty/Chetan Walanj.png",
	// 		faculty_name: "Chetan Walanj",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Vice President HR",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Reliance Industry Ltd",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 22,
	// 		image: "Visiting-Faculty/Ravi Saxena.png",
	// 		faculty_name: "Ravi Saxena",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Founder & MD",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Cipher – Plexus Capital Advisors Pvt Ltd",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 23,
	// 		image: "Visiting-Faculty/Arindam Mukherjee.png",
	// 		faculty_name: "Arindam Mukherjee",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Head Recruitment Operations",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "PWC India",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 24,
	// 		image: "Visiting-Faculty/Ritu Shrivastava.png",
	// 		faculty_name: "Ritu Shrivastava",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Co- Founder",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Light Bulb AI",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 25,
	// 		image: "Visiting-Faculty/Kamal Kumar.png",
	// 		faculty_name: "Kamal Kumar",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "VP & Business Head",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "L & T Energy",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 26,
	// 		image: "Visiting-Faculty/Kavea Chavali.png",
	// 		faculty_name: "Kavea Chavali",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Co- Founder",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "KALANCe",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 27,
	// 		image: "Visiting-Faculty/Bidyut Sen.png",
	// 		faculty_name: "Bidyut Sen",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "CEO",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Asha penn Color Pvt Ltd",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 28,
	// 		image: "Visiting-Faculty/Sandeep Mishra.png",
	// 		faculty_name: "Sandeep Mishra",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Assistant Vice President",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "Grand View Research",
	// 		url: "#",
	// 	},
	// 	{
	// 		id: 29,
	// 		image: "Visiting-Faculty/Ramanuj Kankani.png",
	// 		faculty_name: "Ramanuj Kankani",
	// 		isActive: true,
	// 		faculty_post: "",
	// 		area_special: "Finance and Operations Director",
	// 		cor_experience: "",
	// 		teaching_expe: "",
	// 		qualification: "",
	// 		company_name: "IMCD IndiaIMCD India",
	// 		url: "#",
	// 	},
	// ];

	const [vistingGuestDetails, setVistingGuestDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadPageData = async () => {
			setLoading(true);

			const response = await api({
				method: "post",
				url: "fetch.php",
				data: {
					get_guest_facult: true,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setVistingGuestDetails(jsonData.data);
			setLoading(false);
		};
		loadPageData();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="visiting-guest-container container">
				<div className="core-fcaulty">
					<VistingGuestCard
						CommitteMember_details={vistingGuestDetails}
					/>
				</div>
				<PopupVisitingGuest />
			</div>
		</Fragment>
	);
};

export default VisitingGuest;
