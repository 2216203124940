import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import AdmissionEnquiry from '../admission-enquiry-page/AdmissionEnquiry'

const AdmissionEnquiryPage = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title}></PagesHeading>
            <AdmissionEnquiry></AdmissionEnquiry>
        </Fragment>
    )
}

export default AdmissionEnquiryPage