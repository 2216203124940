import React, { Fragment } from 'react'
import DynamicResult from '../dynamic-results/DynamicResult'
import PagesHeading from '../pages-heding/PagesHeading'

const DynamicResultPage = (props) => {
    return (
        <Fragment>
            <PagesHeading page_title={props.page_title}></PagesHeading>
            <DynamicResult course_title="Master Of Management Studies (MMS)"></DynamicResult>
        </Fragment>

    )
}

export default DynamicResultPage