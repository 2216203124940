import React, { Fragment } from 'react'
import RuralImmersionProgram from '../rural-immersion-program/RuralImmersionProgram'

const RuralImmersionProgramPage = () => {
  return (
    <Fragment>
      <RuralImmersionProgram></RuralImmersionProgram>        
    </Fragment>
  )
}

export default RuralImmersionProgramPage