import React, { Fragment } from 'react'
import './corevalue.css'
const CoreValue = () => {
  return (
    <Fragment>
        <div className='core-value-container'>
        <div className='master-management-imgage'>
                    <img src="images/core_value.jpg" alt="MASTER OF MANAGEMENT STUDIES (MMS)" />
                </div><br></br>
            <p>
            At GNVS Institute, our core values revolve around holistic development and academic excellence. We believe in transparency, diversity and inclusivity, adaptability, and humility as pillars that shape our institution. We foster an environment where open and honest communication is upheld, diversity is celebrated, adaptability is embraced, and humility is valued. Through these values, we aim to create an inclusive and nurturing space for students to grow personally, professionally, and ethically.
            </p>
            <p>
            In pursuit of academic excellence, we prioritise self-development, research and innovation, collaboration, and integrity. We empower individuals to take ownership of their growth, providing them with opportunities for continuous learning and self-improvement. Research and innovation are encouraged, as we seek to advance knowledge and contribute to the field of management. Collaboration and interdisciplinary cooperation are promoted, fostering a community that thrives on teamwork and knowledge sharing. Upholding the highest standards of integrity, we instill ethical values in all academic pursuits, ensuring integrity in research, education, and interactions within our institute and the wider community.
            </p>
            <p>
            Our vision is to be the preferred destination for management education, where students experience holistic development, achieve academic excellence, and are equipped with the skills and knowledge necessary to thrive in a dynamic global business environment. We aspire to be recognised as a leading institute that nurtures future leaders and change-makers, empowering them to create a positive impact on society and contribute to the advancement of the management field. With our core values and vision as guiding principles, we are committed to providing an exceptional educational experience and shaping the leaders of tomorrow.
            </p>
        </div>
    </Fragment>
  )
}

export default CoreValue