import React, { Fragment } from 'react';
import './visionmission.css';

const VissionAndMissions = () => {
    return (
        <Fragment>
            <div className='vision-mission-container'>
                <div className='vision-mission'>
                    <div className='vision-container'>
                        <img src='https://cdn-icons-png.flaticon.com/512/4440/4440481.png' alt="image alt" />
                        <h2>Our Vision</h2>
                        <p>"To be the preferred destination for management education for students and industry."</p>
                    </div>
                    <div className='missions-container'>
                        <img src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQjFoMPgEczPxhco8_TgXD5gKCZgx_cWULnpocjnrzWCbeMSsIb" alt="image alt" />
                        <h2>Our Mission</h2>
                        <ul>
                            <li>
                                Adopt and implement innovative pedagogy for holistic development of students.
                            </li>
                            <li>
                                Provide sufficient resources to enhance the quality of teaching and learning.
                            </li>
                            <li>
                                Establish effective interface with all stakeholders at regular intervals.
                            </li>
                            <li>
                                Provide transparent management system and process.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VissionAndMissions