import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import CommitteesStudentsForum from '../committees-of-students-forum/CommitteesStudentsForum'

const CommitteesStudentsForumPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        {/* <CommitteesStudentsForumPage></CommitteesStudentsForumPage> */}
        <CommitteesStudentsForum></CommitteesStudentsForum>
    </Fragment>
  )
}

export default CommitteesStudentsForumPage