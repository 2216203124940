import React, { Fragment } from 'react'
import AboutUs from '../about-us/AboutUs';
import PagesHeading from '../pages-heding/PagesHeading';
const AboutUsPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}/>
        <AboutUs></AboutUs>
    </Fragment>
  )
}

export default AboutUsPage;