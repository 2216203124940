import React, { Fragment } from 'react'
import './chairmanmessage.css'
import { PagesQuotesDown } from '../pages-quotes-down/PagesQuotesDown'
const ChairmanMessage = () => {
    return (
        <Fragment>
            <div className='chairman-message-container'>
                <div className='chairman-details'>
                    <div className='chairman-image'>
                        <img src="images/chairman-image.jpg" alt="Shri. S. Manjit Singh Bhatti" />
                        <h2>Shri. S. Manjit Singh Bhatti Hon.Chairman</h2>
                    </div>
                    <div className='chairman-name'>
                        
                    </div>
                </div>
                <div className='chairman-message-content'>
                    <strong>Dear Students,</strong><br />
                    <p>Guru Nanak Vidyak Society was founded in 1947 as a humble fledgling effort to cater to India's aspiration and need of education as an independent nation. Our sole motivation was to quench the thirst for knowledge and to become a reagent of change, at the grass root level. Over the last 75 years, our trust has patronized twenty-nine schools, four junior colleges, one-degree College, one B. Ed College, one D. Ed college and one management institute & Law College while always striving to sustain and impart high quality education.</p>
                    <p>They say change is the only constant and industry centered education has become the need of the hour for students. The GNVS Institute of Management was set up in 2010 under our banner as we envisioned an Institute that would inculcate global values, while fulfilling our country's need for the development of global managers and leaders for the Indian economy.</p>
                    <p>Our vision is to be the preferred destination for management education for students and industry. To this end, the institute has made significant progress over the years under the guidance of our esteemed faculty with rich industry exposure. Our institute has been ranked the 10th Best B-School in Mumbai in 2022 by The Times B-School Rankings. We are a NAAC accredited institution with a placement record of 90% + students placed year after year.</p>
                    <p className='with-best'>With Best Wishes and Regards</p>
                    <p className='with-best'>Shri. S. Manjit Singh Bhatti</p>
                    <p className='with-best'>Hon. Chairman - GNVS Institute of Management, Mumbai.</p>
                </div>
                <PagesQuotesDown page_quote={"I welcome you all to become a part of the nation building process as future managers, entrepreneurns and leaders."}></PagesQuotesDown>
            </div>
        </Fragment>
    )
}

export default ChairmanMessage