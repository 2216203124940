import React, { Fragment } from 'react'
import PagesHeading from '../pages-heding/PagesHeading'
import Accolades from '../accolades-page/Accolades'

const AccoladesPage = (props) => {
  return (
    <Fragment>
        <PagesHeading page_title={props.page_title}></PagesHeading>
        <Accolades></Accolades>
    </Fragment>
  )
}

export default AccoladesPage