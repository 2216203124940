import React, { Fragment, useEffect, useState } from "react";
import "./naacpage.css";
import { Link } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
import { FaEye } from "react-icons/fa";

const NaacInside = ({ yearData = "", pageData = "" }) => {
  const [naacData, setNaacData] = useState([]);
  const [naacTab, setNaacTab] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPageData = async () => {
      setLoading(true);
      try {
        const response = await api({
          method: "post",
          url: "fetch.php",
          data: {
            get_naac_pageData: true,
            naac_year: yearData,
            naac_page: pageData.replaceAll("-", " "),
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        const jsonData = await response.data;
        setNaacData(jsonData.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    const loadPageTab = async () => {
      setLoading(true);

      const response = await api({
        method: "post",
        url: "fetch.php",
        data: {
          get_naac_tab: true,
          naac_year: yearData,
          naac_page: pageData.replaceAll("-", " "),
        },
        headers: { "Content-Type": "multipart/form-data" },
      });
      const jsonData = await response.data;
      setNaacTab(jsonData.data);
      setLoading(false);
    };
    if (yearData !== "" && pageData !== "") {
      loadPageData();
      loadPageTab();
    }
  }, [yearData, pageData]);
  return (
    <Fragment>
      {loading && <Loading />}
      <div className="naac-page-container container"> 

        <div className="naac-top-6-conatiner-row">
          {naacTab.length > 0 &&
            naacTab &&
            naacTab.map((naac, id) => (
              <div className="top-6-tags" key={id}>
                <a href={naac.naac_pdf} target="_blank">
                  {naac.naac_title}
                </a>
              </div>
            ))}
        </div>
        {naacTab.length > 0 && naacData.length > 0 && (
          <hr className="nacc-hr-line" />
        )}
        {naacData.length > 0 && (
          <>
            <div className="research-cell-table-container pt-4">
              <table className="research-cell-table">
                <tr>
                  <th>No.</th>
                  <th>Title</th>
                  <th>Academic Year</th>
                  <th>File</th>
                </tr>
                {naacData.map((data, id) => (
                  <tr>
                    <td>{id + 1}</td>
                    <td>{data.naac_title}</td>
                    <td>{`${data.naac_year} - ${parseInt(data.naac_year) + 1}`}</td>
                    <td className="research-cell-pdf">
                      <a href={data.naac_pdf} target="_blank">
                        <FaEye className="research-cell-pdf-view" />
                      </a>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default NaacInside;
